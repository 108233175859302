import { Platform } from "react-native";
import LanaAppState from "../utils/lana-app-state";

export const ColorConfig = {
    "Default": {
        limeGreen: "#ECF4DC",
        primaryColor: "#8549FF",
        secondaryColor: "#F0E6FF",
        selectedColor: "#BC9AFE",
        mainLightColor: "#D7C3FE",
        mainGray: "#808D9C",
        borderGray: "#DADEE1",
        disabledButtonGray: "#EEF0F1",
        darkGray: "#677484",
        lightGray: "#E9EBEC",
        black: "#141414",
        white: "#fff",
        blue400: "#00A6FF",
        blue50: "#E0F5FF",
        red: "#FA6B75",
        redBg: "#FEEBED",
        whiteOpacityBackground: "rgba(255, 255, 255, 0.3)",
    },
    "HSHFVIERA": {
        limeGreen: "#ECF4DC",
        primaryColor: "#6B1F99",
        secondaryColor: "#F4EAFB",
        selectedColor: "#C790E9",
        mainLightColor: "#E1C3F3",
        mainGray: "#808D9C",
        borderGray: "#DADEE1",
        disabledButtonGray: "#EEF0F1",
        darkGray: "#677484",
        lightGray: "#E9EBEC",
        black: "#141414",
        white: "#fff",
        blue400: "#00A6FF",
        blue50: "#E0F5FF",
        red: "#FA6B75",
        redBg: "#FEEBED",
        whiteOpacityBackground: "rgba(255, 255, 255, 0.3)",
    }
};

export function getColorConfig() {
    let hospitalId = LanaAppState.getInstance().getHospitalId();

    // hospitalId is not being set when this is called.
    if(!hospitalId) {
        if(Platform.OS === 'web') {
            if((window) && (window.location) && (window.location.search)) {
                const params = new Proxy(new URLSearchParams(window.location.search), {
                    get: (searchParams, prop) => searchParams.get(prop),
                });
                hospitalId = params.hospital_id;    
            }
        }
    }

    if(hospitalId && ColorConfig[hospitalId]) {
        return ColorConfig[hospitalId];
    } else {
        return ColorConfig["Default"];
    }
}