import React from "react";
import {
    ActivityIndicator,
    BackHandler,
    Dimensions,
    Image,
    ScrollView,
    StyleSheet,
    View,
} from "react-native";
import { getColorConfig } from "../../../../constants/colors";
import { DATE_FORMAT } from "../../../../constants/date-format";
import { capitalize } from "../../../../components/stringManipulations";
import { boxWithDarkShadow } from "../../../../constants/common-styles";
import { SecondaryButton } from "../../../../components/secondary-button";
import { BottomSheetContext } from "../../../../components/bottom-sheet-view";
import { MedicationReminderSelectFrequencySheet } from "../MedicationReminder/MedicationReminderSelectFrequencySheet";
import * as APIHandler from "../../../../apiHandlers/APIHandler";
import dateFormat from "dateformat";
import TextFields from "../../../../components/text-fields";
import HorizontalLine from "../../../../components/horizontal-line";

export default class MedicationDetailScreen extends React.Component {
    constructor(props) {
        super(props);
        this.medicationData = props.route.params.medicationData;
        this.state = {
            reminderData: null,
            reminderPresent: false,
            reminderLoading: true
        };
    }

    handleBackButton = () => {
        BackHandler.removeEventListener(
            "hardwareBackPress",
            this.handleBackButton
        );
        this.hideBottomSheet();
        return true;
    };
    componentDidMount() {
        APIHandler.getRemindersForParticularMedication(
            this.medicationData.medicationId,
            (data) => {
                this.setState({
                    reminderData: data.data,
                    reminderPresent: data.data.length > 0,
                    reminderLoading: false
                });
            },
            () => {
                this.setState({reminderLoading: false})
                console.log(
                    "Error fetching reminders for ",
                    this.medicationData.medicationDisplayName
                );
            }
        );
        BackHandler.addEventListener(
            "hardwareBackPress",
            this.handleBackButton
        );
    }

    render() {
        return (
            <View style={{flex: 1}}>
                {(this.state.reminderLoading) &&
                    <View style={{
                            position: 'absolute', top: 0, left: 0, right: 0, bottom: 0,
                            zIndex: 1000, backgroundColor: '#F8F7F7', flex: 1, justifyContent: 'center', alignItems: 'center'}}>
                        <ActivityIndicator size={"large"} />
                    </View>
                }

                <ScrollView
                    showsVerticalScrollIndicator={false}
                    style={{ paddingBottom: 16 }}
                >
                    {this.medicationDetailsCard()}

                    <View style={{ paddingLeft: 16, paddingTop: 4 }}>
                        <TextFields.TitleTextGray1>
                            REMINDERS
                        </TextFields.TitleTextGray1>
                    </View>

                    {!this.state.reminderPresent && this.createReminderButton()}

                    {this.state.reminderPresent && this.reminderCard()}
                </ScrollView>
            </View>
        );
    }

    medicationDetailsCard() {
        return (
            <View style={[styles.actionCardStyle, boxWithDarkShadow]}>
                <View style={styles.container}>
                    <Image
                        style={{ width: 80, height: 80, marginBottom: 16 }}
                        source={require("../../../../../assets/healthData/medicine.png")}
                    />
                    <TextFields.TitleText3>
                        {this.medicationData.medicationDisplayName}
                    </TextFields.TitleText3>
                    <TextFields.SubTitleTextGray>
                        {capitalize(this.medicationData.status)}
                    </TextFields.SubTitleTextGray>
                </View>

                <HorizontalLine style={{ height: 33 }} />

                <TextFields.SubTitleTextBlack style={{ marginBottom: 16 }}>
                    {this.medicationData.dosageInstructions[0].display}
                </TextFields.SubTitleTextBlack>
                <TextFields.SubTitleTextGray>
                    Prescribed:{" "}
                    {dateFormat(
                        this.medicationData.prescriptionDate,
                        DATE_FORMAT.descriptive_date
                    )}
                </TextFields.SubTitleTextGray>
            </View>
        );
    }

    createReminderButton() {
        return (
            <View style={[styles.actionCardStyle, boxWithDarkShadow]}>
                <TextFields.SubTitleTextGray>
                    No reminders created.
                </TextFields.SubTitleTextGray>

                <BottomSheetContext.Consumer>
                    {({
                        showBottomSheet,
                        hideBottomSheet,
                        bottomSheetChildren,
                    }) => {
                        this.showBottomSheet = showBottomSheet;
                        this.hideBottomSheet = hideBottomSheet;
                        this.bottomSheetChildren = bottomSheetChildren;
                        return (
                            <SecondaryButton
                                icon={require("../../../../../assets/common/addIconPurple.png")}
                                buttonText={"Create reminder"}
                                style={{
                                    marginTop: 48,
                                }}
                                onPress={() =>
                                    this.showMedicationReminderSheet(false)
                                }
                            />
                        );
                    }}
                </BottomSheetContext.Consumer>
            </View>
        );
    }

    editReminderButton() {
        return (
            <BottomSheetContext.Consumer>
                {({
                    showBottomSheet,
                    hideBottomSheet,
                    bottomSheetChildren,
                }) => {
                    this.showBottomSheet = showBottomSheet;
                    this.hideBottomSheet = hideBottomSheet;
                    this.bottomSheetChildren = bottomSheetChildren;
                    return (
                        <SecondaryButton
                            icon={require("../../../../../assets/healthData/editReminderPurple.png")}
                            buttonText={"Edit reminder"}
                            style={{
                                marginTop: 48,
                            }}
                            onPress={() =>
                                this.showMedicationReminderSheet(true)
                            }
                        />
                    );
                }}
            </BottomSheetContext.Consumer>
        );
    }

    reminderCard() {
        function printableTime(recurrence) {
            if(!recurrence) {
                return "";
            }
            function padZero(number) {
                return (number < 10 ? '0' : '')+number
            }
            return (recurrence.hourOfTheDay%12 || 12) + ':' + padZero(recurrence.minuteOfTheHour) + ' ' + (recurrence.hourOfTheDay<12? 'AM' :'PM');          
        }

        function tConvert(data) {
            var finalTimeString = "";
            data.map((recurrence, index) => {
                if(index > 0) {
                    finalTimeString += ", "
                }
                finalTimeString += printableTime(recurrence);
            });
            return finalTimeString;
        }

        return (
            <View style={[styles.actionCardStyle, boxWithDarkShadow]}>
                <View style={{ flexDirection: "row" }}>
                    <TextFields.SubTitleTextGray>
                        Frequency:
                    </TextFields.SubTitleTextGray>
                    <TextFields.SubTitleTextBlack
                        style={{
                            marginLeft: 32,
                            width: Math.min(550, Dimensions.get("screen").width) * 0.58,
                        }}
                    >
                        {this.state.reminderData[0].schedule.recurrenceType ===
                        "DAILY"
                            ? "Every day"
                            : this.state.reminderData[0].schedule
                                  .everyXDaysRecurrences[0].daysToSkip === 1
                            ? "Every other day"
                            : "Every " +
                              (this.state.reminderData[0].schedule
                                  .everyXDaysRecurrences[0].daysToSkip +
                                  1) +
                              " days"}
                    </TextFields.SubTitleTextBlack>
                </View>
                <View style={{ flexDirection: "row", marginTop: 8 }}>
                    <TextFields.SubTitleTextGray>
                        Dosage:
                    </TextFields.SubTitleTextGray>
                    <TextFields.SubTitleTextBlack
                        style={{
                            marginLeft: 56,
                            width: Math.min(550, Dimensions.get("screen").width) * 0.56,
                        }}
                    >
                        {this.medicationData.dosageInstructions[0].display}
                    </TextFields.SubTitleTextBlack>
                </View>

                <HorizontalLine style={{ height: 33 }} />

                <View style={{ flexDirection: "row" }}>
                    <Image
                        style={{ width: 24, height: 24, marginRight: 8 }}
                        source={require("../../../../../assets/healthData/notificationPurple.png")}
                    />
                    {this.state.reminderData[0].schedule
                        .dailyRecurrencesList && (
                        <TextFields.SubTitleTextBlack>
                            {tConvert(
                                this.state.reminderData[0].schedule
                                    .dailyRecurrencesList
                            )}
                        </TextFields.SubTitleTextBlack>
                    )}
                    {this.state.reminderData[0].schedule
                        .everyXDaysRecurrences && (
                        <TextFields.SubTitleTextBlack>
                            {tConvert(
                                this.state.reminderData[0].schedule
                                    .everyXDaysRecurrences
                            )}
                        </TextFields.SubTitleTextBlack>
                    )}
                </View>

                {this.editReminderButton()}
            </View>
        );
    }

    showMedicationReminderSheet = () => {
        this.bottomSheetChildren(
            <MedicationReminderSelectFrequencySheet
                openedFromMedicationDetailsScreen={true}
                medicationData={this.medicationData}
                reminderData={
                    this.state.reminderPresent
                        ? this.state.reminderData[0]
                        : null
                }
                showBottomSheet={this.showBottomSheet}
                bottomSheetChildren={this.bottomSheetChildren}
                setReminderData={this.setReminderData}
                hideBottomSheet={this.hideBottomSheet}
            />
        );
        this.showBottomSheet();
    };

    setReminderData = (reminderData) => {
        this.setState({
            reminderData: reminderData,
            reminderPresent: reminderData.length > 0,
        });
    };
}

const styles = StyleSheet.create({
    container: {
        justifyContent: "center",
        alignItems: "center",
    },
    actionCardStyle: {
        margin: 16,
        padding: 16,
        borderRadius: 12,
        backgroundColor: getColorConfig().white,
        maxWidth: 518,
        width: Dimensions.get("screen").width - 32,
    },
});
