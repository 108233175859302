import { WebView } from 'react-native-webview';
import React from 'react';
import { Platform } from 'react-native';

export default class DicomScreen extends React.Component {
    
    constructor(props) {
        super(props)
        this.url = props.url;
    }

    render() {
        return Platform.OS === "web" ? (
            <iframe src={this.url} height={'100%'} width={'100%'} />
          ) : (
            <WebView
                source={{
                uri: this.url
                }}/>
        )
    }
}