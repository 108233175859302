import React from "react";
import { Alert, BackHandler, Image, StyleSheet, View } from "react-native";
import { getColorConfig } from "../constants/colors";
import { BottomSheetContext } from "./bottom-sheet-view";
import TextFields from "./text-fields";
import CommonComponents from "./common-components";
import TouchableWithAnimation from "./touchable-with-animation";
import * as APIHandler from "../apiHandlers/APIHandler";

export default class SurveyActionCard extends React.Component {
    constructor(props) {
        super(props);
        this.card = props.card;
        this.questionsData = props.card.survey.questionsList;
        this.answers = [];
        this.onUpdateCallback = props.onUpdateCallback;
        this.state = {
            currentQuestion: 0,
            selectedOption: null,
            optionId: null,
            assignedQuestionId: null
        };
    }

    handleBackButton = () => {
        BackHandler.removeEventListener(
            "hardwareBackPress",
            this.handleBackButton
        );
        this.hideBottomSheet();
        return true;
    };

    componentDidMount() {
        BackHandler.addEventListener(
            "hardwareBackPress",
            this.handleBackButton
        );
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            prevState.selectedOption !== this.state.selectedOption ||
            prevState.currentQuestion !== this.state.currentQuestion
        ) {
            this.bottomSheetChildren(this.surveyQuestionScreen());
        }
    }

    onOptionSelect(index, option) {
        let questionsData = this.questionsData[this.state.currentQuestion];
        this.setState({
            selectedOption: index,
            optionId: option.optionId,
            assignedQuestionId: questionsData.assignedQuestionId
        });
    }

    backButtonPressed() {
        if (this.state.currentQuestion > 0) {
            this.setState({
                currentQuestion: this.state.currentQuestion - 1,
                selectedOption: null,
            });
        }
    }

    nextButtonPressed() {
        if((this.state.optionId) && (this.state.assignedQuestionId)) {
            this.answers.push({
                optionId: this.state.optionId,
                assignedQuestionId: this.state.assignedQuestionId
            })
        }
        if (this.state.currentQuestion != this.questionsData.length - 1) {
            this.setState({
                currentQuestion: this.state.currentQuestion + 1,
                selectedOption: null,
                optionId: null,
                assignedQuestionId: null
            });
        } else {
            let responsesList = [];
            this.answers.map((answer) => {
                responsesList.push({
                    assignedQuestionId: answer.assignedQuestionId,
                    scaleBasedAnswer: {
                        optionId: answer.optionId
                    }
                })
            })
            const request = {
                formId: this.card.survey.formId,
                responsesList: responsesList
            }
            APIHandler.submitSurveyResponse(
                request,
                (data) => {
                    this.onUpdateCallback();
                },
                () => {
                    Alert.alert("Failed to submit response");
                }
            );
            this.hideBottomSheet();
        }
    }

    surveyQuestionScreen = () => {
        let options =
            this.questionsData[this.state.currentQuestion].answerOptions
                .scaleOptions;
        return (
            <View style={{ height: 600 }}>
                <TextFields.PurpleTitleUppercaseText style={{ paddingTop: 8 }}>
                    Question {this.state.currentQuestion + 1}/
                    {this.questionsData.length}
                </TextFields.PurpleTitleUppercaseText>
                <CommonComponents.ProgressBar
                    progressRate={
                        (this.state.currentQuestion + 1) /
                        this.questionsData.length
                    }
                    backgroundColor={getColorConfig().mainLightColor}
                    style={{ paddingTop: 16 }}
                    highlightColor={getColorConfig().primaryColor}
                />

                <TextFields.TitleText1 style={{ paddingTop: 40 }}>
                    {this.questionsData[this.state.currentQuestion].title}
                </TextFields.TitleText1>

                {options.map((option, index) => {
                    if(index > 3) {
                        return <></>
                    }
                    return (
                        <TouchableWithAnimation
                            scaleFactor={0.95}
                            key={"option-" + index}
                            onPress={() => this.onOptionSelect(index, option)}
                        >
                            <View
                                style={
                                    this.state.selectedOption == index
                                        ? styles.selectedOption
                                        : styles.unselectedOption
                                }
                            >
                                <TextFields.SubTitleTextBlack>
                                    {option.text}
                                </TextFields.SubTitleTextBlack>
                            </View>
                        </TouchableWithAnimation>
                    );
                })}

                {this.state.currentQuestion > 0 && (
                    <View style={{ position: "absolute", bottom: 0, left: 0 }}>
                        <TouchableWithAnimation
                            onPress={() => this.backButtonPressed()}
                        >
                            <View style={styles.backQuestionButton}>
                                <View
                                    style={{
                                        flexDirection: "row",
                                        alignItems: "center",
                                    }}
                                >
                                    <Image
                                        style={{
                                            marginRight: 4,
                                            width: 16,
                                            height: 16,
                                        }}
                                        source={require("../../assets/arrows/leftPurpleIcon.png")}
                                    />
                                    <TextFields.ButtonTextPurple>
                                        Back
                                    </TextFields.ButtonTextPurple>
                                </View>
                            </View>
                        </TouchableWithAnimation>
                    </View>
                )}

                <View style={{ position: "absolute", bottom: 0, right: 0 }}>
                    <TouchableWithAnimation
                        onPress={() => this.nextButtonPressed()}
                    >
                        <View style={styles.nextQuestionButton}>
                            <View
                                style={{
                                    flexDirection: "row",
                                    alignItems: "center",
                                }}
                            >
                                <TextFields.WhiteButtonText>
                                    {this.state.currentQuestion !=
                                    this.questionsData.length - 1
                                        ? "Next"
                                        : "Done"}
                                </TextFields.WhiteButtonText>
                                {this.state.currentQuestion !=
                                    this.questionsData.length - 1 && (
                                    <Image
                                        style={{
                                            marginLeft: 4,
                                            width: 16,
                                            height: 16,
                                        }}
                                        source={require("../../assets/arrows/rightIconWhite.png")}
                                    />
                                )}
                            </View>
                        </View>
                    </TouchableWithAnimation>
                </View>
            </View>
        );
    };

    startSurvey = () => {
        this.setState({
            currentQuestion: 0,
            selectedOption: null,
        });
        this.bottomSheetChildren(this.surveyQuestionScreen());
        this.showBottomSheet();
    };

    render() {
        return (
            <BottomSheetContext.Consumer>
                {({
                    showBottomSheet,
                    hideBottomSheet,
                    bottomSheetChildren,
                }) => {
                    this.showBottomSheet = showBottomSheet;
                    this.hideBottomSheet = hideBottomSheet;
                    this.bottomSheetChildren = bottomSheetChildren;
                    return (
                        <View style={{ padding: 16 }}>
                            <Image
                                style={{ width: 56, height: 56 }}
                                source={require("../../assets/resourceCardIcons/surveySmiley.png")}
                            />

                            <View style={{ paddingTop: 16 }}>
                                <TextFields.TitleText1>
                                    {this.card.title}
                                </TextFields.TitleText1>
                            </View>

                            <View style={{ paddingTop: 2 }}>
                                <TextFields.SubTitleTextGray>
                                    {this.card.description}
                                </TextFields.SubTitleTextGray>
                            </View>

                            <View
                                style={{ paddingTop: 32, flexDirection: "row" }}
                            >
                                <TouchableWithAnimation
                                    onPress={() => this.startSurvey()}
                                >
                                    <View style={styles.startSurveyButton}>
                                        <TextFields.ButtonTextPurple>
                                            Start
                                        </TextFields.ButtonTextPurple>
                                    </View>
                                </TouchableWithAnimation>
                            </View>
                        </View>
                    );
                }}
            </BottomSheetContext.Consumer>
        );
    }
}

const styles = StyleSheet.create({
    unselectedOption: {
        marginTop: 24,
        borderRadius: 12,
        padding: 20,
        borderWidth: 1.5,
        borderColor: getColorConfig().borderGray,
    },
    selectedOption: {
        marginTop: 24,
        borderRadius: 12,
        padding: 20,
        borderWidth: 1.5,
        borderColor: getColorConfig().primaryColor,
    },
    startSurveyButton: {
        width: 96,
        height: 40,
        backgroundColor: getColorConfig().secondaryColor,
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 8,
    },
    backQuestionButton: {
        width: 96,
        height: 48,
        borderRadius: 8,
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: getColorConfig().secondaryColor,
    },
    nextQuestionButton: {
        width: 96,
        height: 48,
        borderRadius: 8,
        backgroundColor: getColorConfig().primaryColor,
        alignItems: "center",
        justifyContent: "center",
    },
});
