import React from "react";
import { ActivityIndicator, Alert, Dimensions, Image, StyleSheet, TextInput, View, KeyboardAvoidingView, Platform, Text } from "react-native";
import { getColorConfig } from "../constants/colors";
import { PrimaryButton } from "../components/primary-button";
import * as APIHandler from "../../src/apiHandlers/APIHandler";
import Main from "./Main";
import LanaAppState from "../utils/lana-app-state";
import { secureDataGetValueFor, secureDataSave } from "../utils/storage-helper";

export default class LoginScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            configApiLoading: true,
            authCheckApiLoading: true,
            loginCompleted: false,
            username: '',
            password: '',
            passcode: '',
            loginLoading: false,
            errorMessage: null
        }
    }

    componentDidMount() {
        secureDataGetValueFor("loginData")
            .then(loginData => {
                LanaAppState.getInstance().setAccessToken(loginData.accessToken);
                APIHandler.authCheckHomeCare(
                    {
                        hospitalId: LanaAppState.getInstance().getHospitalId(),
                        patientId: loginData.patientId
                    },
                    (data) => {
                        LanaAppState.getInstance().setPatientId(loginData.patientId);
                        LanaAppState.getInstance().setEncounterId(loginData.encounterId);
                        LanaAppState.getInstance().setAccessToken(loginData.accessToken);
                        LanaAppState.getInstance().setPatientName(loginData.patientName);
                        LanaAppState.getInstance().setAdmitDate(loginData.admitDate);
                        LanaAppState.getInstance().setDischargeDate(loginData.dischargeDate);
                        this.setState({
                            authCheckApiLoading: false,
                            loginCompleted: true
                        })
                    },
                    () => {
                        this.setState({authCheckApiLoading: false})
                    }
                )
            })
            .catch(error => this.setState({authCheckApiLoading: false}))
        

        APIHandler.getPreLoginConfig(
            (data) => {
                LanaAppState.getInstance().setHospitalName(data.name);
                LanaAppState.getInstance().setBranding(data.branding);
                LanaAppState.getInstance().setHomeCareAuthType(data.homeCareAuthType);
                this.setState({configApiLoading: false})
            },
            () => {
                this.setState({errorMessage: "Something went wrong (Error code: 101)"})
            }
        );
    }

    async _NotificationsSetupAsync() {
        // setup push notifications only for ios for now
        if(Platform.OS === 'ios') {
            let productionMode = true;
            if(__DEV__) {
                productionMode = false;
            }
            const token = (await Notifications.getDevicePushTokenAsync()).data;
            console.log("iOS: token in _NotificationsSetupAsync", token);
            console.log("productionMode", productionMode);

            APIHandler.registerDevice(
                token,
                productionMode,
                (data) => {
                    console.log("Register device response: ", data);
                },
                () => {
                    console.log("Error calling register device");
                }
            );
        }
    }

    performLogin = () => {
        this.setState({loginLoading: true})
        if(LanaAppState.getInstance().getHomeCareAuthType() === 'DUMMY_HOME_CARE_AUTH') {
            APIHandler.login(
                this.state.username,
                this.state.password,
                (data) => {
                    if(data.success) {
                        const admitDate = new Date(data.encounterAdmitDate);
                        const dischargeDate = new Date(data.encounterDischargeDate);

                        LanaAppState.getInstance().setAccessToken(data.accessToken);
                        LanaAppState.getInstance().setPatientId(data.patientId);
                        LanaAppState.getInstance().setEncounterId(data.encounterId);
                        LanaAppState.getInstance().setPatientName(data.patientName);
                        LanaAppState.getInstance().setAdmitDate(admitDate);
                        LanaAppState.getInstance().setDischargeDate(dischargeDate);

                        secureDataSave("loginData", {
                            patientId: data.patientId,
                            encounterId: data.encounterId,
                            patientName: data.patientName,
                            admitDate: admitDate,
                            dischargeDate: dischargeDate,
                            accessToken: data.accessToken
                        })    

                        this._NotificationsSetupAsync();
                        this.setState({ loginCompleted: true });    
                    } else {
                        Alert.alert("Invalid credentials");
                        this.setState({ loginLoading: false });    
                    }
                },
                () => {
                    Alert.alert("Invalid credentials");
                    this.setState({ loginLoading: false });
                }
            );
        } else if(LanaAppState.getInstance().getHomeCareAuthType() === 'PASSCODE_AUTH') {
            APIHandler.loginWithPasscode(
                this.state.passcode,
                (data) => {
                    if(data.success) {
                        const admitDate = new Date(data.encounterAdmitDate);
                        const dischargeDate = new Date(data.encounterDischargeDate);

                        LanaAppState.getInstance().setAccessToken(data.accessToken);
                        LanaAppState.getInstance().setPatientId(data.patientId);
                        LanaAppState.getInstance().setEncounterId(data.encounterId);
                        LanaAppState.getInstance().setPatientName(data.patientName);
                        LanaAppState.getInstance().setAdmitDate(admitDate);
                        LanaAppState.getInstance().setDischargeDate(dischargeDate);

                        secureDataSave("loginData", {
                            patientId: data.patientId,
                            encounterId: data.encounterId,
                            patientName: data.patientName,
                            admitDate: admitDate,
                            dischargeDate: dischargeDate,
                            accessToken: data.accessToken
                        })

                        this._NotificationsSetupAsync();
                        this.setState({ loginCompleted: true });    
                    } else {
                        Alert.alert("Invalid credentials");
                        this.setState({ loginLoading: false });    
                    }
                },
                () => {
                    Alert.alert("Invalid credentials");
                    this.setState({ loginLoading: false });
                }
            );
        }
    }

    onChangeText = () => {

    }

    render() {
        if(this.state.errorMessage) {
            return (
                <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
                    <Text>{this.state.errorMessage}</Text>
                </View>
            );
        } else if(this.state.authCheckApiLoading || this.state.configApiLoading) {
            return (
                <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
                    <ActivityIndicator />
                </View>
            );
        } else if (!this.state.loginCompleted) {
            return (
                <KeyboardAvoidingView
                    behavior={Platform.OS === "ios" ? "padding" : "height"}
                    style={{flex: 1, justifyContent: 'center', margin: 32}}>

                    <Image
                        style={{height: 64, marginBottom: 64, resizeMode: 'contain'}}
                        // source={require("../../assets/common/hospitalBrandingSm.png")}
                        source={{uri: LanaAppState.getInstance().getBranding().brandingImage.imageLinkLight}}
                    />

                    {LanaAppState.getInstance().getHomeCareAuthType() === 'DUMMY_HOME_CARE_AUTH' &&
                        <>
                            <TextInput
                                style={styles.containerStyle}
                                placeholder={"Username"}
                                onChangeText={(text) => this.setState({username: text})}
                                value={this.state.username}
                            />

                            <TextInput
                                style={styles.containerStyle}
                                placeholder={"Password"}
                                onChangeText={(text) => this.setState({password: text})}
                                value={this.state.password}
                                secureTextEntry={true}
                            />
                        </>
                    }

                    {LanaAppState.getInstance().getHomeCareAuthType() === 'PASSCODE_AUTH' &&
                        <>
                            <TextInput
                                style={styles.containerStyle}
                                placeholder={"Passcode"}
                                onChangeText={(text) => this.setState({passcode: text})}
                                value={this.state.passcode}
                                secureTextEntry={true}
                            />
                        </>
                    }

                    {(!this.state.loginLoading) &&
                        <PrimaryButton
                            buttonText={"Login"}
                            onPress={() => {this.performLogin()}}
                        />                
                    }

                    {(this.state.loginLoading) &&
                        <View style={{padding: 12, marginVertical: 10}}>
                            <ActivityIndicator />
                        </View>
                    }
                </KeyboardAvoidingView>
            );    
        } else {
            return (
                <Main />
            )
        }


    }
}

const styles = StyleSheet.create({
    tabIconImage: {
        width: 20,
        height: 20,
    },
    containerStyle: {
        height: 48,
        marginVertical: 12,
        paddingHorizontal: 12,
        paddingVertical: 10,
        borderWidth: 1,
        borderRadius: 12,
        flexDirection: "row",
        borderColor: getColorConfig().borderGray,
    },
    input: {
        height: 40,
        margin: 12,
        borderWidth: 1,
        padding: 10,
    },
});
