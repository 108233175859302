import { Image, StyleSheet, View } from "react-native";
import TextFields from "./text-fields";

export default class CommonComponents {
    static ProgressBar(props) {
        const widthPercentage = parseInt(props.progressRate * 100) + "%";
        return (
            <View style={[styles.progressOuterView, props.style]}>
                <View
                    style={[
                        styles.progressBackgroundView,
                        { backgroundColor: props.backgroundColor },
                    ]}
                >
                    <View
                        style={[
                            styles.progressBarView,
                            {
                                width: widthPercentage,
                                backgroundColor: props.highlightColor,
                            },
                        ]}
                    ></View>
                </View>
            </View>
        );
    }

    static CompletedBadge() {
        return (
            <View style={styles.completedBadgeView}>
                <TextFields.BadgeText style={{ color: "#00B05F" }}>
                    Completed
                </TextFields.BadgeText>
            </View>
        );
    }

    static RescheduledBadge({ style, text }) {
        return (
            <View style={[styles.rescheduleBadgeView, style]}>
                <Image
                    style={styles.image}
                    source={require("../../assets/common/infoIconYellow.png")}
                />
                <TextFields.BadgeText style={{ color: "#D18E05" }}>
                    {text}
                </TextFields.BadgeText>
            </View>
        );
    }

    static ComingSoonBadge() {
        return (
            <View style={styles.comingSoonBadgeView}>
                <TextFields.BadgeText style={{ color: "#D18E05" }}>
                    Coming Soon
                </TextFields.BadgeText>
            </View>
        );
    }
}

const styles = StyleSheet.create({
    progressOuterView: {
        justifyContent: "center",
    },
    progressBackgroundView: {
        height: 4,
        borderRadius: 2,
    },
    progressBarView: {
        borderRadius: 2,
        height: 4,
    },
    completedBadgeView: {
        borderRadius: 10,
        marginLeft: 16,
        paddingVertical: 4,
        paddingHorizontal: 10,
        justifyContent: "center",
        backgroundColor: "#F0F9F4",
        borderColor: "#BDE7CE",
        borderWidth: 1,
    },
    rescheduleBadgeView: {
        borderRadius: 16,
        paddingVertical: 4,
        paddingHorizontal: 10,
        flexDirection: "row",
        alignItems: "center",
        backgroundColor: "#FFF7E5",
        borderColor: "#FEE2AB",
        borderWidth: 1,
    },
    comingSoonBadgeView: {
        borderRadius: 10,
        right: 0,
        position: "absolute",
        paddingVertical: 4,
        paddingHorizontal: 10,
        justifyContent: "center",
        backgroundColor: "#FFF7E5",
        borderColor: "#FEE2AB",
        borderWidth: 1,
    },
    image: {
        width: 16,
        height: 16,
        marginRight: 4,
    },
});
