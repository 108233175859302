import React from "react";
import * as Linking from "expo-linking";
import { StyleSheet, View } from "react-native";
import { getColorConfig } from "../../../constants/colors";
import { PrimaryButton } from "../../../components/primary-button";
import { CommonConstants } from "../../../constants/common-constants";
import TextFields from "../../../components/text-fields";
import HorizontalLine from "../../../components/horizontal-line";

export const ProfileEmergencyContactSheet = () => {
    return (
        <View>
            <TextFields.TitleText1>Emergency Contact</TextFields.TitleText1>
            <HorizontalLine style={{ height: 33 }} />
            <View
                style={{
                    alignItems: "center",
                }}
            >
                <View style={styles.initialsContainer}>
                    <TextFields.InitialsLargeText>
                        MS
                    </TextFields.InitialsLargeText>
                </View>

                <TextFields.TitleText3_Bold
                    style={{
                        paddingTop: 16,
                    }}
                >
                    Mike Smithson
                </TextFields.TitleText3_Bold>
                <TextFields.SubTitleTextGray>
                    Brother
                </TextFields.SubTitleTextGray>
            </View>

            <HorizontalLine style={{ height: 33 }} />
            <View style={{ flexDirection: "row" }}>
                <TextFields.SubTitleTextGray style={{ width: 96 }}>
                    Phone:
                </TextFields.SubTitleTextGray>
                <TextFields.SubTitleTextBlack>
                    {CommonConstants.emergencyContact1_Text}
                </TextFields.SubTitleTextBlack>
            </View>
            <View style={{ flexDirection: "row", paddingTop: 8 }}>
                <View style={{ width: 96 }}></View>
                <TextFields.SubTitleTextBlack>
                    {CommonConstants.emergencyContact2_Text}
                </TextFields.SubTitleTextBlack>
            </View>

            <PrimaryButton
                style={{ marginTop: 200, marginBottom: 16 }}
                buttonText={"Call - " + CommonConstants.emergencyContact1_Text}
                onPress={() =>
                    Linking.openURL("tel:" + CommonConstants.emergencyContact1)
                }
            />
            <PrimaryButton
                buttonText={"Call - " + CommonConstants.emergencyContact2_Text}
                onPress={() =>
                    Linking.openURL("tel:" + CommonConstants.emergencyContact2)
                }
            />
        </View>
    );
};

const styles = StyleSheet.create({
    image: {
        width: 48,
        height: 48,
    },
    imageBg: {
        width: 80,
        height: 80,
        borderRadius: 48,
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: getColorConfig().limeGreen,
    },
    initialsContainer: {
        marginTop: 16,
        width: 80,
        height: 80,
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 40,
        backgroundColor: getColorConfig().secondaryColor,
    },
});
