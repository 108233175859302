import React, { useEffect, useState } from "react";
import {
    Dimensions,
    Image,
    LayoutAnimation,
    ScrollView,
    StyleSheet,
    TouchableOpacity,
    View,
} from "react-native";
import { getColorConfig } from "../../../constants/colors";
import TextFields from "../../../components/text-fields";
import HorizontalLine from "../../../components/horizontal-line";

const data = [
    {
        question: "What can I do with Lana’s Bedside Assistant app?",
        answer: "With Lana, you can access your entire recovery journey including care plans, health data, lab results and imaging results.",
        isExpanded: false,
    },
    {
        question: "Is my information private?",
        answer: "Your data is completely secure and we don't store any health data information related to you with us. All your data is encrypted in transit with industry standard encryption.",
        isExpanded: false,
    },
    {
        question: "How can I communicate with my care team?",
        answer: "You can use the chat functionality to connect with your care team. You can also dial in the emergency helpline numbers available on the app in case you need any urgent assistance.",
        isExpanded: false,
    },
];

export const ProfileAboutLanaHealth = () => {
    const [listDataSource, setListDataSource] = useState(data);
    const updateLayout = (index) => {
        LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
        const array = [...listDataSource];
        array.map((value, placeholder) =>
            placeholder === index
                ? (array[placeholder]["isExpanded"] =
                      !array[placeholder]["isExpanded"])
                : (array[placeholder]["isExpanded"] = false)
        );
        setListDataSource(array);
    };

    return (
        <ScrollView showsVerticalScrollIndicator={false}>
            <TextFields.TitleText1>About Lana Health</TextFields.TitleText1>

            <HorizontalLine style={{ height: 33 }} />

            <View style={styles.container}>
                <Image
                    style={styles.image}
                    source={require("../../../../assets/logos/lanaLogo.png")}
                />
                <TextFields.TitleText3>Lana Health</TextFields.TitleText3>
                <TextFields.SubTitleTextGray>
                    Market Street, San Francisco, CA
                </TextFields.SubTitleTextGray>
            </View>
            <HorizontalLine style={{ height: 33 }} />

            <TextFields.TitleText3>About</TextFields.TitleText3>
            <TextFields.SubTitleTextGray>
                Lana is a constant companion right from the hospital bedside to recovery at home. We empower patients to become active participants in their own care.

            </TextFields.SubTitleTextGray>

            <HorizontalLine style={{ height: 33 }} />

            <TextFields.TitleText3 style={{ marginBottom: 16 }}>
                FAQs
            </TextFields.TitleText3>
            {listDataSource.map((item, key) => (
                <ExpandableComponent
                    key={item.question}
                    item={item}
                    onPress={() => {
                        updateLayout(key);
                    }}
                />
            ))}

            <View style={{ height: 64 }}></View>
        </ScrollView>
    );
};

const ExpandableComponent = ({ item, onPress }) => {
    const [layoutHeight, setLayoutHeight] = useState(0);
    useEffect(() => {
        if (item.isExpanded) {
            setLayoutHeight(null);
        } else {
            setLayoutHeight(0);
        }
    }, [item.isExpanded]);

    return (
        <View>
            <View>
                <TouchableOpacity onPress={onPress}>
                    <View
                        style={{
                            flexDirection: "row",
                            alignItems: "center",
                        }}
                    >
                        <TextFields.WhiteButtonText
                            style={{
                                color: getColorConfig().black,
                                width: Math.min(550, Dimensions.get("screen").width) - 64,
                            }}
                        >
                            {item.question}
                        </TextFields.WhiteButtonText>

                        <Image
                            style={{
                                width: 24,
                                height: 24,
                                right: 0,
                                position: "absolute",
                            }}
                            source={
                                item.isExpanded
                                    ? require("../../../../assets/arrows/upDarkGray.png")
                                    : require("../../../../assets/arrows/downDarkGray.png")
                            }
                        />
                    </View>
                </TouchableOpacity>
            </View>
            <View
                style={{
                    height: layoutHeight,
                    overflow: "hidden",
                }}
            >
                <TextFields.SubTitleTextGray style={{ marginTop: 12 }}>
                    {item.answer}
                </TextFields.SubTitleTextGray>
            </View>

            <HorizontalLine style={{ height: 33 }} />
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        justifyContent: "center",
        alignItems: "center",
    },
    image: {
        width: 80,
        height: 80,
        marginBottom: 16,
    },
});
