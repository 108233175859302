import React from "react";
import { StyleSheet, View } from "react-native";
import { getColorConfig } from "../../constants/colors";
import { boxWithDarkShadow } from "../../constants/common-styles";
import { BottomSheetContext } from "../../components/bottom-sheet-view";
import { ProfileAboutHospital } from "./ProfileSheets/ProfileAboutHospitalSheet";
import { ProfileAboutLanaHealth } from "./ProfileSheets/ProfileAboutLanaHealthSheet";
import ListButton from "../../components/list-button";
import HorizontalLine from "../../components/horizontal-line";

export default class ProfileAboutSelector extends React.Component {
    render() {
        return (
            <BottomSheetContext.Consumer>
                {({
                    showBottomSheet,
                    hideBottomSheet,
                    bottomSheetChildren,
                }) => {
                    this.showBottomSheet = showBottomSheet;
                    this.hideBottomSheet = hideBottomSheet;
                    this.bottomSheetChildren = bottomSheetChildren;
                    return (
                        <View style={[styles.listStyle, boxWithDarkShadow]}>
                            <ListButton
                                icon={require("../../../assets/logos/hospitalLogo.png")}
                                title={"St. John’s Hospital "}
                                arrowIcon={true}
                                onClick={() => {
                                    this.showProfileAboutHospitalSheet();
                                }}
                            />

                            <HorizontalLine />

                            <ListButton
                                icon={require("../../../assets/logos/lanaLogo.png")}
                                title={"Lana Health"}
                                width={30}
                                height={30}
                                arrowIcon={true}
                                onClick={() => {
                                    this.showProfileAboutLanaHealthSheet();
                                }}
                            />
                        </View>
                    );
                }}
            </BottomSheetContext.Consumer>
        );
    }

    showProfileAboutHospitalSheet = () => {
        this.bottomSheetChildren(<ProfileAboutHospital />);
        this.showBottomSheet();
    };

    showProfileAboutLanaHealthSheet = () => {
        this.bottomSheetChildren(<ProfileAboutLanaHealth />);
        this.showBottomSheet();
    };
}

const styles = StyleSheet.create({
    listStyle: {
        padding: 16,
        marginHorizontal: 16,
        marginVertical: 16,
        backgroundColor: getColorConfig().white,
        borderRadius: 12,
    },
});
