import React from "react";
import { Dimensions, Image, StyleSheet, View } from "react-native";
import { getColorConfig } from "../constants/colors";
import { boxWithDarkShadow } from "../constants/common-styles";
import TextFields from "./text-fields";

export default class ResourceCard extends React.Component {
    constructor(props) {
        super(props);
        this.style = props.style;
        this.icon = props.icon;
        this.title = props.title;
        this.subTitle = props.subTitle;
        this.subTitleBlack = props.subTitleBlack == true ? true : false;
        this.arrowIcon = props.arrowIcon == true ? true : false;
        this.shadow = props.shadow == false ? false : true;
    }

    render() {
        return (
            <View
                style={[
                    styles.resourceCard,
                    this.style,
                    this.shadow ? boxWithDarkShadow : styles.borderGray,
                ]}
            >
                <Image
                    style={{ width: 48, height: 48, borderRadius: 24 }}
                    source={this.icon}
                />
                <View
                    style={{
                        flexDirection: "column",
                        justifyContent: "center",
                        marginLeft: 16,
                        marginRight: 56,
                    }}
                >
                    <TextFields.TitleText1Lines2>
                        {this.title}
                    </TextFields.TitleText1Lines2>

                    {this.subTitleStyle()}
                </View>
                {this.showArrow()}
            </View>
        );
    }

    showArrow() {
        if (this.arrowIcon == true) {
            return (
                <Image
                    style={styles.arrowIcon}
                    source={require("../../assets/arrows/rightIconLightGray.png")}
                />
            );
        }
    }

    subTitleStyle() {
        if (this.subTitleBlack == true) {
            return (
                <TextFields.SubTitleTextBlack
                    numberOfLines={1}
                    style={{
                        width: (Math.min(550, Dimensions.get("screen").width) / 5) * 3,
                    }}
                >
                    {this.subTitle}
                </TextFields.SubTitleTextBlack>
            );
        } else {
            return (
                <TextFields.SubTitleTextGray>
                    {this.subTitle}
                </TextFields.SubTitleTextGray>
            );
        }
    }
}

const styles = StyleSheet.create({
    resourceCard: {
        padding: 16,
        borderRadius: 12,
        marginVertical: 8,
        marginHorizontal: 16,
        flexDirection: "row",
        backgroundColor: getColorConfig().white,
    },
    arrowIcon: {
        width: 24,
        height: 24,
        marginVertical: 30,
        marginHorizontal: 16,
        right: 0,
        position: "absolute",
    },
    borderGray: {
        borderWidth: 1,
        borderColor: getColorConfig().borderGray,
    },
});
