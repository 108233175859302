import React from "react";
import { Image, ScrollView, StyleSheet, View } from "react-native";
import { getColorConfig } from "../../constants/colors";
import { boxWithDarkShadow } from "../../constants/common-styles";
import TextFields from "../../components/text-fields";
import ProfileAboutSelector from "./ProfileAboutSelector";
import ProfileOptionSelector from "./ProfileOptionSelector";
import HorizontalLine from "../../components/horizontal-line";
import ProfileResourcesSelector from "./ProfileResourcesSelector";
import TouchableWithAnimation from "../../components/touchable-with-animation";
import LanaAppState from "../../utils/lana-app-state";

export default class ProfileScreen extends React.Component {
    render() {
        return (
            <ScrollView
                showsVerticalScrollIndicator={false}
                style={{ paddingTop: 16 }}
            >
                <View style={[styles.profileCardContainer, boxWithDarkShadow]}>
                    {this.profileCard()}
                    <HorizontalLine style={{ height: 33 }} />

                    <ProfileOptionSelector navigation={this.props.navigation} />
                    <View style={{ height: 16 }}></View>
                </View>

                <View style={{ paddingLeft: 16, paddingTop: 20 }}>
                    <TextFields.TitleTextGray1>
                        Resources
                    </TextFields.TitleTextGray1>
                </View>
                <ProfileResourcesSelector navigation={this.props.navigation} />

                <View style={{ paddingLeft: 16, paddingTop: 24 }}>
                    <TextFields.TitleTextGray1>About</TextFields.TitleTextGray1>
                </View>
                <ProfileAboutSelector navigation={this.props.navigation} />
                {/* {this.logOutButton()} */}
                <View style={{ height: 32 }}></View>
            </ScrollView>
        );
    }

    profileCard() {
        return (
            <View
                style={{
                    alignItems: "center",
                }}
            >
                <View style={styles.initialsContainer}>
                    <TextFields.InitialsLargeText>
                        {this.getInitials(LanaAppState.getInstance().getPatientName())}
                    </TextFields.InitialsLargeText>
                </View>
                <View
                    style={{
                        marginTop: 16,
                        flexDirection: "row",
                        justifyContent: "center",
                    }}
                >
                    <TextFields.TitleText2
                        style={{
                            marginVertical: 4,
                        }}
                    >
                        {LanaAppState.getInstance().getPatientName()}
                    </TextFields.TitleText2>
                    <View style={{ width: 8 }} />
                    <TextFields.SubTitleTextGray>
                        (he/him)
                    </TextFields.SubTitleTextGray>
                </View>
                <TextFields.SubTitleTextGray>
                    Male • 58
                </TextFields.SubTitleTextGray>
            </View>
        );
    }

    logOutButton() {
        return (
            <TouchableWithAnimation scaleFactor={0.95} onPress={() => {}}>
                <View style={styles.createReminderButton}>
                    <Image
                        style={{
                            width: 24,
                            height: 24,
                            marginRight: 8,
                        }}
                        source={require("../../../assets/common/logout.png")}
                    />
                    <TextFields.ButtonTextPurple style={{ color: getColorConfig().red }}>
                        Log out
                    </TextFields.ButtonTextPurple>
                </View>
            </TouchableWithAnimation>
        );
    }

    getInitials(name) {
        var initials = "";
        name.split(" ").forEach(x => initials += (x[0].toUpperCase()));
        return initials;
    }
}

const styles = StyleSheet.create({
    profileCardContainer: {
        margin: 16,
        borderRadius: 12,
        paddingHorizontal: 16,
        backgroundColor: getColorConfig().white,
    },
    initialsContainer: {
        marginTop: 16,
        width: 80,
        height: 80,
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 40,
        backgroundColor: getColorConfig().secondaryColor,
    },
    createReminderButton: {
        margin: 16,
        padding: 16,
        borderRadius: 12,
        justifyContent: "center",
        flexDirection: "row",
        alignItems: "center",
        backgroundColor: getColorConfig().redBg,
    },
});
