import AsyncStorage from '@react-native-async-storage/async-storage';
import * as SecureStore from 'expo-secure-store';
import { Platform } from 'react-native';

function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    let expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
        c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
        }
    }
    return "";
}
  
export async function secureDataSave(key, value) {
    if(Platform.OS === 'web') {
        setCookie(key, JSON.stringify(value), 30);
    } else {
        try {
            if(key) {
                const jsonValue = JSON.stringify(value)
                await SecureStore.setItemAsync(key, jsonValue);
            } else {
                console.log("Key is undefined")
            }
        } catch (e) {
            console.log("Error while storing object data", e)
        }
    }
  }
  
export async function secureDataGetValueFor(key) {
    try {
        if(Platform.OS === 'web') {
            return JSON.parse(getCookie(key));
        } else {
            let result = await SecureStore.getItemAsync(key);
            if(result) {
                let retVal = JSON.parse(result);
                return retVal;
            }
        }
    } catch (e) {
        console.log("Error while getting secure data", e)
    }
    return null;
}

export async function storeObjectData(key, value) {
    try {
        if(key) {
            const jsonValue = JSON.stringify(value)
            await AsyncStorage.setItem(key, jsonValue)    
        } else {
            console.log("Key is undefined")
        }
    } catch (e) {
        console.log("Error while storing object data", e)
    }
}

export async function getObjectData(key) {
    try {
        const jsonValue = await AsyncStorage.getItem(key)
        if(jsonValue != null) {
            let retVal = JSON.parse(jsonValue);
            return retVal;
        }
    } catch (e) {
        console.log("Error while getting object data", e)
    }
    return null;
}
