import React from "react";
import { Image, StyleSheet, View } from "react-native";
import { getColorConfig } from "../constants/colors";
import TextFields from "./text-fields";
import CommonComponents from "./common-components";
import TouchableWithAnimation from "./touchable-with-animation";

export default class ListButton extends React.Component {
    constructor(props) {
        super(props);
        this.icon = props.icon;
        this.title = props.title;
        this.onClick = props.onClick;
        this.width = props.width == 32 ? 32 : 28;
        this.height = props.height == 32 ? 32 : 28;
        this.arrowIcon = props.arrowIcon == true ? true : false;
        this.disable = props.disable == true ? true : false;
        this.showComingSoonBadge =
            props.showComingSoonBadge == true ? true : false;
    }

    render() {
        return (
            <TouchableWithAnimation
                disable={this.disable}
                scaleFactor={0.95}
                onPress={this.onClick}
            >
                <View style={styles.resourceCard}>
                    <Image
                        style={{ width: this.width, height: this.height }}
                        source={this.icon}
                    />
                    <TextFields.SubTitleTextBlack
                        style={{
                            marginLeft: 16,
                        }}
                    >
                        {this.title}
                    </TextFields.SubTitleTextBlack>
                    {this.showComingSoonBadge && (
                        <CommonComponents.ComingSoonBadge />
                    )}
                    {this.showArrow()}
                </View>
            </TouchableWithAnimation>
        );
    }

    showArrow() {
        if (this.arrowIcon == true) {
            return (
                <Image
                    style={styles.arrowIcon}
                    source={require("../../assets/arrows/rightIconLightGray.png")}
                />
            );
        }
    }
}

const styles = StyleSheet.create({
    resourceCard: {
        backgroundColor: getColorConfig().white,
        borderRadius: 12,
        flexDirection: "row",
    },
    arrowIcon: {
        width: 24,
        height: 24,
        right: 0,
        position: "absolute",
    },
});
