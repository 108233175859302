import React from "react";
import { Image, StyleSheet, View } from "react-native";
import TextFields from "./text-fields";
import CommonComponents from "./common-components";
import { getColorConfig } from "../constants/colors";
import { boxWithDarkShadow } from "../constants/common-styles";
import { EDUCATION_STATUS } from "../constants/education-status";

export default class EducationDisplayCard extends React.Component {
    constructor(props) {
        super(props);
        this.imageUrl = { uri: props.imageUrl };
        this.title = props.title;
        this.completionRate = props.completionRate;
        this.duration = props.duration;
        this.status = props.status;
        this.assetType = props.assetType;
    }

    render() {
        return (
            <View style={[styles.educationDisplayCard, boxWithDarkShadow]}>
                <View style={styles.backgroundImageView}>
                    {(this.assetType === 'VIDEO' ?
                        <>
                        <Image
                            source={this.imageUrl}
                            style={styles.backgroundImage}
                        />
                        <View style={styles.overlay} />
                        {(this.imageUrl && this.imageUrl.uri && this.imageUrl.uri !== '') ? 
                            <Image
                                style={styles.playButtonStyle}
                                source={require("../../assets/common/play-button-white-bg.png")}
                            /> : 
                            <Image
                                style={styles.playButtonStyle}
                                source={require("../../assets/common/play-button-blue-bg.png")}
                            />
                        }
                        </>
                    : 
                        <Image
                                style={styles.backgroundImage}
                                source={require("../../assets/common/edu-doc-yellow-bg.png")}
                        />
                    )}
                </View>

                <View
                    style={{
                        flex: 1,
                        flexDirection: "column",
                        justifyContent: "center",
                        marginLeft: 16,
                        marginRight: 16,
                    }}
                >
                    <TextFields.SubTitleTextBlack>
                        {this.title}
                    </TextFields.SubTitleTextBlack>
                    <View style={{ height: 8 }}></View>
                    <View
                        style={{ flexDirection: "row", alignItems: "center" }}
                    >
                        <TextFields.DarkCardSubText style={{color: getColorConfig().mainGray}}>
                            {this.duration}
                        </TextFields.DarkCardSubText>
                        {this.status == EDUCATION_STATUS.ASSIGNED && (
                            <>{/* Empty view */}</>
                        )}
                        {this.status == EDUCATION_STATUS.IN_PROGRESS && (
                            <CommonComponents.ProgressBar
                                progressRate={0.4}
                                backgroundColor={getColorConfig().mainLightColor}
                                style={{ paddingHorizontal: 16, flex: 1 }}
                                highlightColor={getColorConfig().primaryColor}
                            />
                        )}
                        {this.status == EDUCATION_STATUS.COMPLETED && (
                            <CommonComponents.CompletedBadge />
                        )}
                    </View>
                </View>
            </View>
        );
    }
}

const styles = StyleSheet.create({
    educationDisplayCard: {
        padding: 16,
        marginHorizontal: 16,
        marginVertical: 8,
        backgroundColor: getColorConfig().white,
        borderRadius: 12,
        flexDirection: "row",
    },
    playButtonStyle: {
        position: "absolute",
        marginTop: 20,
        width: 28,
        height: 28,
    },
    backgroundImageView: {
        alignItems: "center",
        borderRadius: 8,
        width: 64,
    },
    backgroundImage: {
        width: 64,
        height: 64,
        borderRadius: 8,
    },
    overlay: {
        width: 64,
        height: 64,
        borderRadius: 8,
        position: "absolute",
        backgroundColor: getColorConfig().blue50,
        opacity: 0.3,
    },
});
