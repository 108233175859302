import React, { useRef, useState } from "react";
import { Image, Modal, StyleSheet, TouchableOpacity, View } from "react-native";
import { getColorConfig } from "../constants/colors";
import { FlatList } from "react-native-gesture-handler";
import { boxWithDarkShadow } from "../constants/common-styles";
import TextFields from "../components/text-fields";

export const Dropdown = (props) => {
    const DropdownButton = useRef();
    const [dropdownTop, setDropdownTop] = useState(0);
    const [visible, setVisible] = useState(false);

    const toggleDropdown = () => {
        visible ? setVisible(false) : openDropdown();
    };
    const openDropdown = () => {
        DropdownButton.current.measure((_fx, _fy, _w, h, _px, py) => {
            setDropdownTop(py + h + 10);
        });
        setVisible(true);
    };
    const onItemPress = (item) => {
        props.updateDropdownValue(item);
        setVisible(false);
    };
    const renderItem = (item) => (
        <TouchableOpacity
            style={
                props.dropdownValue == item.item
                    ? { backgroundColor: "#F1F2F3" }
                    : { backgroundColor: "#FFFFFF" }
            }
            onPress={() => onItemPress(item)}
        >
            <TextFields.SubTitleTextBlack
                style={{
                    padding: 10,
                    paddingHorizontal: 24,
                }}
            >
                {item.item.label}
            </TextFields.SubTitleTextBlack>
        </TouchableOpacity>
    );
    const renderDropdown = () => {
        return (
            <Modal visible={visible} transparent animationType="none">
                <TouchableOpacity
                    style={styles.overlay}
                    onPress={() => setVisible(false)}
                >
                    <View
                        style={[
                            styles.dropdownBox,
                            props.dropdownBoxStyle,
                            boxWithDarkShadow,
                            {
                                top: dropdownTop,
                                height:
                                    44.4 *
                                    (props.data.length > 5
                                        ? 5
                                        : props.data.length),
                            },
                        ]}
                    >
                        <FlatList
                            data={props.data}
                            renderItem={renderItem}
                            showsVerticalScrollIndicator={false}
                            initialScrollIndex={
                                props.dropdownValue
                                    ? props.dropdownValue.value
                                    : 0
                            }
                            onScrollToIndexFailed={0}
                        />
                    </View>
                </TouchableOpacity>
            </Modal>
        );
    };

    return (
        <TouchableOpacity
            ref={DropdownButton}
            style={[styles.textFieldStyle, props.style]}
            onPress={toggleDropdown}
        >
            {renderDropdown()}
            <View style={{ padding: 10 }}>
                {props.dropdownValue && (
                    <TextFields.SubTitleTextBlack>
                        {props.dropdownValue.label}
                    </TextFields.SubTitleTextBlack>
                )}
                {!props.dropdownValue && (
                    <TextFields.SubTitleTextGray>
                        {props.placeholder}
                    </TextFields.SubTitleTextGray>
                )}
            </View>
            <Image
                style={styles.iconStyle}
                source={require("../../assets/common/dropdownArrow.png")}
            />
        </TouchableOpacity>
    );
};

const styles = StyleSheet.create({
    textFieldStyle: {
        borderWidth: 1,
        borderRadius: 12,
        alignItems: "center",
        flexDirection: "row",
        borderColor: getColorConfig().borderGray,
    },
    dropdownBox: {
        borderWidth: 1,
        borderRadius: 12,
        marginHorizontal: 16,
        overflow: "hidden",
        borderColor: getColorConfig().borderGray,
        backgroundColor: getColorConfig().white,
    },
    iconStyle: {
        width: 24,
        height: 24,
        marginRight: 10,
        right: 0,
        position: "absolute",
    },
    overlay: {
        width: "100%",
        height: "100%",
    },
});
