import React, { useEffect, useState } from "react";
import { Dimensions, Image, StyleSheet, TextInput, View } from "react-native";
import { getColorConfig } from "../../../../constants/colors";
import { Dropdown } from "../../../../components/dropdown";
import { MedicationReminderSelectFrequencySheet } from "./MedicationReminderSelectFrequencySheet";
import TextFields from "../../../../components/text-fields";
import HorizontalLine from "../../../../components/horizontal-line";
import TouchableWithAnimation from "../../../../components/touchable-with-animation";
import * as APIHandler from "../../../../apiHandlers/APIHandler";

const medicationDosageList = [
    { label: "mg", value: "0" },
    { label: "mcg", value: "1" },
    { label: "g", value: "2" },
    { label: "mL", value: "3" },
    { label: "%", value: "4" },
];

const medicationTypeList = [
    { label: "Capsule", value: "0" },
    { label: "Liquid", value: "1" },
    { label: "Tablet", value: "2" },
    { label: "Topical", value: "3" },
];

export const MedicationReminderSelectMedicineSheet = (props) => {
    const [medication, setMedication] = useState(null);
    const [medicationDosageStrength, setMedicationDosageStrength] =
        useState(null);
    const [medicationDosageUnit, setMedicationDosageUnit] = useState(null);
    const [medicationType, setMedicationType] = useState(null);
    const [medicationsList, setMedicationsList] = useState([]);
    const [medicationDropdownList, setMedicationDropdownList] = useState([]);

    const updateMedication = (dropdownValue) => {
        setMedication(dropdownValue.item);
    };
    const updateDosage = (dropdownValue) => {
        setMedicationDosageUnit(dropdownValue.item);
    };
    const updateMedicationType = (dropdownValue) => {
        setMedicationType(dropdownValue.item);
    };
    function findArrayElementByTitle(medicationDisplayName) {
        return medicationsList.find((element) => {
            return element.medicationDisplayName == medicationDisplayName;
        });
    }

    useEffect(() => {
        APIHandler.getMedications(
            (data) => {
                setMedicationsList(data.medicationList);
                setMedicationDropdownList(data.medicationList.map((medication, index) => {
                    return {
                        label: medication.medicationDisplayName,
                        value: "" + index
                    }
                }))
            },
            () => {
                console.log("Error fetching medications");
            }
        );    
    }, []);

    function buttonDisabled() {
        return (
            medication == null ||
            medicationDosageStrength == null ||
            medicationDosageUnit == null ||
            medicationType == null
        );
    }

    const nextButtonPressed = () => {
        var selectedMedication = findArrayElementByTitle(medication.label);
        props.bottomSheetChildren(
            <MedicationReminderSelectFrequencySheet
                openedFromMedicationDetailsScreen={false}
                medicationData={selectedMedication}
                bottomSheetChildren={props.bottomSheetChildren}
                showBottomSheet={props.showBottomSheet}
                hideBottomSheet={props.hideBottomSheet}
            />
        );
        props.showBottomSheet();
    };

    return (
        <View>
            <TextFields.TitleText2>Medication Reminder</TextFields.TitleText2>
            <HorizontalLine style={{ marginTop: 8, height: 33 }} />
            <TextFields.TitleText3_Bold>
                Add medication details
            </TextFields.TitleText3_Bold>

            <TextFields.SubTitleTextBlack
                style={{ marginTop: 24, marginBottom: 8 }}
            >
                Medication Name:
            </TextFields.SubTitleTextBlack>
            <Dropdown
                placeholder="Select medication"
                data={medicationDropdownList}
                dropdownValue={medication}
                updateDropdownValue={updateMedication}
            />
            <TextFields.SubTitleTextBlack
                style={{ marginTop: 20, marginBottom: 8 }}
            >
                Dosage:
            </TextFields.SubTitleTextBlack>
            <View style={{ flexDirection: "row" }}>
                <TextInput
                    style={[
                        styles.dosageStrength,
                        {
                            color: getColorConfig().black,
                            fontFamily: "MetropolisMedium",
                            fontSize: 16,
                        },
                    ]}
                    selectionColor={getColorConfig().primaryColor}
                    placeholderTextColor={getColorConfig().mainGray}
                    value={medicationDosageStrength}
                    onChangeText={setMedicationDosageStrength}
                    placeholder="Add strength"
                    keyboardType="numeric"
                    returnKeyType={"done"}
                />

                <Dropdown
                    placeholder="Add dosage"
                    data={medicationDosageList}
                    dropdownValue={medicationDosageUnit}
                    updateDropdownValue={updateDosage}
                    dropdownBoxStyle={{
                        right: 0,
                        position: "absolute",
                        width: (Math.min(550, Dimensions.get("window").width) - 56) / 2,
                    }}
                    style={{
                        marginLeft: 12,
                        width: (Math.min(550, Dimensions.get("window").width) - 56) / 2,
                    }}
                />
            </View>
            <TextFields.SubTitleTextBlack
                style={{ marginTop: 20, marginBottom: 8 }}
            >
                Type:
            </TextFields.SubTitleTextBlack>
            <Dropdown
                placeholder="Select type of medication"
                data={medicationTypeList}
                dropdownValue={medicationType}
                updateDropdownValue={updateMedicationType}
            />
            <View style={{ marginTop: 200 }}>
                <View style={{ position: "absolute", bottom: 0, right: 0 }}>
                    <TouchableWithAnimation
                        onPress={() => nextButtonPressed()}
                        disable={buttonDisabled() ? true : false}
                    >
                        <View
                            style={
                                buttonDisabled()
                                    ? styles.nextQuestionButtonDisabled
                                    : styles.nextQuestionButton
                            }
                        >
                            <View
                                style={{
                                    flexDirection: "row",
                                    alignItems: "center",
                                }}
                            >
                                <NextButton buttonDisabled={buttonDisabled} />
                                <Image
                                    style={{
                                        marginLeft: 4,
                                        width: 16,
                                        height: 16,
                                    }}
                                    source={
                                        buttonDisabled()
                                            ? require("../../../../../assets/arrows/rightIconGray.png")
                                            : require("../../../../../assets/arrows/rightIconWhite.png")
                                    }
                                />
                            </View>
                        </View>
                    </TouchableWithAnimation>
                </View>
            </View>
        </View>
    );
};

export const NextButton = (props) => {
    if (props.buttonDisabled()) {
        return <TextFields.SubTitleTextGray>Next</TextFields.SubTitleTextGray>;
    } else {
        return <TextFields.WhiteButtonText>Next</TextFields.WhiteButtonText>;
    }
};

const styles = StyleSheet.create({
    nextQuestionButtonDisabled: {
        width: 96,
        height: 48,
        backgroundColor: getColorConfig().disabledButtonGray,
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 8,
    },
    nextQuestionButton: {
        width: 96,
        height: 48,
        backgroundColor: getColorConfig().primaryColor,
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 8,
    },
    dosageStrength: {
        borderWidth: 1,
        borderRadius: 12,
        paddingVertical: 12,
        paddingHorizontal: 12,
        borderColor: getColorConfig().borderGray,
        width: (Math.min(550, Dimensions.get("window").width) - 32) / 2,
    },
});
