import * as React from "react";

import {
    Animated,
    Dimensions,
    Easing,
    Image,
    Platform,
    Pressable,
    StyleSheet,
    View,
} from "react-native";

export const HEIGHT_DEFAULT = 300;

export const BottomSheetContext = React.createContext({
    showBottomSheet: () => {},
    hideBottomSheet: () => {},
    bottomSheetChildren: () => {},
});

export class BottomSheetView extends React.Component {
    constructor(props) {
        super(props);
        this.height = props.height;

        if (!this.height) {
            this.height = HEIGHT_DEFAULT;
        }

        // initial state - sheet closed
        this.state = {
            bottom: new Animated.Value(0),
            show: false,
            children: props.children,
        };
    }

    setChildren = (children) => {
        this.setState({ children });
    };

    showBottomSheet = () => {
        this.setState({ show: true });
        Animated.timing(this.state.bottom, {
            toValue: 1,
            duration: 350,
            easing: Easing.bezier(0.28, 0, 0.63, 1),
            useNativeDriver: false,
        }).start();
    };

    hideBottomSheet = () => {
        Animated.timing(this.state.bottom, {
            toValue: 0,
            duration: 250,
            easing: Easing.cubic,
            useNativeDriver: false,
        }).start();
        this.setState({ show: false });
    };

    render() {
        const screenHeight = Dimensions.get("window").height;
        return (
            <>
                {this.state.show && (
                    <Pressable
                        onPress={this.hideBottomSheet}
                        style={[styles.outerOverlay, { height: screenHeight }]}
                    >
                        <View />
                    </Pressable>
                )}
                {/* Special handling for web because the View is showing up on web on scrolling */}
                {(Platform.OS !== "web" || this.state.show) && (
                    <Animated.View
                        style={[
                            styles.bottomSheet,
                            {
                                height: this.height,
                                bottom: this.state.bottom.interpolate({
                                    inputRange: [0, 1],
                                    outputRange: [-this.height, 0],
                                }),
                            },
                        ]}
                    >
                        <View style={{ padding: 16 }}>
                            {this.state.children}
                        </View>
                        <Pressable
                            onPress={this.hideBottomSheet}
                            style={styles.bottomSheetCloseButton}
                        >
                            <Image
                                style={{ width: 28, height: 28 }}
                                source={require("../../assets/common/crossGrayWithBg.png")}
                            />
                        </Pressable>
                    </Animated.View>
                )}
            </>
        );
    }
}

const styles = StyleSheet.create({
    outerOverlay: {
        position: "absolute",
        width: "100%",
        zIndex: 1,
        backgroundColor: "black",
        opacity: 0.3,
    },
    bottomSheet: {
        position: "absolute",
        width: "100%",
        zIndex: 1,
        backgroundColor: "white",
        borderTopRightRadius: 16,
        borderTopLeftRadius: 16,
    },
    bottomSheetCloseButton: {
        position: "absolute",
        right: 16,
        top: 16,
    },
});
