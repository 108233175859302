import React from "react";
import * as Font from "expo-font";
import LanaAppState from "./src/utils/lana-app-state";
import { LogBox, Platform, StyleSheet, View } from 'react-native';
import LoginScreen from "./src/pages/LoginScreen";
LogBox.ignoreLogs(['Warning: ...']); // Ignore log notification by message
LogBox.ignoreAllLogs();//Ignore all log notifications

const customFonts = {
    MetropolisBold: require("./assets/fonts/Metropolis-Bold.otf"),
    MetropolisExtraLight: require("./assets/fonts/Metropolis-ExtraLight.otf"),
    MetropolisMedium: require("./assets/fonts/Metropolis-Medium.otf"),
    MetropolisSemiBold: require("./assets/fonts/Metropolis-SemiBold.otf"),
    MetropolisExtraBold: require("./assets/fonts/Metropolis-ExtraBold.otf"),
    MetropolisLight: require("./assets/fonts/Metropolis-Light.otf"),
    MetropolisRegular: require("./assets/fonts/Metropolis-Regular.otf"),
    MetropolisThin: require("./assets/fonts/Metropolis-Thin.otf"),
};

export default class App extends React.Component {
    state = {
        fontsLoaded: false,
        // loginCompleted: false,
        data: { activity: "Loading..." },
    };

    async _loadFontsAsync() {
        await Font.loadAsync(customFonts);
        this.setState({ fontsLoaded: true });
    }

    constructor() {
        super();
        let hospitalId = undefined;
        if(Platform.OS === 'web') {
            if((window) && (window.location) && (window.location.search)) {
                const params = new Proxy(new URLSearchParams(window.location.search), {
                    get: (searchParams, prop) => searchParams.get(prop),
                });
                hospitalId = params.hospital_id;    
            }
        }
        if(hospitalId) {
            LanaAppState.getInstance().setHospitalId(hospitalId);
        } else {
            LanaAppState.getInstance().setHospitalId("HS200919190755123123");
        }
    }

    componentDidMount() {
        this._loadFontsAsync();
    }

    render() {
        if (!this.state.fontsLoaded) {
            return null;
        }

        return (
            <View style={styles.container}>
                <View style={styles.content}>
                    <LoginScreen />
                </View>
            </View>
        )
    }
}

const styles = StyleSheet.create({
    container: {
      flex: 1,
      paddingVertical: 12,
      alignItems: "center",
    },
    content: {
      flex: 1,
      width: "100%",
      maxWidth: 550,
    }
  })
