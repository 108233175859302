import React from "react";
import { View, ScrollView, StyleSheet, ActivityIndicator } from "react-native";
import * as APIHandler from "../../../apiHandlers/APIHandler";
import ResourceCard2 from "../../../components/resource-card-2";
import TextFields from "../../../components/text-fields";
import TouchableWithAnimation from "../../../components/touchable-with-animation";

export default class LabResultsScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            labs: []
        }
    }

    componentDidMount() {
        APIHandler.getLabs(
            (data) => {
                this.setState({
                    loaded: true,
                    labs: data.labResults
                });
            },
            () => {
                console.log("Error fetching labs");
            }
        );
    }

    render() {
        if(!this.state.loaded) {
            return (<View style={{flex: 1, flexDirection: "column", justifyContent: "center"}}>
                    <ActivityIndicator size={"large"}/>
                </View>)
        } else {
            return (
                <ScrollView
                    showsVerticalScrollIndicator={false}
                    style={{ paddingTop: 16 }}
                >
                    <View style={styles.appointmentContentView}>
                        <View style={{ paddingLeft: 16, paddingTop: 16 }}>
                            <TextFields.TitleTextGray1>
                                Lab Results
                            </TextFields.TitleTextGray1>
                        </View>
                        {this.state.labs.map((lab) => {
                            return (<TouchableWithAnimation
                                scaleFactor={0.95}
                                onPress={() => {}}>
                                <ResourceCard2
                                    icon={require("../../../../assets/healthData/labResultsIconColor.png")}
                                    title={lab.labResultName}
                                    subTitle={lab.latestObservationRecord.value.value + " " + lab.latestObservationRecord.value.unit}
                                    arrowIcon={true}
                                />
                            </TouchableWithAnimation>)
                        })}
                    </View>
                </ScrollView>
            );
        }
    }
}

const styles = StyleSheet.create({
    appointmentContentView: {
        flex: 1,
    },
});
