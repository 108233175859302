import React from "react";
import { StyleSheet, Dimensions, View, Platform } from "react-native";
import WebView from "react-native-webview";

export default class DicomWebviewScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dicomUrl: props.route.params.dicomUrl
        }
    }

    render() {
        if(Platform.OS === 'web') {
            return (
                <iframe src={this.state.dicomUrl} height={Dimensions.get('window').height} width={Math.min(550, Dimensions.get('window').width)} />
            );
        } else {
            return (
                <WebView
                    source={{
                        uri: this.state.dicomUrl,
                    }}
                    style={{
                        height: Dimensions.get('window').height,
                        width: Math.min(550, Dimensions.get('window').width)
                    }}
                />
            )    
        }
    }
}