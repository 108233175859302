import React from "react";
import DateTimePickerModal from "react-native-modal-datetime-picker";

export const DateTimeSelectorDevice = (props) => {
    return (
        <DateTimePickerModal
            mode={props.mode}
            date={props.selectedDate}
            isVisible={props.isPickerVisible}
            onConfirm={props.handleConfirm}
            onCancel={props.hidePicker}
            display={props.display}
        />
    );
};
