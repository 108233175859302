import React from "react";
import { Image, StyleSheet, View } from "react-native";
import { getColorConfig } from "../constants/colors";
import TextFields from "./text-fields";
import TouchableWithAnimation from "./touchable-with-animation";

export const PrimaryButton = (props) => {
    return (
        <TouchableWithAnimation
            onPress={() => {
                props.onPress();
            }}
        >
            <View style={[styles.button, props.style]}>
                {props.icon && <Image
                    style={{
                        width: 24,
                        height: 24,
                        marginRight: 8,
                    }}
                    source={props.icon}
                />}
                <TextFields.WhiteButtonText>
                    {props.buttonText}
                </TextFields.WhiteButtonText>
            </View>
        </TouchableWithAnimation>
    );
};

const styles = StyleSheet.create({
    button: {
        left: 0,
        right: 0,
        padding: 12,
        borderRadius: 12,
        marginVertical: 10,
        alignItems: "center",
        flexDirection: "row",
        justifyContent: "center",
        backgroundColor: getColorConfig().primaryColor,
    },
});
