import React from "react";
import { Image, View, StyleSheet } from "react-native";
import { DATE_FORMAT } from "../../../constants/date-format";
import dateFormat from "dateformat";
import TextFields from "../../../components/text-fields";
import HorizontalLine from "../../../components/horizontal-line";

export const AppointmentDetailSheet = (props) => {
    const appointmentData = props.appointmentData;

    return (
        <View>
            <TextFields.TitleText1>
                {appointmentData.appointmentStatus === "FULFILLED"
                    ? "Past"
                    : "Upcoming"}{" "}
                Appointment
            </TextFields.TitleText1>

            <HorizontalLine style={{ height: 33 }} />

            <TextFields.TitleText3_Bold>
                {dateFormat(appointmentData.startTime, DATE_FORMAT.time) +
                    " - " +
                    dateFormat(
                        appointmentData.endTime,
                        DATE_FORMAT.time_with_marker
                    )}
            </TextFields.TitleText3_Bold>

            <TextFields.SubTitleTextBlack style={{ marginDown: 20 }}>
                {dateFormat(appointmentData.startTime, DATE_FORMAT.day_date)}
            </TextFields.SubTitleTextBlack>

            <View style={{ height: 20 }}></View>

            <View style={styles.card}>
                <Image
                    style={styles.image1}
                    source={require("../../../../assets/healthData/doctor.png")}
                />
                <View style={styles.text1}>
                    <TextFields.SubTitleTextBlack>
                        {appointmentData.practitioner.name}
                    </TextFields.SubTitleTextBlack>
                    {appointmentData.practitioner.practitionerRole && (
                        <TextFields.SubTitleTextGray>
                            {appointmentData.practitioner.practitionerRole}
                        </TextFields.SubTitleTextGray>
                    )}
                    {(!appointmentData.practitioner.practitionerRole) &&
                        <TextFields.SubTitleTextGray>
                            General Physician
                        </TextFields.SubTitleTextGray>
                    }
                </View>
            </View>

            <View style={{ height: 20 }}></View>

            <View style={styles.card}>
                <Image
                    style={styles.image1}
                    source={require("../../../../assets/healthData/hospitalUnit.png")}
                />
                <View style={styles.text1}>
                    <TextFields.SubTitleTextBlack>
                        {appointmentData.location.displayName}
                    </TextFields.SubTitleTextBlack>
                    {appointmentData.location.address && (
                        <TextFields.SubTitleTextGray>
                            {appointmentData.location.address}
                        </TextFields.SubTitleTextGray>
                    )}
                    {(!appointmentData.location.address) &&
                        <TextFields.SubTitleTextGray>
                            "St. John's Hospital, 21st Street, Delaney Park, Orlando, FL"
                        </TextFields.SubTitleTextGray>
                    }
                </View>
            </View>

            <View style={{ height: 4 }}></View>

            {/* <View style={styles.card}>
                <Image
                    style={styles.image2}
                    source={require("../../../../assets/common/directionPurple.png")}
                />
                <View style={styles.text2}>
                    <TextFields.ButtonTextPurple>
                        Get directions
                    </TextFields.ButtonTextPurple>
                </View>
            </View> */}
        </View>
    );
};

const styles = StyleSheet.create({
    card: {
        marginHorizontal: 16,
        flexDirection: "row",
    },
    image1: {
        width: 48,
        height: 48,
    },
    image2: {
        width: 20,
        height: 20,
        marginLeft: 64,
    },
    image3: {
        width: 396,
        height: 200,
        marginTop: 12,
    },
    text1: {
        flexDirection: "column",
        justifyContent: "center",
        marginLeft: 16,
        marginRight: 16
    },
    text2: {
        flexDirection: "column",
        justifyContent: "center",
        marginLeft: 4,
    },
});
