import React from "react";
import {
    BackHandler,
    Image,
    Platform,
    ScrollView,
    StyleSheet,
    View,
    Dimensions,
    ActivityIndicator,
    Text
} from "react-native";
import { getColorConfig } from "../../../../constants/colors";
import { PrimaryButton } from "../../../../components/primary-button";
import { boxWithDarkShadow } from "../../../../constants/common-styles";
import { BottomSheetContext } from "../../../../components/bottom-sheet-view";
import TextFields from "../../../../components/text-fields";
import HorizontalLine from "../../../../components/horizontal-line";
import CreateReminderSheet from "../MedicationReminder/CreateReminderSheet";
import * as APIHandler from "../../../../apiHandlers/APIHandler";

export default class MedicationLogRemindersList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            reminders: [],
            loading: true
        }
    }

    handleBackButton = () => {
        BackHandler.removeEventListener(
            "hardwareBackPress",
            this.handleBackButton
        );
        this.hideBottomSheet();
        return true;
    };
    componentDidMount() {
        BackHandler.addEventListener(
            "hardwareBackPress",
            this.handleBackButton
        );
        this.fetchReminders();
        setInterval(() => this.fetchReminders(), 2500);
    }

    fetchReminders() {
        APIHandler.getRemindersForPatient(
            (data) => {
                this.setState({
                    reminders: data.data,
                    loading: false
                });
            },
            () => {
                console.log("Error fetching patient reminders");
            }
        );
    }

    render() {
        const screenHeight = Dimensions.get("window").height - 200;
        if(this.state.loading) {
            return (
                <View style={{flex: 1, flexDirection: 'column', justifyContent: 'center'}}>
                    <ActivityIndicator size={"large"}/>
                </View>)
        } else {
            if(this.state.reminders.length == 0) {
                return (
                    <View style={{ alignItems: "center", paddingTop: 48 }}>
                        <TextFields.TitleText1>No Reminders</TextFields.TitleText1>
                    </View>
                );
            } else {
                return (
                    <View style={{paddingTop: 16}}>
                        <View style={{height: screenHeight}}>
                            <ScrollView showsVerticalScrollIndicator={false}>
                                {this.reminderCards()}
                                <View style={{height: 96}} />
                            </ScrollView>
                        </View>
                        {this.createReminderButton()}
                    </View>
                )    
            }
        }
    }

    reminderCards() {
        return this.state.reminders.map((reminder) => {
            if(!reminder.medication) {
                return <></>
            }
            return (
                <View
                    key={"medication_" + reminder.medication.medicationId}
                    style={[styles.actionCardStyle, boxWithDarkShadow]}
                >
                    <View style={{ flexDirection: "row" }}>
                        <Image
                            style={{ width: 48, height: 48, borderRadius: 24 }}
                            source={require("../../../../../assets/healthData/medicine.png")}
                        />
                        <View
                            style={{
                                flexDirection: "column",
                                justifyContent: "center",
                                marginLeft: 16,
                                marginRight: 40,
                            }}
                        >
                            <TextFields.TitleText1Lines2>
                                {reminder.medication.medicationDisplayName}
                            </TextFields.TitleText1Lines2>

                            <TextFields.SubTitleTextGray>
                                {reminder.medication.dosageInstructions[0].display}
                            </TextFields.SubTitleTextGray>
                        </View>
                    </View>

                    <HorizontalLine style={{ height: 33 }} />
                    <View style={{ flexDirection: "row", marginBottom: 8 }}>
                        <Image
                            style={{ width: 24, height: 24, marginRight: 8 }}
                            source={require("../../../../../assets/healthData/calendarGray.png")}
                        />
                        {reminder.schedule.recurrenceType === 'DAILY' &&
                            <TextFields.SubTitleTextBlack>
                                Every day
                            </TextFields.SubTitleTextBlack>
                        }
                        {reminder.schedule.recurrenceType === 'EVERY_X_DAYS' && reminder.schedule.everyXDaysRecurrences[0].daysToSkip == 1 &&
                            <TextFields.SubTitleTextBlack>
                                Every other day
                            </TextFields.SubTitleTextBlack>
                        }
                        {reminder.schedule.recurrenceType === 'EVERY_X_DAYS' && 
                        reminder.schedule.everyXDaysRecurrences[0].daysToSkip > 1 &&
                            <TextFields.SubTitleTextBlack>
                                Every {reminder.schedule.everyXDaysRecurrences[0].daysToSkip+1} days
                            </TextFields.SubTitleTextBlack>
                        }
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Image
                            style={{ width: 24, height: 24, marginRight: 8 }}
                            source={require("../../../../../assets/healthData/notificationGray.png")}
                        />
                        <TextFields.SubTitleTextBlack>
                            {reminder.schedule.dailyRecurrencesList &&
                                reminder.schedule.dailyRecurrencesList.map((dailyRecurrence, index) => {
                                    return ((index>0) ? ", ": "") + this.getTimeStringFromDailyRecurrence(dailyRecurrence)
                                })
                            }
                            {reminder.schedule.everyXDaysRecurrences && 
                                reminder.schedule.everyXDaysRecurrences.map((dailyRecurrence, index) => {
                                    return ((index>0) ? ", ": "") + this.getTimeStringFromDailyRecurrence(dailyRecurrence)
                                })
                            }
                        </TextFields.SubTitleTextBlack>
                    </View>
                </View>
            );
        });
    }

    showMedicationReminderSheet = () => {
        this.bottomSheetChildren(
            <CreateReminderSheet
                bottomSheetChildren={this.bottomSheetChildren}
                showBottomSheet={this.showBottomSheet}
                hideBottomSheet={this.hideBottomSheet}
            />
        );
        this.showBottomSheet();
    };

    createReminderButton() {
        return (
            <View style={styles.createReminderButton}>
                <BottomSheetContext.Consumer>
                    {({
                        showBottomSheet,
                        hideBottomSheet,
                        bottomSheetChildren,
                    }) => {
                        this.showBottomSheet = showBottomSheet;
                        this.hideBottomSheet = hideBottomSheet;
                        this.bottomSheetChildren = bottomSheetChildren;
                        return (
                            <PrimaryButton
                                icon={require("../../../../../assets/common/addIconWhite.png")}
                                buttonText={"Create reminder"}
                                onPress={this.showMedicationReminderSheet}
                            />
                        );
                    }}
                </BottomSheetContext.Consumer>
            </View>
        );
    }

    getTimeStringFromDailyRecurrence(dailyRecurrence) {
        let hour = "" + dailyRecurrence.hourOfTheDay;
        let minute = "" + dailyRecurrence.minuteOfTheHour;

        if(dailyRecurrence.hourOfTheDay < 10) {
            hour = "0" + hour;
        }

        if(dailyRecurrence.minuteOfTheHour < 10) {
            minute = "0" + minute;
        }

        return hour + ":" + minute
    }
}

const styles = StyleSheet.create({
    actionCardStyle: {
        marginHorizontal: 16,
        marginVertical: 6,
        padding: 16,
        borderRadius: 12,
        backgroundColor: getColorConfig().white,
    },
    createReminderButton: {
        position: 'absolute',
        bottom: 0,
        left: 16,
        right: 16
        // Todo - test on android
    },
});
