import React from "react";
import { ActivityIndicator, Dimensions, Image, StyleSheet, Text, View } from "react-native";
import { getColorConfig } from "../constants/colors";
import { tabIconImage } from "../utils/utils";
import { NavigationContainer } from "@react-navigation/native";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import {
    BottomSheetContext,
    BottomSheetView,
} from "../components/bottom-sheet-view";
import HomeScreenNavigator from "../navigators/HomeScreenNavigator";
import HealthDataScreenNavigator from "../navigators/HealthDataScreenNavigator";
import EducationScreenNavigator from "../navigators/EducationScreenNavigator";
import ChatScreenNavigator from "../navigators/ChatScreenNavigator";
import { storeObjectData } from "../utils/storage-helper";
import { MEDICATION_OBJECT_DATA } from "./Home/LogYourMedicationSheet";
import { MESSAGES_STORE_KEY } from "./Chat/ChatScreen";
import { APP_RATING_VAR_NAME } from "../components/primary-action-cards";
import LanaAppState from "../utils/lana-app-state";
import * as APIHandler from "../../src/apiHandlers/APIHandler";

const Tab = createBottomTabNavigator();

export default class Main extends React.Component {
    constructor(props) {
        super(props);

        // TODO: Remove the following lines after testing is complete
        // storeObjectData(MEDICATION_OBJECT_DATA, null);
        // storeObjectData(MESSAGES_STORE_KEY, null);
        // storeObjectData(APP_RATING_VAR_NAME, null);

        this.bottomSheetViewRef = React.createRef();
        this.state = {
            loading: true,
            errorMessage: null,
            showBottomSheet: () =>
                this.bottomSheetViewRef.current.showBottomSheet(),
            hideBottomSheet: () =>
                this.bottomSheetViewRef.current.hideBottomSheet(),
            bottomSheetChildren: (children) =>
                this.bottomSheetViewRef.current.setChildren(children),
        };
    }

    componentDidMount() {
        APIHandler.getHospitalHomeCareConfig(
            (data) => {
                LanaAppState.getInstance().setHomeCareTabTypes(data.homeCareTabTypes);
                LanaAppState.getInstance().setEnabledAuthFeatures(data.enabledAuthFeatures);
                this.setState({ loading: false });
            },
            () => {
                this.setState({ errorMessage: "Something went wrong (Error code: 102)" });    
            }
        )
    }

    render() {
        if(this.state.errorMessage) {
            return (
                <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
                    <Text>{this.state.errorMessage}</Text>
                </View>
            )
        } else if(this.state.loading) {
            return (
                <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
                    <ActivityIndicator />
                </View>
            )
        } else {
            return (
                <BottomSheetContext.Provider value={this.state}>
                    <NavigationContainer>
                        <Tab.Navigator
                            screenOptions={({ route }) => ({
                                tabBarIcon: ({ focused }) => {
                                    return (
                                        <Image
                                            style={styles.tabIconImage}
                                            source={tabIconImage(
                                                route.name,
                                                focused
                                            )}
                                        />
                                    );
                                },
                                tabBarLabelStyle: {
                                    fontSize: 12,
                                    lineHeight: 16,
                                    fontFamily: "MetropolisSemiBold",
                                },
                                tabBarActiveTintColor: getColorConfig().black,
                                tabBarInactiveTintColor: getColorConfig().mainGray,
                                headerShown: false,
                            })}
                        >
                            {LanaAppState.getInstance().getHomeCareTabTypes().includes("HOME") &&
                                <Tab.Screen
                                    name="Home"
                                    component={HomeScreenNavigator}
                                    options={{
                                        unmountOnBlur: true,
                                    }}
                                />
                            }
                            {LanaAppState.getInstance().getHomeCareTabTypes().includes("MY_HEALTH") &&
                                <Tab.Screen
                                    name="My Health"
                                    component={HealthDataScreenNavigator}
                                    options={{
                                        unmountOnBlur: true,
                                    }}
                                />
                            }
                            {LanaAppState.getInstance().getHomeCareTabTypes().includes("LEARN") &&
                                <Tab.Screen
                                    name="Learn"
                                    component={EducationScreenNavigator}
                                    options={{
                                        unmountOnBlur: true,
                                    }}
                                />
                            }
                            {LanaAppState.getInstance().getHomeCareTabTypes().includes("CHAT") &&
                                <Tab.Screen
                                    name="Chat"
                                    component={ChatScreenNavigator}
                                    options={{
                                        unmountOnBlur: true,
                                    }}
                                />
                            }
                        </Tab.Navigator>
                        <BottomSheetView
                            ref={this.bottomSheetViewRef}
                            height={Dimensions.get("window").height * 0.84}
                        />
                    </NavigationContainer>
                </BottomSheetContext.Provider>
            );
        }
    }
}

const styles = StyleSheet.create({
    tabIconImage: {
        width: 20,
        height: 20,
    },
});
