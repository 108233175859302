import React from "react";
import {
    ActivityIndicator,
    Dimensions,
    Image,
    Platform,
    ScrollView,
    StyleSheet,
    View,
} from "react-native";
import { getColorConfig } from "../../constants/colors";
import { EDUCATION_STATUS } from "../../constants/education-status";
import { boxWithDarkShadow } from "../../constants/common-styles";
import * as APIHandler from "../../apiHandlers/APIHandler";
import TextFields from "../../components/text-fields";
import HorizontalLine from "../../components/horizontal-line";
import CommonComponents from "../../components/common-components";
import EducationActionCard from "../../components/education-action-card";
import EducationDisplayCard from "../../components/education-display-card";
import TouchableWithAnimation from "../../components/touchable-with-animation";

export default class EducationScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            inProgressEducationData: [],
            assignedEducationData: [],
            completedEducationData: [],
            educationItemsByCategory: new Map(),
            totalEducationItemsCount: 0,
            educationLoading: true
        };
    }

    componentDidMount() {
        APIHandler.getPatientEducation(
            (data) => {
                let educationItemsByCategory = new Map();

                data.patientEducationResponses.forEach(educationItem => {
                    const category  = educationItem.educationAsset.educationCategory || educationItem.educationAsset.category || 'Others';

                    console.log("category", category);

                    if(!educationItemsByCategory.has(category)) {
                        educationItemsByCategory.set(category, []);
                    }
                    educationItemsByCategory.get(category).push(educationItem);
                });

                this.setState({
                    inProgressEducationData:
                        data.patientEducationResponses.filter(
                            (response) =>
                                response.progress ==
                                EDUCATION_STATUS.IN_PROGRESS
                        ),
                    assignedEducationData:
                        data.patientEducationResponses.filter(
                            (response) =>
                                response.progress == EDUCATION_STATUS.ASSIGNED
                        ),
                    completedEducationData:
                        data.patientEducationResponses.filter(
                            (response) =>
                                response.progress == EDUCATION_STATUS.COMPLETED
                        ),
                    educationItemsByCategory: educationItemsByCategory,
                    totalEducationItemsCount: data.patientEducationResponses.length,
                    educationLoading: false
                });
            },
            () => {
                this.setState({educationLoading: false})
                console.log("Error fetching patient education");
            }
        );
    }

    navigateToEducationDetailScreen = (educationData) => {
        this.props.navigation.navigate("EducationDetailScreen", {
            educationData: educationData,
        });
    };

    completionCard() {
        let completedVideosCount = this.state.completedEducationData.length;
        let totalVideosCount =
            this.state.completedEducationData.length +
            this.state.assignedEducationData.length +
            this.state.inProgressEducationData.length;

        return (
            <View style={[styles.resourceCard, boxWithDarkShadow]}>
                <Image
                    style={{
                        width: 56,
                        height: 56,
                    }}
                    source={require("../../../assets/resourceCardIcons/videosWatched.png")}
                />

                <View
                    style={{
                        flex: 1,
                        flexDirection: "column",
                        justifyContent: "center",
                        marginLeft: 16,
                    }}
                >
                    <View
                        style={{
                            marginTop: 8,
                            flexDirection: "row",
                        }}
                    >
                        <TextFields.ButtonTextPurple style={{marginRight: 8}}>
                            {completedVideosCount} / {totalVideosCount}
                        </TextFields.ButtonTextPurple>

                        <TextFields.SubTitleTextGray>
                            Videos watched
                        </TextFields.SubTitleTextGray>
                    </View>

                    <CommonComponents.ProgressBar
                        progressRate={completedVideosCount / totalVideosCount}
                        backgroundColor={getColorConfig().mainLightColor}
                        highlightColor={getColorConfig().primaryColor}
                        style={{ marginBottom: 8, marginTop: 12 }}
                    />
                </View>
            </View>
        );
    }

    continueWatchingBigCard(educationData) {
        return (
            <TouchableWithAnimation
                key={
                    "continueWatchingBigCard-" +
                    educationData.patientEducationId
                }
                scaleFactor={0.95}
                onPress={() =>
                    this.navigateToEducationDetailScreen(educationData)
                }
            >
                <View
                    style={[styles.educationCardContainer, boxWithDarkShadow]}
                >
                    <EducationActionCard
                        imageUrl={educationData.educationAsset.thumbnailWide}
                        title={
                            educationData.educationAsset.languagesAvailable[0]
                                .displayName
                        }
                        duration={new Date(
                            educationData.educationAsset.languagesAvailable[0]
                                .durationInSeconds * 1000
                        )
                            .toISOString()
                            .slice(14, 19)}
                        completionRate={0.2}
                    />
                </View>
            </TouchableWithAnimation>
        );
    }

    educationDisplayCard(educationData) {
        let durationString = ""
        if(educationData.educationAsset.languagesAvailable[0].durationInSeconds) {
            durationString = new Date(
                educationData.educationAsset.languagesAvailable[0]
                    .durationInSeconds * 1000)
                .toISOString()
                .slice(14, 19)
        }
        return (
            <TouchableWithAnimation
                key={"educationDisplayCard-" + educationData.patientEducationId}
                scaleFactor={0.95}
                onPress={() =>
                    this.navigateToEducationDetailScreen(educationData)
                }
            >
                <EducationDisplayCard
                    assetType={educationData.educationAsset.assetType}
                    imageUrl={educationData.educationAsset.thumbnailWide}
                    title={
                        educationData.educationAsset.languagesAvailable[0]
                            .displayName
                    }
                    duration={durationString}
                    status={educationData.progress}
                />
            </TouchableWithAnimation>
        );
    }

    render() {
        return (
            <View style={{flex: 1}}>
            {(this.state.educationLoading) &&
                <View style={{
                        position: 'absolute', top: 0, left: 0, right: 0, bottom: 0,
                        zIndex: 1000, backgroundColor: '#F8F7F7', flex: 1, justifyContent: 'center', alignItems: 'center'}}>
                    <ActivityIndicator size={"large"} />
                </View>
            }

                <ScrollView
                    showsVerticalScrollIndicator={false}
                    style={{ paddingTop: 16 }}
                >
                    <View style={{paddingHorizontal: 16, paddingBottom: 20, flexDirection: 'row', alignItems: 'center', gap: 8}}>
                        <TextFields.MainHeadingText>
                            Saved Content
                        </TextFields.MainHeadingText>
                        <View style={styles.countLabel}>
                            {this.state.totalEducationItemsCount}
                        </View>
                    </View>

                    <View style={styles.homeContentView}>

                        {[...this.state.educationItemsByCategory.keys()].map((category) => {
                            return (
                                <View style={{marginBottom: 16}} key={"educationItemsByCategory-" + category}>
                                    <TextFields.TitleTextGray1
                                        style={{ paddingHorizontal: 16, paddingTop: 4 }}
                                    >
                                        {category}
                                    </TextFields.TitleTextGray1>
                                    {this.state.educationItemsByCategory.get(category).map((educationItem) => {
                                        return this.educationDisplayCard(educationItem);
                                    })}
                                </View>
                            )
                        })}
                    </View>
                    <View style={{ height: 32 }}></View>
                </ScrollView>
            </View>
        );
    }
}

const styles = StyleSheet.create({
    greetingTitle: {
        paddingHorizontal: 16,
        flexDirection: "column",
    },
    greetingEmoji: {
        width: 28,
        height: 28,
    },
    homeContentView: {
        flex: 1,
    },
    educationCardContainer: {
        height: 240,
        margin: 16,
        borderRadius: 12,
        overflow: "hidden",
    },
    resourceCard: {
        padding: 16,
        marginTop: 16,
        marginHorizontal: 16,
        borderRadius: 12,
        flexDirection: "row",
        backgroundColor: getColorConfig().white,
    },
    countLabel: {
        fontFamily: 'MetropolisBold',
        backgroundColor: getColorConfig().lightGray,
        padding: 8,
        borderRadius: 32
    }
});
