import * as apiCall from "../utils/networking-helper";
import LanaAppState from "../utils/lana-app-state";
import * as Localization from "expo-localization";
import * as Crypto from 'expo-crypto';
import * as Notifications from 'expo-notifications';

export function login(username, password, successCallback, failureCallback) {
    Crypto.digestStringAsync(
        Crypto.CryptoDigestAlgorithm.SHA256,
        password
    ).then((encryptedPassword) => {
        apiCall.makePostCall(
            "auth/home-care/login",
            {
                hospitalId: LanaAppState.getInstance().getHospitalId(),
                username: username,
                password: encryptedPassword,
            },
            successCallback,
            failureCallback
        );
    });
}

export function loginWithPasscode(passcode, successCallback, failureCallback) {
    apiCall.makePostCall(
        "auth/home-care/login",
        {
            hospitalId: LanaAppState.getInstance().getHospitalId(),
            passcode: passcode
        },
        successCallback,
        failureCallback
    );
}

async function _NotificationsSetupAsync() {
    // setup push notifications only for ios for now
    if(Platform.OS === 'ios') {
        let productionMode = true;
        if(__DEV__) {
            productionMode = false;
        }
        const token = (await Notifications.getDevicePushTokenAsync()).data;
        console.log("iOS: token in _NotificationsSetupAsync", token);
        console.log("productionMode", productionMode);

        APIHandler.registerDevice(
            token,
            productionMode,
            (data) => {
                console.log("Register device response: ", data);
            },
            () => {
                console.log("Error calling register device");
            }
        );
    }
}

export function failureLoginCallback(failureCallback, error, errorCode) {
    failureCallback(error)
}

export function registerDevice(token, isProductionDevice, successCallback, failureCallback) {
    apiCall.makePostCall(
        "user-data/register-device/homecare",
        {
            hospitalId: LanaAppState.getInstance().getHospitalId(),
            encounterId: LanaAppState.getInstance().getPatientId(),
            encounterId: LanaAppState.getInstance().getEncounterId(),
            deviceRegistrationToken: token,
            isProductionDevice: isProductionDevice
        },
        successCallback,
        (error, errorCode) => failureLoginCallback(failureCallback, error, errorCode)
    );
}

export function getCoordinations(successCallback, failureCallback) {
    apiCall.makeGetCall(
        "home-care-coordinator/get-coordinations",
        successCallback,
        (error, errorCode) => failureLoginCallback(failureCallback, error, errorCode)
    );
}

export function getPatientEducation(successCallback, failureCallback) {
    apiCall.makeGetCall(
        "patient-education/home-care/patient/" +
            LanaAppState.getInstance().getHospitalId() +
            "/" +
            LanaAppState.getInstance().getPatientId(),
        successCallback,
        (error, errorCode) => failureLoginCallback(failureCallback, error, errorCode)
    );
}

export function getPreLoginConfig(successCallback, failureCallback) {
    apiCall.makeGetCall(
        "core/config/hospital-patient-app-open-config/" + LanaAppState.getInstance().getHospitalId(),
        successCallback,
        (error, errorCode) => failureLoginCallback(failureCallback, error, errorCode)
    );
}

export function getHospitalHomeCareConfig(successCallback, failureCallback) {
    apiCall.makeGetCall(
        "core/config/hospital-homecare-app-config/" + LanaAppState.getInstance().getHospitalId(),
        successCallback,
        (error, errorCode) => failureLoginCallback(failureCallback, error, errorCode)
    );
}

export function getAppointments(successCallback, failureCallback) {
    apiCall.makeGetCall(
        "home-care-patient-data/appointments/" +
            LanaAppState.getInstance().getHospitalId() +
            "/" +
            LanaAppState.getInstance().getPatientId(),
        successCallback,
        (error, errorCode) => failureLoginCallback(failureCallback, error, errorCode)
    );
}

export function getMedications(successCallback, failureCallback) {
    apiCall.makeGetCall(
        "home-care-patient-data/medications/" +
            LanaAppState.getInstance().getHospitalId() +
            "/" +
            LanaAppState.getInstance().getPatientId() +
            "/" +
            LanaAppState.getInstance().getEncounterId(),
        successCallback,
        (error, errorCode) => failureLoginCallback(failureCallback, error, errorCode)
    );
}

export function getLabs(successCallback, failureCallback) {
    apiCall.makeGetCall(
        "home-care-patient-data/labs/" +
            LanaAppState.getInstance().getHospitalId() +
            "/" +
            LanaAppState.getInstance().getPatientId() +
            "/" +
            LanaAppState.getInstance().getEncounterId(),
        successCallback,
        (error, errorCode) => failureLoginCallback(failureCallback, error, errorCode)
    );
}

export function getImagingResulst(successCallback, failureCallback) {
    apiCall.makeGetCall(
        "home-care-patient-data/imagingResults/" +
            LanaAppState.getInstance().getHospitalId() +
            "/" +
            LanaAppState.getInstance().getPatientId() +
            "/" +
            LanaAppState.getInstance().getEncounterId(),
        successCallback,
        (error, errorCode) => failureLoginCallback(failureCallback, error, errorCode)
    );
}

export function getRemindersForParticularMedication(
    medicationId,
    successCallback,
    failureCallback
) {
    apiCall.makeGetCall(
        "home-care/reminders/reminders-for-medication/" + medicationId,
        successCallback,
        (error, errorCode) => failureLoginCallback(failureCallback, error, errorCode)
    );
}

export function getRemindersForPatient(
    successCallback,
    failureCallback
) {
    apiCall.makeGetCall(
        "home-care/reminders/patient-active-reminders",
        successCallback,
        (error, errorCode) => failureLoginCallback(failureCallback, error, errorCode)
    );
}


export function getMediaAssetUrl(mediaAssetId, successCallback, failureCallback) {
    apiCall.makePostCall(
        "patient-education/get-media-asset",
        {
            uniqueId: mediaAssetId
        },
        successCallback,
        (error, errorCode) => failureLoginCallback(failureCallback, error, errorCode)
    );
}

export function submitSurveyResponse(surveyResponse, successCallback, failureCallback) {
    apiCall.makePostCall(
        "survey-response/update-survey-answers",
        surveyResponse,
        successCallback,
        (error, errorCode) => failureLoginCallback(failureCallback, error, errorCode)
    );
}

export function authCheckHomeCare(loginData, successCallback, failureCallback) {
    apiCall.makePostCall(
        "auth/auth-check/home-care",
        {
            hospitalId: loginData.hospitalId,
            patientId: loginData.patientId
        },
        successCallback,
        (error, errorCode) => failureLoginCallback(failureCallback, error, errorCode)
    );
}

export function createMedicationReminder(
    reminderId,
    medicationId,
    schedule,
    successCallback,
    failureCallback
) {
    const params =
        reminderId !== 0
            ? {
                  reminderId: reminderId,
                  reminderStatus: "ACTIVE",
                  reminderType: "MEDICATION",
                  schedule: schedule,
                  medicationId: medicationId,
                  timezone: Localization.timezone,
              }
            : {
                  reminderStatus: "ACTIVE",
                  reminderType: "MEDICATION",
                  schedule: schedule,
                  medicationId: medicationId,
                  timezone: Localization.timezone,
              };

    apiCall.makePostCall(
        "home-care/reminders/add",
        params,
        successCallback,
        (error, errorCode) => failureLoginCallback(failureCallback, error, errorCode)
    );
}
