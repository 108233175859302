import { StyleSheet, Text } from "react-native";
import { getColorConfig } from "../constants/colors";

export default class TextFields {
    static MainHeadingText(props) {
        return (
            <Text style={[styles.mainHeadingText, props.style]}>
                {props.children}
            </Text>
        );
    }

    static TitleText1(props) {
        return (
            <Text style={[styles.titleText1, props.style]}>
                {props.children}
            </Text>
        );
    }

    static TitleText1Lines2(props) {
        return (
            <Text
                numberOfLines={2}
                ellipsizeMode="tail"
                style={[styles.titleText1, props.style]}
            >
                {props.children}
            </Text>
        );
    }

    static TitleText2(props) {
        return (
            <Text style={[styles.titleText2, props.style]}>
                {props.children}
            </Text>
        );
    }

    static TitleText3(props) {
        return (
            <Text style={[styles.titleText3, props.style]}>
                {props.children}
            </Text>
        );
    }

    static TitleText3_Bold(props) {
        return (
            <Text style={[styles.titleText3_Bold, props.style]}>
                {props.children}
            </Text>
        );
    }

    static TitleTextGray1(props) {
        return (
            <Text style={[styles.titleTextGray1, props.style]}>
                {props.children.toUpperCase()}
            </Text>
        );
    }

    static SubTitleTextGray(props) {
        return (
            <Text style={[styles.subTitleTextGray, props.style]}>
                {props.children}
            </Text>
        );
    }

    static SubTitleTextBlack(props) {
        return (
            <Text
                numberOfLines={props.numberOfLines}
                style={[styles.subTitleTextBlack, props.style]}
            >
                {props.children}
            </Text>
        );
    }

    static WhiteButtonText(props) {
        return (
            <Text style={[styles.WhiteButtonText, props.style]}>
                {props.children}
            </Text>
        );
    }

    static DarkCardSubText(props) {
        return (
            <Text style={[styles.darkCardSubText, props.style]}>
                {props.children}
            </Text>
        );
    }

    static BadgeText(props) {
        return (
            <Text style={[styles.badgeText, props.style]}>
                {props.children}
            </Text>
        );
    }

    static InitialsLargeText(props) {
        return (
            <Text style={[styles.initialsLargeText, props.style]}>
                {props.children.toUpperCase()}
            </Text>
        );
    }

    static ButtonTextPurple(props) {
        return (
            <Text style={[styles.buttonTextPurpleText, props.style]}>
                {props.children}
            </Text>
        );
    }

    static PurpleTitleUppercaseText(props) {
        return (
            <Text style={[styles.purpleTitleUppercaseText, props.style]}>
                {props.children}
            </Text>
        );
    }
}

const styles = StyleSheet.create({
    initialsLargeText: {
        fontFamily: "MetropolisSemiBold",
        fontSize: 42,
        color: getColorConfig().primaryColor,
    },
    mainHeadingText: {
        fontFamily: "MetropolisSemiBold",
        fontSize: 24,
    },
    titleText3_Bold: {
        fontFamily: "MetropolisSemiBold",
        fontSize: 20,
        lineHeight: 30,
        color: getColorConfig().black,
    },
    titleText3: {
        fontFamily: "MetropolisMedium",
        fontSize: 20,
        lineHeight: 30,
        color: getColorConfig().black,
    },
    titleText1: {
        fontFamily: "MetropolisSemiBold",
        fontSize: 18,
        lineHeight: 28,
    },
    titleText2: {
        fontFamily: "MetropolisSemiBold",
        fontSize: 18,
    },
    subTitleTextBlack: {
        fontFamily: "MetropolisMedium",
        fontSize: 16,
        lineHeight: 24,
        color: getColorConfig().black,
    },
    subTitleTextGray: {
        fontFamily: "MetropolisMedium",
        fontSize: 16,
        lineHeight: 24,
        color: getColorConfig().mainGray,
    },
    titleTextGray1: {
        fontFamily: "MetropolisSemiBold",
        fontSize: 16,
        color: getColorConfig().mainGray,
    },
    buttonTextPurpleText: {
        fontFamily: "MetropolisSemiBold",
        fontSize: 16,
        lineHeight: 24,
        color: getColorConfig().primaryColor,
    },
    WhiteButtonText: {
        fontFamily: "MetropolisSemiBold",
        fontSize: 16,
        lineHeight: 24,
        color: "white",
    },
    darkCardSubText: {
        fontFamily: "MetropolisSemiBold",
        fontSize: 14,
        lineHeight: 20,
        color: "#C8C8C8",
    },
    purpleTitleUppercaseText: {
        fontFamily: "MetropolisSemiBold",
        fontSize: 15,
        color: getColorConfig().primaryColor,
        textTransform: "uppercase",
    },
    badgeText: {
        fontFamily: "MetropolisSemiBold",
        fontSize: 12,
    },
});
