import React, { useState } from "react";
import { Image, Platform, ScrollView, StyleSheet, View } from "react-native";
import { getColorConfig } from "../../../../constants/colors";
import { Dropdown } from "../../../../components/dropdown";
import { MedicationReminderSelectMedicineSheet } from "./MedicationReminderSelectMedicineSheet";
import {
    AddTimeButton,
    Card,
    DateSetter,
    ReminderTimeList,
} from "./MedicationReminderDateTimePickers";
import * as APIHandler from "../../../../apiHandlers/APIHandler";
import TextFields from "../../../../components/text-fields";
import HorizontalLine from "../../../../components/horizontal-line";
import TouchableWithAnimation from "../../../../components/touchable-with-animation";

const data = [
    { label: "Every day", daysToSkip: 0, value: "0" },
    { label: "Every other day", daysToSkip: 1, value: "1" },
    { label: "Every 3 days", daysToSkip: 2, value: "2" },
    { label: "Every 4 days", daysToSkip: 3, value: "3" },
    { label: "Every 5 days", daysToSkip: 4, value: "4" },
    { label: "Every 6 days", daysToSkip: 5, value: "5" },
    { label: "Every 7 days", daysToSkip: 6, value: "6" },
    { label: "Every 10 days", daysToSkip: 9, value: "7" },
    { label: "Every 14 days", daysToSkip: 13, value: "8" },
    { label: "Every 30 days", daysToSkip: 29, value: "9" },
];

export const MedicationReminderSelectFrequencySheet = (props) => {
    const openedFromMedicationDetailsScreen =
        props.openedFromMedicationDetailsScreen;
    const reminderData = props.reminderData;
    const medicationData = props.medicationData;

    const [id, setId] = useState(initialId);
    const [frequency, setFrequency] = useState(initialFrequency);
    const [reminderTimeArray, setReminderTimeArray] = useState(
        initialReminderTimeArray
    );
    const [reminderStartDate, setReminderStartDate] =
        useState(initialStartDate);
    const [reminderEndDate, setReminderEndDate] = useState(initialEndDate);
    const [prevMedicationData, setPrevMedicationData] = useState(null);
    const [prevReminderData, setPrevReminderData] = useState(null);

    function initialId() {
        return reminderData
            ? 1000 +
                  (reminderData.schedule.recurrenceType === "DAILY"
                      ? reminderData.schedule.dailyRecurrencesList.length
                      : reminderData.schedule.everyXDaysRecurrences.length)
            : 1000;
    }
    function initialFrequency() {
        return reminderData
            ? reminderData.schedule.recurrenceType === "DAILY"
                ? data[0]
                : data[
                      data.findIndex(
                          (object) =>
                              object.daysToSkip ===
                              reminderData.schedule.everyXDaysRecurrences[0]
                                  .daysToSkip
                      )
                  ]
            : null;
    }
    function initialReminderTimeArray() {
        var reminderTimeArray = [];
        var tempDate = new Date();
        function getReminderTimeArray(list) {
            list.map((obj, index) => {
                tempDate.setHours(obj.hourOfTheDay);
                tempDate.setMinutes(obj.minuteOfTheHour);
                reminderTimeArray.push({ id: 1000 + index, time: tempDate });
            });
            return reminderTimeArray;
        }

        return reminderData
            ? reminderData.schedule.recurrenceType === "DAILY"
                ? getReminderTimeArray(
                      reminderData.schedule.dailyRecurrencesList
                  )
                : getReminderTimeArray(
                      reminderData.schedule.everyXDaysRecurrences
                  )
            : [];
    }
    function initialStartDate() {
        return reminderData
            ? new Date(reminderData.schedule.startDate)
            : new Date();
    }
    function initialEndDate() {
        return reminderData
            ? new Date(reminderData.schedule.endDate)
            : new Date(Date.now() + 3600 * 1000 * 24);
    }
    function resetValues() {
        setId(initialId);
        setFrequency(initialFrequency);
        setReminderTimeArray(initialReminderTimeArray);
        setReminderStartDate(initialStartDate);
        setReminderEndDate(initialEndDate);
        setPrevMedicationData(medicationData);
        setPrevReminderData(reminderData);
    }
    function buttonDisabled() {
        return (
            frequency === null ||
            reminderTimeArray.length < 1 ||
            reminderEndDate <= reminderStartDate
        );
    }

    if (
        medicationData !== prevMedicationData ||
        reminderData !== prevReminderData
    ) {
        // Medication Data has changed since last render. Update prevMedicationData and prevReminderData.
        resetValues();
    }

    const updateDropdownValue = (dropdownValue) => {
        setFrequency(dropdownValue.item);
    };
    const handleAddTime = () => {
        const newTime = [
            ...reminderTimeArray,
            {
                id: id,
                time: new Date(Date.now() + 3600 * 1000 * ((id % 1000) - 1)),
            },
        ];
        setId(id + 1);
        setReminderTimeArray(newTime);
    };
    const onPressDoneButton = () => {
        function getDailyRecurrencesList() {
            var dailyRecurrencesList = [];
            reminderTimeArray
                .map((date) => [
                    new Date(date.time).getHours(),
                    new Date(date.time).getMinutes(),
                ])
                .map((item) => {
                    frequency.value > 0
                        ? dailyRecurrencesList.push({
                              daysToSkip: frequency.daysToSkip,
                              hourOfTheDay: item[0],
                              minuteOfTheHour: item[1],
                          })
                        : dailyRecurrencesList.push({
                              hourOfTheDay: item[0],
                              minuteOfTheHour: item[1],
                          });
                });

            return dailyRecurrencesList
                .map(JSON.stringify)
                .filter((e, i, a) => i === a.indexOf(e))
                .map(JSON.parse);
        }

        const updatedReminderData =
            frequency.value > 0
                ? {
                      startDate: reminderStartDate.getTime(),
                      endDate: reminderEndDate.getTime(),
                      type: "ABSOLUTE",
                      recurrenceType: "EVERY_X_DAYS",
                      everyXDaysRecurrences: getDailyRecurrencesList(),
                  }
                : {
                      startDate: reminderStartDate.getTime(),
                      endDate: reminderEndDate.getTime(),
                      type: "ABSOLUTE",
                      recurrenceType: "DAILY",
                      dailyRecurrencesList: getDailyRecurrencesList(),
                  };
        APIHandler.createMedicationReminder(
            reminderData ? reminderData.reminderId : 0,
            medicationData.medicationId,
            updatedReminderData,
            () => {
                console.log("Success creating reminder");
            },
            () => {
                console.log("Error creating reminder");
            }
        );
        resetValues();
        setTimeout(function () {
            APIHandler.getRemindersForParticularMedication(
                medicationData.medicationId,
                (data) => {
                    openedFromMedicationDetailsScreen
                        ? props.setReminderData(data.data)
                        : {};
                },
                () => {
                    console.log(
                        "Error fetching reminders for ",
                        medicationData.medicationDisplayName
                    );
                }
            );
        }, 3000);

        props.hideBottomSheet();
    };
    const backButtonPressed = () => {
        props.bottomSheetChildren(
            <MedicationReminderSelectMedicineSheet
                bottomSheetChildren={props.bottomSheetChildren}
                showBottomSheet={props.showBottomSheet}
                hideBottomSheet={props.hideBottomSheet}
            />
        );
    };

    return (
        <ScrollView showsVerticalScrollIndicator={false}>
            <TextFields.TitleText2>Medication Reminder</TextFields.TitleText2>
            <HorizontalLine style={{ marginTop: 8, height: 33 }} />
            <Card medicationData={medicationData} />
            <TextFields.TitleText3_Bold style={{ marginTop: 8 }}>
                Medication frequency
            </TextFields.TitleText3_Bold>
            <TextFields.SubTitleTextBlack
                style={{ marginTop: 8, marginBottom: 8 }}
            >
                Frequency:
            </TextFields.SubTitleTextBlack>
            <Dropdown
                placeholder="Select frequency"
                data={data}
                dropdownValue={frequency}
                updateDropdownValue={updateDropdownValue}
            />
            <TextFields.SubTitleTextBlack style={{ marginTop: 20 }}>
                Time:
            </TextFields.SubTitleTextBlack>
            <ReminderTimeList
                reminderTimeArray={reminderTimeArray}
                setReminderTimeArray={setReminderTimeArray}
            />
            {reminderTimeArray.length < 5 && (
                <AddTimeButton
                    reminderTimeArray={reminderTimeArray}
                    handleAddTime={handleAddTime}
                />
            )}
            <TextFields.SubTitleTextBlack style={{ marginTop: 28 }}>
                Start on:
            </TextFields.SubTitleTextBlack>
            <DateSetter
                reminderDate={reminderStartDate}
                setSelectedDate={setReminderStartDate}
            />
            <TextFields.SubTitleTextBlack style={{ marginTop: -48 }}>
                Ends on:
            </TextFields.SubTitleTextBlack>
            <DateSetter
                reminderDate={reminderEndDate}
                setSelectedDate={setReminderEndDate}
            />
            <View style={{ height: Platform.OS === "ios" ? 0 : 16 }} />
            <View style={{ position: "absolute", bottom: 0, left: 0 }}>
                {!openedFromMedicationDetailsScreen && (
                    <TouchableWithAnimation onPress={() => backButtonPressed()}>
                        <View style={styles.backQuestionButton}>
                            <View
                                style={{
                                    flexDirection: "row",
                                    alignItems: "center",
                                }}
                            >
                                <Image
                                    style={{
                                        width: 16,
                                        height: 16,
                                        marginRight: 4,
                                    }}
                                    source={require("../../../../../assets/arrows/leftPurpleIcon.png")}
                                />
                                <TextFields.ButtonTextPurple>
                                    Back
                                </TextFields.ButtonTextPurple>
                            </View>
                        </View>
                    </TouchableWithAnimation>
                )}
            </View>
            <View
                style={
                    openedFromMedicationDetailsScreen
                        ? {}
                        : { position: "absolute", bottom: 0, right: 0 }
                }
            >
                <TouchableWithAnimation
                    scaleFactor={0.95}
                    onPress={onPressDoneButton}
                    disable={buttonDisabled() ? true : false}
                >
                    <View
                        style={[
                            openedFromMedicationDetailsScreen
                                ? styles.doneButton
                                : styles.smallDoneButtonStyle,
                            {
                                backgroundColor: buttonDisabled()
                                    ? getColorConfig().disabledButtonGray
                                    : getColorConfig().primaryColor,
                            },
                        ]}
                    >
                        {buttonDisabled() && (
                            <TextFields.WhiteButtonText
                                style={{ color: getColorConfig().mainGray }}
                            >
                                Done
                            </TextFields.WhiteButtonText>
                        )}
                        {!buttonDisabled() && (
                            <TextFields.WhiteButtonText>
                                Done
                            </TextFields.WhiteButtonText>
                        )}
                    </View>
                </TouchableWithAnimation>
            </View>
        </ScrollView>
    );
};

const styles = StyleSheet.create({
    backQuestionButton: {
        width: 96,
        height: 48,
        borderRadius: 12,
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: getColorConfig().secondaryColor,
    },
    smallDoneButtonStyle: {
        borderRadius: 12,
        paddingVertical: 12,
        paddingHorizontal: 27,
        backgroundColor: getColorConfig().primaryColor,
    },
    doneButton: {
        padding: 16,
        marginTop: -24,
        borderRadius: 12,
        marginBottom: 16,
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: getColorConfig().primaryColor,
    },
});
