import React from "react";
import { StyleSheet, View } from "react-native";
import { getColorConfig } from "../../constants/colors";
import { boxWithDarkShadow } from "../../constants/common-styles";
import ListButton from "../../components/list-button";
import HorizontalLine from "../../components/horizontal-line";
import dateFormat from "dateformat";
import { DATE_FORMAT } from "../../constants/date-format";

export const healthDataOptions = [
    {
        image: require("../../../assets/healthData/medicationsIcon.png"),
        title: "Medications",
        navigationScreen: "MedicationScreen",
    },
    {
        image: require("../../../assets/healthData/appointmentsIcon.png"),
        title: "Appointments",
        navigationScreen: "AppointmentScreen",
    },
    {
        image: require("../../../assets/healthData/imagingResultIcon.png"),
        title: "Imaging Results",
        navigationScreen: "ImagingResultsScreen",
    },
    {
        image: require("../../../assets/healthData/labResultIcon.png"),
        title: "Lab Results",
        navigationScreen: "LabResultsScreen",
    },
];

export default class HealthDataSelector extends React.Component {

    navigateToMedicationLogRemindersScreen = () => {
        this.props.navigation.navigate("MedicationLogRemindersList", {
            reminder: rem[0],
        });
};

    render() {
        return (
            <View style={[styles.listStyle, boxWithDarkShadow]}>
                <ListButton
                    icon={healthDataOptions[0].image}
                    title={healthDataOptions[0].title}
                    arrowIcon={true}
                    onClick={() =>
                        this.props.navigation.navigate(
                            healthDataOptions[0].navigationScreen
                        )
                    }
                />

                <HorizontalLine />

                <ListButton
                    icon={healthDataOptions[1].image}
                    title={healthDataOptions[1].title}
                    arrowIcon={true}
                    onClick={() =>
                        this.props.navigation.navigate(
                            healthDataOptions[1].navigationScreen
                        )
                    }
                />

                <HorizontalLine />

                <ListButton
                    icon={healthDataOptions[2].image}
                    title={healthDataOptions[2].title}
                    showComingSoonBadge={false}
                    arrowIcon={true}
                    onClick={() =>
                        this.props.navigation.navigate(
                            healthDataOptions[2].navigationScreen
                        )
                    }
                />

                <HorizontalLine />

                <ListButton
                    icon={healthDataOptions[3].image}
                    title={healthDataOptions[3].title}
                    showComingSoonBadge={false}
                    arrowIcon={true}
                    onClick={() =>
                        this.props.navigation.navigate(
                            healthDataOptions[3].navigationScreen
                        )
                    }
                />
            </View>
        );
    }
}

const styles = StyleSheet.create({
    listStyle: {
        padding: 16,
        marginHorizontal: 16,
        marginVertical: 16,
        backgroundColor: getColorConfig().white,
        borderRadius: 12,
    },
});
