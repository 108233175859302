import React from "react";
import { Image, ScrollView, StyleSheet, View } from "react-native";
import { getColorConfig } from "../../../../constants/colors";
import { DATE_FORMAT } from "../../../../constants/date-format";
import {
    boxWithLightShadow,
    boxWithDarkShadow,
} from "../../../../constants/common-styles";
import dateFormat from "dateformat";
import TextFields from "../../../../components/text-fields";
import HorizontalLine from "../../../../components/horizontal-line";
import TouchableWithAnimation from "../../../../components/touchable-with-animation";
import { MEDICATION_OBJECT_DATA } from "../../../Home/LogYourMedicationSheet";
import { getObjectData } from "../../../../utils/storage-helper";

function getTodayAndPast5Dates() {
    return Array.from(Array(6).keys()).map((index) => {
        const date = new Date();
        date.setDate(date.getDate() - index);
        return date;
    });
}

function getNext5Dates() {
    return Array.from(Array(5).keys()).map((index) => {
        const date = new Date();
        date.setDate(date.getDate() + (index + 1));
        return date;
    });
}

function getDateRange() {
    let next7Dates = getNext5Dates();
    let past7Dates = getTodayAndPast5Dates();
    let datesArray = [...past7Dates, ...next7Dates];
    datesArray.sort((date1, date2) => date1 - date2);
    return datesArray;
}

const weekday = ["S", "M", "T", "W", "T", "F", "S"];

export default class MedicationLogScreen extends React.Component {
    constructor(props) {
        super(props);
        this.dateRange = getDateRange();
        this.state = {
            selectedDate: new Date(),
            storedMedications: []
        };

        getObjectData(MEDICATION_OBJECT_DATA)
            .then((storedMedications) => {
                if(storedMedications) {
                    this.setState({storedMedications})
                }
        });
    }

    scrollToInitialPosition = () => {
        this.myScroll.scrollTo({
            x: 116,
            animated: true,
        });
    };

    navigateToMedicationLogRemindersScreen = (reminder) => {
        this.props.navigation.navigate("MedicationLogRemindersList", {});
    };

    render() {
        return (
            <>
                <View
                    style={[
                        boxWithLightShadow,
                        {
                            padding: 16,
                            backgroundColor: getColorConfig().white,
                            marginBottom: 20,
                        },
                    ]}>
                        <TextFields.TitleText3 style={{ textAlign: "center" }}>
                            {dateFormat(
                                this.state.selectedDate,
                                DATE_FORMAT.short_day_date
                            )}
                        </TextFields.TitleText3>
                        <ScrollView
                            horizontal={true}
                            showsHorizontalScrollIndicator={false}
                            style={{ paddingTop: 8 }}
                            ref={(ref) => (this.myScroll = ref)}
                            onLayout={this.scrollToInitialPosition}
                            contentContainerStyle={{
                                flexGrow: 1,
                                justifyContent: "center",
                            }}>
                                {this.DayList()}
                        </ScrollView>
                </View>
                <ScrollView showsVerticalScrollIndicator={false}>
                    {this.ReminderList()}
                </ScrollView>
            </>
        );
    }

    DayList() {
        return this.dateRange.map((date) => {
            const dateExistsInReminder = this.state.storedMedications.some(
                (item) =>
                    dateFormat(
                        item.date,
                        DATE_FORMAT.descriptive_date
                    ) === dateFormat(date, DATE_FORMAT.descriptive_date)
            );
            const selectedDotCondition =
                dateFormat(date, DATE_FORMAT.descriptive_date) ==
                dateFormat(
                    this.state.selectedDate,
                    DATE_FORMAT.descriptive_date
                );

            return (
                <TouchableWithAnimation
                    key={"timeComponent_" + date}
                    scaleFactor={0.95}
                    onPress={() => {
                        this.setState({
                            selectedDate: date,
                        });
                    }}
                    disable={dateExistsInReminder ? false : true}
                >
                    <View
                        style={[
                            styles.dayBg,
                            {
                                backgroundColor: dateExistsInReminder
                                    ? getColorConfig().secondaryColor
                                    : getColorConfig().disabledButtonGray,
                            },
                        ]}
                    >
                        {selectedDotCondition ? (
                            <View style={styles.selectedDateDot} />
                        ) : (
                            <View style={{ marginTop: 12, marginBottom: 6 }} />
                        )}
                        <TextFields.TitleText1
                            style={{
                                color: dateExistsInReminder
                                    ? getColorConfig().primaryColor
                                    : getColorConfig().mainGray,
                            }}
                        >
                            {weekday[date.getDay()]}
                        </TextFields.TitleText1>
                    </View>
                </TouchableWithAnimation>
            );
        });
    }

    ReminderList() {

        const medicationLogExistsForDay = this.state.storedMedications.some(
            (reminder) =>
                dateFormat(
                    reminder.date,
                    DATE_FORMAT.descriptive_date
                ) ==
                dateFormat(
                    this.state.selectedDate,
                    DATE_FORMAT.descriptive_date
                )
        );

        if(medicationLogExistsForDay) {
            return this.state.storedMedications.map((reminder) => {
                if (
                    dateFormat(
                        reminder.date,
                        DATE_FORMAT.descriptive_date
                    ) ==
                    dateFormat(
                        this.state.selectedDate,
                        DATE_FORMAT.descriptive_date
                    )
                ) {
                    return (
                        <View
                            key={"reminder_" + reminder.medicationId}
                            style={boxWithDarkShadow}
                        >
                            <TouchableWithAnimation
                                scaleFactor={0.95}
                                onPress={() =>
                                    {}
                                }
                            >
                                <ReminderTimeDetail reminder={reminder} />
                            </TouchableWithAnimation>
                        </View>
                    );
                }
            });
        } else {
            return (
                <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
                    <TextFields.SubTitleTextGray>
                        No medications logged for this day
                    </TextFields.SubTitleTextGray>
                </View>
            )
        }
    }


}

export const ReminderTimeDetail = (props) => {
    let reminder = props.reminder;
    return (
        <View style={styles.reminderCard}>
            <View style={{ flexDirection: "row" }}>
                <Image
                    style={{ width: 48, height: 48, borderRadius: 24 }}
                    source={require("../../../../../assets/healthData/medicine.png")}
                />
                <View
                    style={{
                        flexDirection: "column",
                        justifyContent: "center",
                        marginLeft: 16,
                    }}
                >
                    <TextFields.TitleText1Lines2>
                        {dateFormat(
                            reminder.loggedTime,
                            DATE_FORMAT.time_with_marker
                        )}
                    </TextFields.TitleText1Lines2>

                    <TextFields.SubTitleTextGray>
                        1 medication
                    </TextFields.SubTitleTextGray>
                </View>
                {/* <Image
                    style={styles.arrowIcon}
                    source={require("../../../../../assets/arrows/rightIconLightGray.png")}
                /> */}
            </View>

            <HorizontalLine style={{ height: 33 }} />
                <TextFields.SubTitleTextBlack
                    key={"medication_" + reminder.medication.medicationDisplayName}
                    style={{ marginBottom: 16 }}
                >
                    {reminder.medication.medicationDisplayName}
                </TextFields.SubTitleTextBlack>
        </View>
    );
};

const styles = StyleSheet.create({
    dayBg: {
        paddingHorizontal: 12,
        paddingBottom: 12,
        marginBottom: 16,
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 24,
        marginHorizontal: 10,
    },
    selectedDateDot: {
        width: 6,
        height: 6,
        borderRadius: 100 / 2,
        backgroundColor: getColorConfig().primaryColor,
        marginVertical: 6,
    },
    reminderCard: {
        paddingHorizontal: 16,
        paddingTop: 16,
        marginHorizontal: 16,
        marginBottom: 16,
        borderRadius: 12,
        backgroundColor: getColorConfig().white,
    },
    arrowIcon: {
        marginVertical: 16,
        width: 24,
        height: 24,
        right: 0,
        position: "absolute",
    },
});
