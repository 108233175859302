import React from "react";
import { capitalize } from "../../../components/stringManipulations";
import ResourceCard from "../../../components/resource-card";
import TouchableWithAnimation from "../../../components/touchable-with-animation";

export default class MedicationCard extends React.Component {
    constructor(props) {
        super(props);
        this.medicationData = props.medicationData;
    }

    navigateToMedicationDetailScreen = () => {
        this.props.navigation.navigate("MedicationDetailScreen", {
            medicationData: this.medicationData,
        });
    };

    render() {
        return (
            <TouchableWithAnimation
                scaleFactor={0.95}
                onPress={() => this.navigateToMedicationDetailScreen()}
            >
                <ResourceCard
                    icon={require("../../../../assets/healthData/medicine.png")}
                    title={this.medicationData.medicationDisplayName}
                    subTitle={capitalize(this.medicationData.status)}
                    arrowIcon={true}
                />
            </TouchableWithAnimation>
        );
    }
}
