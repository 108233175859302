import { BlurView } from "expo-blur";
import React from "react";
import {
    Dimensions,
    Image,
    ImageBackground,
    StyleSheet,
    Text,
    View,
    ActivityIndicator,
    Platform
} from "react-native";
import CommonComponents from "../../components/common-components";
import TextFields from "../../components/text-fields";
import TouchableWithAnimation from "../../components/touchable-with-animation";
import { getColorConfig } from "../../constants/colors";
import { EDUCATION_STATUS } from "../../constants/education-status";
import * as APIHandler from "../../apiHandlers/APIHandler";
import WebView from "react-native-webview";
import { Video } from 'expo-av';

export default class EducationDetailScreen extends React.Component {
    constructor(props) {
        super(props);
        this.educationData = props.route.params.educationData;
        this.state = {
            educationData: this.educationData,
            educationWebviewSpinner: true,
            educationVideoOpened: false,
            educationAssetVideoUrl: null,
            educationAssetBodyHtml: null,
            selectedLanguage: 'ENGLISH'
        }
        console.log("educationData", this.educationData);
    }

    componentDidMount() {
        this.updateSelectedLanguage('ENGLISH');
    }

    updateSelectedLanguage = (updatedLanguage) => {
        this.setState({
            selectedLanguage: updatedLanguage
        });
        this.state.educationData.educationAsset.languagesAvailable.map((language) => {
            if(language.language === updatedLanguage) {
                const mediaAssetId = language.mediaAssetIds[0];
                APIHandler.getMediaAssetUrl(mediaAssetId,
                    (data) => {
                        this.setState({
                            educationAssetVideoUrl: data.url,
                            educationAssetBodyHtml: data.bodyHtml,
                            educationWebviewSpinner: (Platform.OS !== 'web')
                        })
                    },
                    () => {
                        console.log("Error calling media asset url");
                    }
                )
            }
        })
    }

    render() {
        if(this.state.educationAssetVideoUrl && this.state.educationVideoOpened) {
            const height = Dimensions.get('window').height;
            const width = Math.min(550, Dimensions.get('window').width);
            return (
                <View style={{flex: 1}}>
                    {Platform.OS === 'web' && (!this.state.educationAssetVideoUrl.endsWith(".mp4")) &&
                        <iframe onLoad={() => this.setState({educationWebviewSpinner: false})} src={this.state.educationAssetVideoUrl} height={height} width={width} />
                    }
                    {Platform.OS === 'web' && (this.state.educationAssetVideoUrl.endsWith(".mp4")) &&
                        <View style={{flex: 1, justifyContent:'center', overflow: 'visible', backgroundColor: getColorConfig().black}}>
                            <Video
                                videoStyle={{position: 'relative'}}
                                source={{
                                    uri: this.state.educationAssetVideoUrl,
                                }}
                                shouldPlay
                                useNativeControls
                                resizeMode="contain"
                                isLooping />
                        </View>
                    }
                    {Platform.OS !== 'web' &&
                        <WebView
                            onLoad={() => this.setState({educationWebviewSpinner: false})}
                            source={{
                                uri: this.state.educationAssetVideoUrl,
                            }}
                            style={{
                                height: height,
                                width: width
                            }}
                        />
                    }
                    {this.state.educationWebviewSpinner &&
                        <ActivityIndicator
                            style={{position: "absolute", top: (height/2) - 100, left: (width/2) - 24}}
                            size="large"
                        />
                    }
                </View>
            )
        } else if(this.state.educationAssetVideoUrl) {
            return (
                <View style={{ backgroundColor: getColorConfig().white, margin: 16, borderRadius: 16 }}>
                    <TouchableWithAnimation onPress={() => this.setState({educationVideoOpened: true})}>
                        <View style={{ height: 216 }}>
                            {(this.educationData && this.educationData.educationAsset &&
                                this.educationData.educationAsset.thumbnailWide) ?
                                <Image
                                    style={styles.playButtonCenterImageStyle}
                                    source={require("../../../assets/common/play-button-white-bg.png")}
                                /> :
                                <Image
                                    style={styles.playButtonCenterImageStyle}
                                    source={require("../../../assets/common/play-button-blue-bg.png")}
                                />                            
                            }

                            <View style={{flex: 1, borderTopLeftRadius: 16,  borderTopRightRadius: 16, overflow: 'hidden' }}>
                                <ImageBackground
                                    source={{
                                        uri: this.educationData.educationAsset
                                            .thumbnailWide,
                                    }}
                                    resizeMode="cover"
                                    style={styles.backgroundImageView}
                                >
                                    <View
                                        style={{
                                            backgroundColor: "rgba(0, 0, 0, 0.45)",
                                        }}
                                    >
                                        <BlurView
                                            intensity={24}
                                            style={{ flexDirection: "column" }}
                                        >
                                            <View
                                                style={{
                                                    padding: 16,
                                                    flexDirection: "row",
                                                }}
                                            >
                                                <View
                                                    style={{ justifyContent: "center" }}
                                                >
                                                    <TextFields.DarkCardSubText>
                                                        {new Date(
                                                            this.educationData
                                                                .educationAsset
                                                                .languagesAvailable[0]
                                                                .durationInSeconds *
                                                                1000
                                                        )
                                                            .toISOString()
                                                            .slice(14, 19)}
                                                    </TextFields.DarkCardSubText>
                                                </View>
                                                <CommonComponents.ProgressBar
                                                    progressRate={
                                                        0 // update from backend
                                                    }
                                                    backgroundColor={
                                                        getColorConfig().whiteOpacityBackground
                                                    }
                                                    style={{
                                                        paddingHorizontal: 16,
                                                        flex: 1,
                                                    }}
                                                    highlightColor={getColorConfig().white}
                                                />
                                            </View>
                                        </BlurView>
                                    </View>
                                </ImageBackground>
                            </View>
                        </View>
                    </TouchableWithAnimation>

                    {this.educationData.status === EDUCATION_STATUS.COMPLETED && (
                        <View
                            style={{
                                paddingTop: 24,
                                flexDirection: "row",
                                alignItems: "center",
                            }}
                        >
                            <CommonComponents.CompletedBadge />
                        </View>
                    )}
                    <View style={{ padding: 16 }}>
                        <View style={{marginBottom: 16, flexDirection: 'row', alignItems: 'center', gap: 8}}>
                            <View 
                                style={this.state.selectedLanguage === 'ENGLISH' ? styles.countSelectedLabel : styles.countLabel}
                                onClick={() => this.updateSelectedLanguage("ENGLISH")}>

                                {this.state.selectedLanguage === 'ENGLISH' ? 
                                    <Image
                                        style={{ width: 20, height: 20 }}
                                        source={require("../../../assets/common/translate-selected-icon.png")}
                                    /> : 
                                    <Image
                                        style={{ width: 20, height: 20 }}
                                        source={require("../../../assets/common/translate-unselected-icon.png")}
                                    />
                                }
                                <Text style={this.state.selectedLanguage === 'ENGLISH' ? styles.countSelectedLabelText : styles.countLabelText}>
                                    EN
                                </Text>
                            </View>
                            {(this.state.educationData.educationAsset.languagesAvailable.filter(language => language.language === 'SPANISH').length > 0) && 
                                <View 
                                    style={this.state.selectedLanguage === 'SPANISH' ? styles.countSelectedLabel : styles.countLabel} 
                                    onClick={() => this.updateSelectedLanguage("SPANISH")}>


                                    {this.state.selectedLanguage === 'SPANISH' ? 
                                        <Image
                                            style={{ width: 20, height: 20 }}
                                            source={require("../../../assets/common/translate-selected-icon.png")}
                                        /> : 
                                        <Image
                                            style={{ width: 20, height: 20 }}
                                            source={require("../../../assets/common/translate-unselected-icon.png")}
                                        />
                                    }

                                    <Text style={this.state.selectedLanguage === 'SPANISH' ? styles.countSelectedLabelText : styles.countLabelText}>
                                        ES
                                    </Text>
                                </View>
                        }
                        </View>

                        <TextFields.TitleText3>
                            {
                                this.state.educationData.educationAsset.languagesAvailable.filter(language => language.language === this.state.selectedLanguage)[0].displayName
                            }
                        </TextFields.TitleText3>
                        <TextFields.SubTitleTextGray style={{ paddingTop: 16 }}>
                            {
                                this.state.educationData.educationAsset.languagesAvailable.filter(language => language.language === this.state.selectedLanguage)[0].description
                            }
                        </TextFields.SubTitleTextGray>
                    </View>
                </View>
            );
        } else if(this.state.educationAssetBodyHtml) {
            return Platform.OS === "web" ? (
                <iframe
                    srcDoc={this.state.educationAssetBodyHtml}
                    style={{height: '100%'}}
                />
              ) : (
                <WebView
                    originWhitelist={['*']}
                    source={{ html: this.state.educationAssetBodyHtml }}
                    style={{height: '100%'}}
                />
            )
        }
    }
}

const styles = StyleSheet.create({
    backgroundImageView: {
        flex: 1,
        justifyContent: "flex-end",
        backgroundColor: getColorConfig().blue50
    },
    playButtonCenterImageStyle: {
        position: "absolute",
        width: 56,
        height: 56,
        marginTop: 80,
        marginLeft: (Math.min(550, Dimensions.get("window").width) - 56 - 32) / 2,
        zIndex: 1,
    },
    countLabelText: {
        fontFamily: 'MetropolisBold',
        fontSize: 14,
        color: getColorConfig().mainGray,
    },
    countSelectedLabelText: {
        fontFamily: 'MetropolisBold',
        fontSize: 14,
        color: getColorConfig().primaryColor,
    },
    countLabel: {
        backgroundColor: getColorConfig().lightGray,
        padding: 8,
        borderRadius: 32,
        flexDirection: 'row',
        cursor: 'pointer',
        alignItems: 'center'
    },
    countSelectedLabel: {
        backgroundColor: getColorConfig().secondaryColor,
        padding: 8,
        borderRadius: 32,
        flexDirection: 'row',
        cursor: 'pointer',
        alignItems: 'center'
    }
});
