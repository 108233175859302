import React, { useState, useEffect, useCallback } from "react";
import { Image, Platform, StyleSheet, View } from "react-native";
import {
    Bubble,
    GiftedChat,
    InputToolbar,
    Send,
} from "react-native-gifted-chat";
import { boxWithDarkShadow } from "../../constants/common-styles";
import { getColorConfig } from "../../constants/colors";
import TextFields from "../../components/text-fields";
import { getObjectData, storeObjectData } from "../../utils/storage-helper";
import LanaAppState from "../../utils/lana-app-state";

export const MESSAGES_STORE_KEY = "messages";

export const ChatScreen = () => {
    const [messages, setMessages] = useState([]);

    useEffect(() => {
        getObjectData(MESSAGES_STORE_KEY)
            .then((storedMessages) => {
                if((!storedMessages) || (storedMessages.length == 0)) {
                    setMessages([
                        {
                            _id: "4c84b77c-8cca-11ed-b52a-9b6a7fa5266d",
                            text: "Hello " + LanaAppState.getInstance().getPatientFirstName() + ".\nI am Lana, your virtual assistant.\nYou can send a message your care team here. Use this chat for non-urgent questions.\nIn case of any emergency, call the hospital helpline at +1 (313) 111-2222",
                            createdAt: new Date(),
                            user: {
                                _id: 2,
                                name: "Marvin Burr",
                            },
                        },
                    ]);
                } else {
                    setMessages(storedMessages);
                }
            })
    }, []);

    useEffect(() => {
        storeObjectData(MESSAGES_STORE_KEY, messages);
    }, [messages])

    const renderInputToolbar = (props) => {
        return (
            <InputToolbar
                {...props}
                containerStyle={[
                    {
                        borderRadius: 12,
                        borderTopWidth: 0,
                        paddingVertical: 6,
                        marginHorizontal: 12,
                        justifyContent: "center",
                    },
                    boxWithDarkShadow,
                ]}
            />
        );
    };

    const onSend = useCallback((messages = []) => {
        setMessages((previousMessages) =>
            GiftedChat.append(previousMessages, messages)
        );
    }, []);

    const renderSend = (props) => {
        return (
            <Send {...props}>
                <Image
                    style={{
                        width: 40,
                        height: 40,
                    }}
                    source={require("../../../assets/common/sendMessage.png")}
                />
            </Send>
        );
    };

    const renderBubble = (props) => {
        return (
            <Bubble
                {...props}
                wrapperStyle={{
                    right: {
                        backgroundColor: getColorConfig().secondaryColor,
                    },
                    left: {
                        backgroundColor: getColorConfig().lightGray,
                    },
                }}
                textStyle={{
                    right: {
                        fontFamily: "MetropolisMedium",
                        fontSize: 16,
                        lineHeight: 24,
                        color: getColorConfig().primaryColor,
                    },
                    left: {
                        fontFamily: "MetropolisMedium",
                        fontSize: 16,
                        lineHeight: 24,
                        color: getColorConfig().darkGray,
                    },
                }}
                timeTextStyle={{
                    right: {
                        fontFamily: "MetropolisMedium",
                        color: getColorConfig().primaryColor,
                    },
                    left: {
                        fontFamily: "MetropolisMedium",
                        color: getColorConfig().darkGray,
                    },
                }}
            />
        );
    };

    const CareTeamDetail = () => {
        return (
            <View style={[styles.staffDetailBox, boxWithDarkShadow]}>
                <View style={styles.initialsContainer}>
                    <TextFields.SubTitleTextGray>
                        L
                    </TextFields.SubTitleTextGray>
                </View>

                <View style={styles.staffDetail}>
                    <TextFields.TitleText1Lines2>
                        Chat with your care team
                    </TextFields.TitleText1Lines2>

                    <View style={{ height: 2 }}></View>

                    <TextFields.SubTitleTextGray>
                        Powered by Lana
                    </TextFields.SubTitleTextGray>
                </View>
            </View>
        );
    };

    return (
        <View style={{ flex: 1, marginBottom: Platform.OS === "ios" ? -24 : 8 }}>
            <CareTeamDetail />
            <GiftedChat
                messages={messages}
                onSend={(messages) => onSend(messages)}
                user={{
                    _id: 1,
                }}
                renderAvatar={() => null}
                minInputToolbarHeight={56}
                renderInputToolbar={renderInputToolbar}
                placeholder={"Start typing..."}
                renderBubble={renderBubble}
                alwaysShowSend
                renderSend={renderSend}
            />
        </View>
    );
};

const styles = StyleSheet.create({
    initialsContainer: {
        width: 48,
        height: 48,
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 56,
        backgroundColor: getColorConfig().disabledButtonGray,
    },
    staffDetailBox: {
        flexDirection: "row",
        alignItems: "center",
        backgroundColor: getColorConfig().white,
        paddingHorizontal: 16,
        paddingVertical: 9,
    },
    staffDetail: {
        flexDirection: "column",
        justifyContent: "center",
        marginLeft: 16,
        marginRight: 56,
    },
});
