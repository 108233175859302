import React from "react";
import { Dimensions, Image, StyleSheet, View } from "react-native";
import { getColorConfig } from "../../constants/colors";
import { boxWithDarkShadow } from "../../constants/common-styles";
import { LogYourMedication } from "./LogYourMedicationSheet";
import { UpcomingAppointment } from "./UpcomingAppointmentSheet";
import { BottomSheetContext } from "../../components/bottom-sheet-view";
import TextFields from "../../components/text-fields";
import TouchableWithAnimation from "../../components/touchable-with-animation";

export default class HomeReminderCard extends React.Component {
    constructor(props) {
        super(props);
        this.cardName = props.cardName;
        this.icon = props.icon;
        this.title = props.title;
        this.subTitle = props.subTitle;
        this.showBadge = props.showBadge;
        this.bottomSheet = props.bottomSheet;
        this.medicationData = props.medicationData;
        this.appointmentData = props.appointmentData;
        this.medicationObjectUpdated = props.medicationObjectUpdated;
    }

    render() {
        return (
            <BottomSheetContext.Consumer>
                {({
                    showBottomSheet,
                    hideBottomSheet,
                    bottomSheetChildren,
                }) => {
                    this.showBottomSheet = showBottomSheet;
                    this.hideBottomSheet = hideBottomSheet;
                    this.bottomSheetChildren = bottomSheetChildren;
                    return (
                        <TouchableWithAnimation
                            scaleFactor={0.95}
                            onPress={() => this.showSheet()}
                        >
                            <View
                                style={[styles.resourceCard, boxWithDarkShadow]}
                            >
                                <View
                                    style={{
                                        flexDirection: "row",
                                        alignItem: "center",
                                    }}
                                >
                                    <TextFields.SubTitleTextGray>
                                        {this.cardName}
                                    </TextFields.SubTitleTextGray>

                                    <Image
                                        style={styles.arrowIcon}
                                        source={require("../../../assets/arrows/rightIconLightGray.png")}
                                    />
                                </View>
                                <View
                                    style={{
                                        flexDirection: "row",
                                        marginTop: 16,
                                    }}
                                >
                                    <Image
                                        style={{
                                            width: 48,
                                            height: 48,
                                            borderRadius: 24,
                                        }}
                                        source={this.icon}
                                    />
                                    <View
                                        style={{
                                            marginLeft: 16,
                                            marginRight: 56,
                                        }}
                                    >
                                        <TextFields.TitleText1Lines2>
                                            {this.title}
                                        </TextFields.TitleText1Lines2>

                                        <TextFields.SubTitleTextBlack
                                            numberOfLines={1}
                                            style={{
                                                width:
                                                    (Dimensions.get("screen")
                                                        .width /
                                                        5) *
                                                    3,
                                            }}
                                        >
                                            {this.subTitle}
                                        </TextFields.SubTitleTextBlack>
                                    </View>
                                </View>
                            </View>
                        </TouchableWithAnimation>
                    );
                }}
            </BottomSheetContext.Consumer>
        );
    }
    showSheet = () => {
        this.bottomSheetChildren(
            this.bottomSheet === "UpcomingAppointment" ? (
                <UpcomingAppointment hideBottomSheet={this.hideBottomSheet} appointmentData={this.appointmentData}/>
            ) : (
                <LogYourMedication hideBottomSheet={this.hideBottomSheet} medicationData={this.medicationData} medicationObjectUpdated={this.medicationObjectUpdated} />
            )
        );
        this.showBottomSheet();
    };
}

const styles = StyleSheet.create({
    resourceCard: {
        padding: 16,
        borderRadius: 12,
        marginVertical: 8,
        marginHorizontal: 16,
        backgroundColor: getColorConfig().white,
    },
    arrowIcon: {
        width: 24,
        height: 24,
        right: 0,
        position: "absolute",
    },
});
