import React from "react";
import { Image, View, StyleSheet } from "react-native";
import { getColorConfig } from "../../../constants/colors";
import { DATE_FORMAT } from "../../../constants/date-format";
import dateFormat from "dateformat";
import TextFields from "../../../components/text-fields";
import HorizontalLine from "../../../components/horizontal-line";
import LanaAppState from "../../../utils/lana-app-state";

export const ProfileVisitDetailsSheet = () => {
    const admitDate = LanaAppState.getInstance().getAdmitDate();
    const dischargeDate = LanaAppState.getInstance().getDischargeDate();
    return (
        <View>
            <TextFields.TitleText1>Visit Details</TextFields.TitleText1>
            <HorizontalLine style={{ height: 33 }} />
            <View
                style={{
                    alignItems: "center",
                }}
            >
                <View style={styles.imageBg}>
                    <Image
                        style={styles.image}
                        source={require("../../../../assets/logos/hospitalLogo.png")}
                    />
                </View>

                <TextFields.TitleText3_Bold
                    style={{
                        paddingTop: 16,
                    }}
                >
                    St. John’s Hospital
                </TextFields.TitleText3_Bold>
                <TextFields.SubTitleTextGray>
                    21st Street, Delaney Park, Orlando, FL
                </TextFields.SubTitleTextGray>
            </View>

            <HorizontalLine style={{ height: 33 }} />
            <View style={{ flexDirection: "row" }}>
                <TextFields.SubTitleTextGray style={{ width: 96 }}>
                    Admission:
                </TextFields.SubTitleTextGray>
                <TextFields.SubTitleTextBlack>
                    {dateFormat(admitDate, DATE_FORMAT.descriptive_date)}
                </TextFields.SubTitleTextBlack>
            </View>
            <View style={{ flexDirection: "row", paddingTop: 8 }}>
                <TextFields.SubTitleTextGray style={{ width: 96 }}>
                    Discharge:
                </TextFields.SubTitleTextGray>
                <TextFields.SubTitleTextBlack>
                    {dateFormat(dischargeDate, DATE_FORMAT.descriptive_date)}
                </TextFields.SubTitleTextBlack>
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    image: {
        width: 48,
        height: 48,
    },
    imageBg: {
        width: 80,
        height: 80,
        borderRadius: 48,
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: getColorConfig().limeGreen,
    },
});
