import React from "react";
import { Alert, Image, StyleSheet, View } from "react-native";
import TextFields from "./text-fields";
import TouchableWithAnimation from "./touchable-with-animation";

export default class AppRatingActionCard extends React.Component {
    constructor(props) {
        super(props);
        this.card = props.card;
        this.onUpdateCallback= props.onUpdateCallback;
        this.state = {
            rating: 0,
        };
    }

    selectedStar = (props) => {
        return (
            <TouchableWithAnimation
                key={"selected-star-" + props.index}
                onPress={() => this.setState({ rating: props.index + 1 })}
            >
                <View style={{ marginLeft: props.index == 0 ? 0 : 20 }}>
                    <Image
                        style={{ width: 40, height: 40 }}
                        source={require("../../assets/common/starSelected.png")}
                    />
                </View>
            </TouchableWithAnimation>
        );
    };

    unSelectedStar = (props) => {
        return (
            <TouchableWithAnimation
                key={"unselected-star-" + props.index}
                onPress={() => {
                    Alert.alert(
                        "Submit the app rating?",
                        "You have rated the app with " + (props.index + 1) + " stars",
                        [
                            {
                                text: "Cancel",
                                onPress: () => console.log("Cancel Pressed"),
                                style: "cancel"
                            },
                            {
                                text: "OK",
                                onPress: () => {
                                    this.setState({rating: props.index + 1})
                                    this.onUpdateCallback();
                                }
                            }
                        ]
                    );
                }
            }>
                <View style={{ marginLeft: props.index == 0 ? 0 : 20 }}>
                    <Image
                        style={{ width: 40, height: 40 }}
                        source={require("../../assets/common/starUnselected.png")}
                    />
                </View>
            </TouchableWithAnimation>
        );
    };

    starDiv = (props) => {
        return (
            <>
                {[...Array(5)].map((x, index) => {
                    if (props.rating > index) {
                        return this.selectedStar({ index });
                    } else {
                        return this.unSelectedStar({ index });
                    }
                })}
            </>
        );
    };

    render() {
        return (
            <View style={{ padding: 16 }}>
                <Image
                    style={{ width: 56, height: 56 }}
                    source={require("../../assets/logos/lanaLogo.png")}
                />

                <View style={{ paddingTop: 16 }}>
                    <TextFields.TitleText1>
                        {this.card.title}
                    </TextFields.TitleText1>
                </View>
                <View style={{ paddingTop: 2 }}>
                    <TextFields.SubTitleTextGray>
                        {this.card.description}
                    </TextFields.SubTitleTextGray>
                </View>
                <View style={{ paddingTop: 28, flexDirection: "row" }}>
                    <this.starDiv rating={this.state.rating} />
                </View>
            </View>
        );
    }
}

const styles = StyleSheet.create({
    backgroundImageView: {
        flex: 1,
        justifyContent: "flex-end",
    },
});
