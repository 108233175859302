import React from "react";
import {
    Dimensions,
    Image,
    Platform,
    ScrollView,
    StyleSheet,
    View,
} from "react-native";
import { DATE_FORMAT } from "../../constants/date-format";
import { PrimaryButton } from "../../components/primary-button";
import dateFormat from "dateformat";
import TextFields from "../../components/text-fields";
import HorizontalLine from "../../components/horizontal-line";

export const UpcomingAppointment = (props) => {
    console.log("appointmentData", JSON.stringify(props.appointmentData));
    return (
        <>
            <ScrollView showsVerticalScrollIndicator={false}>
                <TextFields.TitleText1>
                    Upcoming Appointment
                </TextFields.TitleText1>

                <HorizontalLine style={{ height: 33 }} />

                <TextFields.TitleText3_Bold>
                    {dateFormat(props.appointmentData.startTime, DATE_FORMAT.time) +
                        " - " +
                        dateFormat(props.appointmentData.endTime, DATE_FORMAT.time_with_marker)}
                </TextFields.TitleText3_Bold>

                <TextFields.SubTitleTextBlack style={{ marginDown: 20 }}>
                    {dateFormat(props.appointmentData.startTime, DATE_FORMAT.day_date)}
                </TextFields.SubTitleTextBlack>

                <View style={{ height: 20 }}></View>

                <View style={{ flexDirection: "row" }}>
                    <Image
                        style={styles.image}
                        source={require("../../../assets/healthData/doctor.png")}
                    />
                    <View style={styles.text}>
                        <TextFields.SubTitleTextBlack>
                            {props.appointmentData.practitioner.name}
                        </TextFields.SubTitleTextBlack>
                        <TextFields.SubTitleTextGray>
                            General Physician
                        </TextFields.SubTitleTextGray>
                    </View>
                </View>

                <View style={{ height: 20 }}></View>

                <View style={{ flexDirection: "row" }}>
                    <Image
                        style={styles.image}
                        source={require("../../../assets/healthData/hospitalUnit.png")}
                    />
                    <View style={styles.text}>
                        <TextFields.SubTitleTextBlack>
                            {props.appointmentData.location.displayName}
                        </TextFields.SubTitleTextBlack>
                        <TextFields.SubTitleTextGray style={{ width: 300 }}>
                            St. John's Hospital, 21st Street, Delaney Park,
                            Orlando, FL
                        </TextFields.SubTitleTextGray>
                    </View>
                </View>
            </ScrollView>

            <View
                style={{
                    height:
                        Platform.OS === "android"
                            ? Dimensions.get("window").height * 0.32
                            : Dimensions.get("window").height * 0.38,
                }}
            />

            <PrimaryButton
                // icon={require("../../../assets/common/directionWhite.png")}
                buttonText={"Close"}
                onPress={props.hideBottomSheet}
            />
        </>
    );
};

const styles = StyleSheet.create({
    image: {
        width: 48,
        height: 48,
    },
    text: {
        flexDirection: "column",
        justifyContent: "center",
        marginLeft: 16,
    },
});
