import React from "react";
import { Image, Platform, StyleSheet, View } from "react-native";
import { PrimaryButton } from "../../../../components/primary-button";
import { MedicationReminderSelectMedicineSheet } from "./MedicationReminderSelectMedicineSheet";
import TextFields from "../../../../components/text-fields";

export default class CreateReminderSheet extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <>
                <View style={styles.container}>
                    <Image
                        style={{
                            width: 120,
                            height: 120,
                            marginTop: 24,
                            marginBottom: 24,
                        }}
                        source={require("../../../../../assets/healthData/medicine.png")}
                    />
                    <TextFields.TitleText3_Bold style={{ textAlign: "center" }}>
                        Create reminders for your medications
                    </TextFields.TitleText3_Bold>

                    <TextFields.SubTitleTextGray
                        style={{ textAlign: "center", marginTop: 12 }}
                    >
                        Log your medications to make sure you don’t miss a dose
                    </TextFields.SubTitleTextGray>

                    <Image
                        style={{
                            width: 375,
                            height: 264,
                            marginTop: Platform.OS === "ios" ? 20 : 0,
                            marginBottom: 0
                        }}
                        source={require("../../../../../assets/healthData/createReminder.png")}
                    />
                </View>

                <PrimaryButton
                    buttonText={"Get started"}
                    onPress={() => {
                        this.props.bottomSheetChildren(
                            <MedicationReminderSelectMedicineSheet
                                bottomSheetChildren={
                                    this.props.bottomSheetChildren
                                }
                                showBottomSheet={this.props.showBottomSheet}
                                hideBottomSheet={this.props.hideBottomSheet}
                            />
                        );
                        this.props.showBottomSheet();
                    }}
                />
            </>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        marginHorizontal: 8,
        alignItems: "center",
        justifyContent: "center",
    },
});
