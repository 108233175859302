import React from "react";
import { View, StyleSheet, ScrollView } from "react-native";
import TextFields from "../../components/text-fields";
import ResourceCard from "../../components/resource-card";
import TouchableWithAnimation from "../../components/touchable-with-animation";
import HealthDataSelector from "./HealthDataSelector";

export default class HealthDataScreen extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <ScrollView
                showsVerticalScrollIndicator={false}
                style={{ paddingTop: 16 }}
            >
                <View style={styles.greetingTitle}>
                    <TextFields.MainHeadingText>
                        My Health Data
                    </TextFields.MainHeadingText>
                </View>
                {/* <View style={styles.homeContentView}>
                    <TouchableWithAnimation
                        scaleFactor={0.95}
                        onPress={() =>
                            this.props.navigation.navigate(
                                "DischargeInstructionsScreen"
                            )
                        }
                    >
                        <ResourceCard
                            icon={require("../../../assets/resourceCardIcons/dischargeInstructions.png")}
                            title="Discharge instructions"
                            subTitle="View discharge instructions"
                            arrowIcon={true}
                        />
                    </TouchableWithAnimation>
                </View>
                <View style={{ paddingLeft: 16, paddingTop: 24 }}>
                    <TextFields.TitleTextGray1>
                        Health data
                    </TextFields.TitleTextGray1>
                </View> */}
                <HealthDataSelector navigation={this.props.navigation} />
            </ScrollView>
        );
    }
}

const styles = StyleSheet.create({
    greetingTitle: {
        paddingHorizontal: 16,
        paddingVertical: 8,
        flexDirection: "row",
        alignItems: "center",
    },
    homeContentView: {
        flex: 1,
    },
});
