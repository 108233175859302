import React from "react";
import { getColorConfig } from "../constants/colors";
import { createStackNavigator } from "@react-navigation/stack";
import EducationScreen from "../pages/Education/EducationScreen";
import HospitalLogoTitle from "../components/hospital-logo-title";
import EducationDetailScreen from "../pages/Education/EducationDetailScreen";
import ProfileScreen from "../pages/Profile/ProfileScreen";
import { Image } from "react-native";
import TouchableWithAnimation from "../components/touchable-with-animation";
import TextFields from "../components/text-fields";
import LanaAppState from "../utils/lana-app-state";

const Stack = createStackNavigator();

export default class EducationScreenNavigator extends React.Component {
    openProfileScreen = () => {
        this.props.navigation.navigate("ProfileScreen");
    };
    render() {
        return (
            <Stack.Navigator
                screenOptions={{
                    cardStyle: { backgroundColor: "#F8F7F7" },
                }}
            >
                <Stack.Screen
                    name="EducationScreen"
                    component={EducationScreen}
                    options={{
                        headerTitle: (props) => (
                            <HospitalLogoTitle {...props} />
                        ),
                        headerTitleAlign: "center",
                        headerRight: ({ focused }) => (
                            <>
                                {LanaAppState.getInstance().getEnabledAuthFeatures().includes("PROFILE") &&
                                    <TouchableWithAnimation
                                        onPress={this.openProfileScreen}>
                                        <Image
                                            style={{
                                                marginRight: 16,
                                                width: 28,
                                                height: 28,
                                            }}
                                            source={
                                                focused
                                                    ? require("../../assets/profile/profileIconSelected.png")
                                                    : require("../../assets/profile/profileIconUnselected.png")
                                            }
                                        />
                                    </TouchableWithAnimation>
                                }
                            </>
                        ),
                        headerStyle: {
                            shadowColor: "#B3A8A8",
                            shadowOffset: { width: 0, height: 4 },
                            shadowOpacity: 0.2,
                            shadowRadius: 8,
                            elevation: 5,
                        },
                    }}
                />
                <Stack.Screen
                    name="EducationDetailScreen"
                    component={EducationDetailScreen}
                    options={{
                        headerTitle: (props) => (
                            <HospitalLogoTitle {...props} />
                        ),
                        headerTitleAlign: "center",
                        headerBackTitle: " ",
                        headerTintColor: getColorConfig().primaryColor,
                        headerStyle: {
                            shadowColor: "#B3A8A8",
                            shadowOffset: { width: 0, height: 4 },
                            shadowOpacity: 0.2,
                            shadowRadius: 8,
                            elevation: 5,
                        },
                    }}
                />
                <Stack.Screen
                    name="ProfileScreen"
                    component={ProfileScreen}
                    options={{
                        headerTitle: () => (
                            <TextFields.TitleText2>
                                My Profile
                            </TextFields.TitleText2>
                        ),
                        headerTitleAlign: "center",
                        headerBackTitle: " ",
                        headerTintColor: getColorConfig().primaryColor,
                        headerStyle: {
                            shadowColor: "#B3A8A8",
                            shadowOffset: { width: 0, height: 4 },
                            shadowOpacity: 0.2,
                            shadowRadius: 8,
                            elevation: 5,
                        },
                    }}
                />
            </Stack.Navigator>
        );
    }
}
