import React from 'react';
import { Image } from 'react-native';
import LanaAppState from '../utils/lana-app-state';

export default class HospitalLogoTitle extends React.Component {
  render() {
      return (
        <Image
          style={{width: 161, height: 32, resizeMode: 'contain'}}
          source={{uri: LanaAppState.getInstance().getBranding().brandingImage.imageLinkLight}}
        />
      )
  }
}