import React from "react";
import { getColorConfig } from "../constants/colors";
import { createStackNavigator } from "@react-navigation/stack";
import TextFields from "../components/text-fields";
import HospitalLogoTitle from "../components/hospital-logo-title";
import HealthDataScreen from "../pages/HealthData/HealthDataScreen";
import DischargeInstructionsScreen from "../pages/DischargeInstructionsScreen";
import MedicationScreen from "../pages/HealthData/Medications/MedicationScreen";
import AppointmentScreen from "../pages/HealthData/Appointments/AppointmentScreen";
import MedicationLogScreen from "../pages/HealthData/Medications/MedicationLog/MedicationLogScreen";
import MedicationDetailScreen from "../pages/HealthData/Medications/MedicationDetail/MedicationDetailScreen";
import MedicationLogRemindersList from "../pages/HealthData/Medications/MedicationLog/MedicationLogRemindersList";
import LabResultsScreen from "../pages/HealthData/Appointments/LabResultsScreen";
import ImagingResultsScreen from "../pages/HealthData/Appointments/ImagingResultsScreen";
import DicomScreen from "../pages/DicomScreen";
import ProfileScreen from "../pages/Profile/ProfileScreen";
import TouchableWithAnimation from "../components/touchable-with-animation";
import { Image } from "react-native";
import DicomWebviewScreen from "../pages/HealthData/Appointments/DicomWebviewScreen";
import LanaAppState from "../utils/lana-app-state";

const Stack = createStackNavigator();

export default class HomeScreenNavigator extends React.Component {
    openProfileScreen = () => {
        this.props.navigation.navigate("ProfileScreen");
    };
    render() {
        return (
            <Stack.Navigator
                screenOptions={{
                    cardStyle: { backgroundColor: "#F8F7F7" },
                }}
            >
                <Stack.Screen
                    name="HealthDataScreen"
                    component={HealthDataScreen}
                    options={{
                        headerTitle: (props) => (
                            <HospitalLogoTitle {...props} />
                        ),
                        headerTitleAlign: "center",
                        headerRight: ({ focused }) => (
                            <>
                                {LanaAppState.getInstance().getEnabledAuthFeatures().includes("PROFILE") &&
                                    <TouchableWithAnimation
                                        onPress={this.openProfileScreen}>
                                        <Image
                                            style={{
                                                marginRight: 16,
                                                width: 28,
                                                height: 28,
                                            }}
                                            source={
                                                focused
                                                    ? require("../../assets/profile/profileIconSelected.png")
                                                    : require("../../assets/profile/profileIconUnselected.png")
                                            }
                                        />
                                    </TouchableWithAnimation>
                                }
                            </>
                        ),
                        headerStyle: {
                            shadowColor: "#B3A8A8",
                            shadowOffset: { width: 0, height: 4 },
                            shadowOpacity: 0.2,
                            shadowRadius: 8,
                            elevation: 5,
                        },
                    }}
                />
                <Stack.Screen
                    name="DicomScreen"
                    component={DicomScreen}
                    options={{
                        headerTitle: () => (
                            <TextFields.TitleText2>
                                Dicom Screens
                            </TextFields.TitleText2>
                        ),
                        headerTitleAlign: "center",
                        headerBackTitle: " ",
                        headerTintColor: getColorConfig().primaryColor,
                        headerStyle: {
                            shadowColor: "#B3A8A8",
                            shadowOffset: { width: 0, height: 4 },
                            shadowOpacity: 0.2,
                            shadowRadius: 8,
                            elevation: 5,
                        },
                    }}
                />
                <Stack.Screen
                    name="DischargeInstructionsScreen"
                    component={DischargeInstructionsScreen}
                    options={{
                        headerTitle: () => (
                            <TextFields.TitleText2>
                                Discharge Instructions
                            </TextFields.TitleText2>
                        ),
                        headerTitleAlign: "center",
                        headerBackTitle: " ",
                        headerTintColor: getColorConfig().primaryColor,
                        headerStyle: {
                            shadowColor: "#B3A8A8",
                            shadowOffset: { width: 0, height: 4 },
                            shadowOpacity: 0.2,
                            shadowRadius: 8,
                            elevation: 5,
                        },
                    }}
                />
                <Stack.Screen
                    name="AppointmentScreen"
                    component={AppointmentScreen}
                    options={{
                        headerTitle: () => (
                            <TextFields.TitleText2>
                                Appointments
                            </TextFields.TitleText2>
                        ),
                        headerTitleAlign: "center",
                        headerBackTitle: " ",
                        headerTintColor: getColorConfig().primaryColor,
                        headerStyle: {
                            shadowColor: "#B3A8A8",
                            shadowOffset: { width: 0, height: 4 },
                            shadowOpacity: 0.2,
                            shadowRadius: 8,
                            elevation: 5,
                        },
                    }}
                />
                <Stack.Screen
                    name="ImagingResultsScreen"
                    component={ImagingResultsScreen}
                    options={{
                        headerTitle: () => (
                            <TextFields.TitleText2>
                                Imaging Results
                            </TextFields.TitleText2>
                        ),
                        headerTitleAlign: "center",
                        headerBackTitle: " ",
                        headerTintColor: getColorConfig().primaryColor,
                        headerStyle: {
                            shadowColor: "#B3A8A8",
                            shadowOffset: { width: 0, height: 4 },
                            shadowOpacity: 0.2,
                            shadowRadius: 8,
                            elevation: 5,
                        },
                    }}
                />
                <Stack.Screen
                    name="DicomWebviewScreen"
                    component={DicomWebviewScreen}
                    options={{
                        headerTitle: () => (
                            <TextFields.TitleText2>
                                Your Report
                            </TextFields.TitleText2>
                        ),
                        headerTitleAlign: "center",
                        headerBackTitle: " ",
                        headerTintColor: getColorConfig().primaryColor,
                        headerStyle: {
                            shadowColor: "#B3A8A8",
                            shadowOffset: { width: 0, height: 4 },
                            shadowOpacity: 0.2,
                            shadowRadius: 8,
                            elevation: 5,
                        },
                    }}
                />
                <Stack.Screen
                    name="LabResultsScreen"
                    component={LabResultsScreen}
                    options={{
                        headerTitle: () => (
                            <TextFields.TitleText2>
                                Lab Results
                            </TextFields.TitleText2>
                        ),
                        headerTitleAlign: "center",
                        headerBackTitle: " ",
                        headerTintColor: getColorConfig().primaryColor,
                        headerStyle: {
                            shadowColor: "#B3A8A8",
                            shadowOffset: { width: 0, height: 4 },
                            shadowOpacity: 0.2,
                            shadowRadius: 8,
                            elevation: 5,
                        },
                    }}
                />
                <Stack.Screen
                    name="MedicationScreen"
                    component={MedicationScreen}
                    options={{
                        headerTitle: () => (
                            <TextFields.TitleText2>
                                Medications
                            </TextFields.TitleText2>
                        ),
                        headerTitleAlign: "center",
                        headerBackTitle: " ",
                        headerTintColor: getColorConfig().primaryColor,
                        headerStyle: {
                            shadowColor: "#B3A8A8",
                            shadowOffset: { width: 0, height: 4 },
                            shadowOpacity: 0.2,
                            shadowRadius: 8,
                            elevation: 5,
                        },
                    }}
                />
                <Stack.Screen
                    name="MedicationLogScreen"
                    component={MedicationLogScreen}
                    options={{
                        headerTitle: () => (
                            <TextFields.TitleText2>
                                My Medication Log
                            </TextFields.TitleText2>
                        ),
                        headerTitleAlign: "center",
                        headerBackTitle: " ",
                        headerTintColor: getColorConfig().primaryColor,
                        headerStyle: {
                            shadowColor: "#B3A8A8",
                            shadowOffset: { width: 0, height: 4 },
                            shadowOpacity: 0.2,
                            shadowRadius: 8,
                            elevation: 5,
                        },
                    }}
                />
                <Stack.Screen
                    name="MedicationLogRemindersList"
                    component={MedicationLogRemindersList}
                    options={{
                        headerTitle: () => (
                            <TextFields.TitleText2>
                                My Reminders
                            </TextFields.TitleText2>
                        ),
                        headerTitleAlign: "center",
                        headerBackTitle: " ",
                        headerTintColor: getColorConfig().primaryColor,
                        headerStyle: {
                            shadowColor: "#B3A8A8",
                            shadowOffset: { width: 0, height: 4 },
                            shadowOpacity: 0.2,
                            shadowRadius: 8,
                            elevation: 5,
                        },
                    }}
                />
                <Stack.Screen
                    name="MedicationDetailScreen"
                    component={MedicationDetailScreen}
                    options={{
                        headerTitle: () => (
                            <TextFields.TitleText2>
                                Medication Details
                            </TextFields.TitleText2>
                        ),
                        headerTitleAlign: "center",
                        headerBackTitle: " ",
                        headerTintColor: getColorConfig().primaryColor,
                        headerStyle: {
                            shadowColor: "#B3A8A8",
                            shadowOffset: { width: 0, height: 4 },
                            shadowOpacity: 0.2,
                            shadowRadius: 8,
                            elevation: 5,
                        },
                    }}
                />
                <Stack.Screen
                    name="ProfileScreen"
                    component={ProfileScreen}
                    options={{
                        headerTitle: () => (
                            <TextFields.TitleText2>
                                My Profile
                            </TextFields.TitleText2>
                        ),
                        headerTitleAlign: "center",
                        headerBackTitle: " ",
                        headerTintColor: getColorConfig().primaryColor,
                        headerStyle: {
                            shadowColor: "#B3A8A8",
                            shadowOffset: { width: 0, height: 4 },
                            shadowOpacity: 0.2,
                            shadowRadius: 8,
                            elevation: 5,
                        },
                    }}
                />
            </Stack.Navigator>
        );
    }
}
