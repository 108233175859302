import React from "react";
import { View, Image, StyleSheet, ScrollView, ActivityIndicator } from "react-native";
import HomeReminderCard from "./HomeReminderCard";
import TextFields from "../../components/text-fields";
import PrimaryActionCards from "../../components/primary-action-cards";
import * as APIHandler from "../../apiHandlers/APIHandler";
import dateFormat from "dateformat";
import { DATE_FORMAT } from "../../constants/date-format";
import { getObjectData, storeObjectData } from "../../utils/storage-helper";
import { MEDICATION_OBJECT_DATA } from "./LogYourMedicationSheet";
import LanaAppState from "../../utils/lana-app-state";

const MAX_MINUTE_DIFF = 100000;

export default class HomeScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            medication: {
                medicationTime: null,
                medicationReminders: []    
            },
            appointment: null,
            storedMedications: [],
            medicationLoading: true,
            appointmentLoading: true,
            primaryCardsLoading: true
        }
    }

    componentDidMount() {
        this.setupMedicationObject();
        APIHandler.getAppointments(
            (data) => {
                let pendingAppointments = data.appointments.filter(
                    (appointment) =>
                        appointment.appointmentStatus === "PENDING"
                )
                if(pendingAppointments.length > 0) {
                    this.setState({
                        appointment: pendingAppointments[0]
                    })
                }
                this.setState({appointmentLoading: false})
            },
            () => {
                console.log("Error fetching appointments");
                this.setState({appointmentLoading: false})
            }
        );
    }

    setupMedicationObject = () => {
        getObjectData(MEDICATION_OBJECT_DATA)
            .then((storedMedications) => {
                if(storedMedications) {
                    this.setState({storedMedications})
                }
                    APIHandler.getRemindersForPatient(
                        (data) => {
                            const recentReminder = this.getRecentReminder(data.data);
                            this.setState({
                                medication: {
                                    medicationTime: recentReminder.time,
                                    medicationReminders: recentReminder.reminders
                                }
                            })
                            this.setState({medicationLoading: false})
                        },
                        () => {
                            console.log("Error fetching patient reminders");
                            this.setState({medicationLoading: false})
                        }
                    );
        });
    }

    render() {
        return (
            <View style={{flex: 1}}>
                {(this.state.medicationLoading || this.state.appointmentLoading || this.state.primaryCardsLoading) &&
                    <View style={{
                            position: 'absolute', top: 0, left: 0, right: 0, bottom: 0,
                            zIndex: 1000, backgroundColor: '#F8F7F7', flex: 1, justifyContent: 'center', alignItems: 'center'}}>
                        <ActivityIndicator size={"large"} />
                    </View>
                }
                <ScrollView
                    showsVerticalScrollIndicator={false}
                    style={{ paddingTop: 16, zIndex: 10 }}
                >
                    <View style={styles.greetingTitle}>
                        <TextFields.MainHeadingText>
                            Hi, {LanaAppState.getInstance().getPatientFirstName()}
                        </TextFields.MainHeadingText>
                        <Image
                            style={styles.greetingEmoji}
                            source={require("../../../assets/common/waveEmoji.png")}
                        />
                    </View>
    
                    <View style={styles.homeContentView}>
                        <PrimaryActionCards navigation={this.props.navigation} primaryCardsLoaded={() => this.setState({primaryCardsLoading: false})} />
    
                        <View style={{ paddingLeft: 16, paddingTop: 24 }}>
                            <TextFields.TitleTextGray1>
                                Reminders
                            </TextFields.TitleTextGray1>
                        </View>
                        {this.state.medication.medicationTime 
                            && this.state.medication.medicationReminders 
                            && this.state.medication.medicationReminders.length > 0 
                            &&
                            <HomeReminderCard
                                cardName={"Medications"}
                                icon={require("../../../assets/healthData/medicine.png")}
                                title={this.printableTime(this.state.medication.medicationTime)}
                                subTitle={this.printableMedicationDisplayNames()}
                                bottomSheet={"LogYourMedication"}
                                medicationData={this.state.medication}
                                medicationObjectUpdated={this.setupMedicationObject}
                            />
                        }
                        {this.state.appointment &&
                            <HomeReminderCard
                                cardName={"Appointment"}
                                icon={require("../../../assets/healthData/appointment.png")}
                                title={dateFormat(
                                    this.state.appointment.startTime,
                                    DATE_FORMAT.date_time
                                )}
                                subTitle={
                                    this.state.appointment.practitioner.name +
                                    ", " +
                                    this.state.appointment.location.displayName
                                }
                                bottomSheet={"UpcomingAppointment"}
                                showBadge={true}
                                appointmentData={this.state.appointment}
                            />
                        }
    
                    </View>
                    <View style={{ height: 32 }}></View>
                </ScrollView>
            </View>
        );

    }

    getRecentReminder(remindersList) {
        let now = new Date();
        let closestRecurrence = this.getClosestRecurrence(remindersList);
        let remindersResponseList = [];
        
        for(let i=0; i<remindersList.length; i++) {
            const reminder = remindersList[i];

            let startDate = new Date(reminder.schedule.startDate);
            let endDate = new Date(reminder.schedule.endDate);
            endDate.setDate(endDate.getDate() + 1);

            if(now >= startDate && now < endDate) {
                if(reminder.schedule.recurrenceType === 'DAILY') {
                    if(reminder.schedule.dailyRecurrencesList) {
                        for(let j=0; j<reminder.schedule.dailyRecurrencesList.length; j++) {
                            let dailyRecurrence = reminder.schedule.dailyRecurrencesList[j];
                            let diff = this.dailyRecurrenceDifferenceInMinutes(closestRecurrence, dailyRecurrence);
                            if(diff == 0) {
                                remindersResponseList.push(reminder)
                            }
                        }    
                    }
                } else if(reminder.schedule.recurrenceType === 'EVERY_X_DAYS') {
                    if(this.isEveryXDaysValidOnDay(startDate, reminder.schedule)) {
                        if(reminder.schedule.dailyRecurrencesList) {
                            for(let j=0; j<reminder.schedule.everyXDaysRecurrences.length; j++) {
                                let dailyRecurrence = reminder.schedule.everyXDaysRecurrences[j];
                                let diff = this.dailyRecurrenceDifferenceInMinutes(closestRecurrence, dailyRecurrence);
                                if(diff == 0) {
                                    remindersResponseList.push(reminder)
                                }    
                            }
                        }
                    }
                }
            }
        }

        return {
            time: closestRecurrence,
            reminders: remindersResponseList
        }

    }

    getClosestRecurrence(remindersList) {
        let closestRecurrence = null;
        if(!remindersList) {
            return closestRecurrence;
        }

        let now = new Date();
        for(let i=0; i<remindersList.length; i++) {
            const reminder = remindersList[i];

            let startDate = new Date(reminder.schedule.startDate);
            let endDate = new Date(reminder.schedule.endDate);
            endDate.setDate(endDate.getDate() + 1);

            let closestAbsoluteMinute = MAX_MINUTE_DIFF;
            
            const currentDailyRecurrence = {
                hourOfTheDay: now.getHours(),
                minuteOfTheHour: now.getMinutes()
            }

            if(now >= startDate && now < endDate) {
                if(reminder.schedule.recurrenceType === 'DAILY') {
                    if(reminder.schedule.dailyRecurrencesList) {
                        for(let j=0; j<reminder.schedule.dailyRecurrencesList.length; j++) {
                            let dailyRecurrence = reminder.schedule.dailyRecurrencesList[j];
                            let diff = this.dailyRecurrenceDifferenceInMinutes(currentDailyRecurrence, dailyRecurrence);
                            if(this.toAbsoluteMinutes(currentDailyRecurrence) > this.toAbsoluteMinutes(dailyRecurrence)) {
                                if(diff < 120) {
                                    if(diff < closestAbsoluteMinute) {
                                        // check if medication is not marked as taken
                                        if(!this.isMedicationTakenForTime(reminder, dailyRecurrence)) {
                                            closestAbsoluteMinute = diff;
                                            closestRecurrence = dailyRecurrence;
                                        }
                                    }        
                                } else {
                                    // ignore reminders earlier than 2 hours from now
                                }
                            } else {
                                if(diff < closestAbsoluteMinute) {
                                    if(!this.isMedicationTakenForTime(reminder, dailyRecurrence)) {
                                        closestAbsoluteMinute = diff;
                                        closestRecurrence = dailyRecurrence;    
                                    }
                                }
                            }
                        }
                    }
                } else if(reminder.schedule.recurrenceType === 'EVERY_X_DAYS') {
                    if(this.isEveryXDaysValidOnDay(startDate, reminder.schedule)) {
                        if(reminder.schedule.dailyRecurrencesList) {
                            for(let j=0; j<reminder.schedule.everyXDaysRecurrences.length; j++) {
                                let dailyRecurrence = reminder.schedule.everyXDaysRecurrences[j];
                                let diff = this.dailyRecurrenceDifferenceInMinutes(currentDailyRecurrence, dailyRecurrence);
                                if(this.toAbsoluteMinutes(currentDailyRecurrence) > this.toAbsoluteMinutes(dailyRecurrence)) {
                                    if(diff < 120) {
                                        if(diff < closestAbsoluteMinute) {
                                            // check if medication is not marked as taken
                                            if(!this.isMedicationTakenForTime(reminder, dailyRecurrence)) {
                                                closestAbsoluteMinute = diff;
                                                closestRecurrence = dailyRecurrence;
                                            }
                                        }        
                                    } else {
                                        // ignore reminders earlier than 2 hours from now
                                    }
                                } else {
                                    if(diff < closestAbsoluteMinute) {
                                        if(!this.isMedicationTakenForTime(reminder, dailyRecurrence)) {
                                            closestAbsoluteMinute = diff;
                                            closestRecurrence = dailyRecurrence;    
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        return closestRecurrence;
    }

    isMedicationTakenForTime(reminder, dailyRecurrence) {
        let medicationTaken = false;
        const now = new Date();
        for(let k=0; k<this.state.storedMedications.length; k++) {
            const takenMedication = this.state.storedMedications[k];
            if((takenMedication.medicationId === reminder.medication.medicationId) 
                && (takenMedication.hour == dailyRecurrence.hourOfTheDay) 
                && (takenMedication.minute == dailyRecurrence.minuteOfTheHour) 
                && (new Date(takenMedication.date).getTime() == this.stripDateOfTime(now).getTime())
                ) {
                    medicationTaken = true;
            }
        }
        return medicationTaken;
    }

    toAbsoluteMinutes(dailyRecurrences) {
        return ((dailyRecurrences.hourOfTheDay * 60) + dailyRecurrences.minuteOfTheHour);
    }

    dailyRecurrenceDifferenceInMinutes(dailyRecurrences1, dailyRecurrences2) {
        if((!dailyRecurrences1) || (!dailyRecurrences2)) {
            return MAX_MINUTE_DIFF;
        }
        const ans = Math.abs((this.toAbsoluteMinutes(dailyRecurrences2) - this.toAbsoluteMinutes(dailyRecurrences1)) % 1440);
        if(ans > 720) {
            return 1440 - ans;
        } else {
            return ans;
        }
	}

    isEveryXDaysValidOnDay(startDate, schedule) {
        let now = new Date();
		let daysToSkip = schedule.everyXDaysRecurrences[0].daysToSkip;
		let dayDifference = this.dateDifferenceInDays(this.stripDateOfTime(startDate), this.stripDateOfTime(now))
		return (dayDifference % (daysToSkip+1) == 0);
	}

    dateDifferenceInDays(d1, d2) {
        var diff = d2.getTime() - d1.getTime();   
        return parseInt(diff / (1000 * 60 * 60 * 24));
    }

    stripDateOfTime(date) {
        return new Date(date.getFullYear(), date.getMonth(), date.getDate());
    }

    printableTime(recurrence) {
        if(!recurrence) {
            return "";
        }
        function padZero(number) {
            return (number < 10 ? '0' : '')+number
        }
        return (recurrence.hourOfTheDay%12 || 12) + ':' + padZero(recurrence.minuteOfTheHour) + ' ' + (recurrence.hourOfTheDay<12? 'AM' :'PM');          
    }

    printableMedicationDisplayNames() {
        let displayNames = "";
        this.state.medication.medicationReminders.map((reminder, index) => {
            if((reminder) && (reminder.medication)) {
                if(index > 0) {
                    displayNames += ", "
                }
                displayNames += reminder.medication.medicationDisplayName;
            }
        })
        return displayNames;
    }

}

const styles = StyleSheet.create({
    greetingTitle: {
        paddingHorizontal: 16,
        paddingVertical: 8,
        flexDirection: "row",
        alignItems: "center",
    },
    greetingEmoji: {
        width: 28,
        height: 28,
    },
    homeContentView: {
        flex: 1,
    },
});
