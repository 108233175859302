export const tabIconImage = (routeName, focused) => {
    if (routeName === "Home" && focused) {
        return require("../../assets/tabIcons/homeTabSelected.png");
    } else if (routeName === "Home") {
        return require("../../assets/tabIcons/homeTabUnselected.png");
    } else if (routeName === "My Health" && focused) {
        return require("../../assets/tabIcons/healthTabSelected.png");
    } else if (routeName === "My Health") {
        return require("../../assets/tabIcons/healthTabUnselected.png");
    } else if (routeName === "Learn" && focused) {
        return require("../../assets/tabIcons/learnTabSelected.png");
    } else if (routeName === "Learn") {
        return require("../../assets/tabIcons/learnTabUnselected.png");
    } else if (routeName === "Chat" && focused) {
        return require("../../assets/tabIcons/chatTabSelected.png");
    } else if (routeName === "Chat") {
        return require("../../assets/tabIcons/chatTabUnselected.png");
    }
};
