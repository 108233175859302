import React from "react";
import { StyleSheet, View } from "react-native";
import { getColorConfig } from "../constants/colors";

export default class HorizontalLine extends React.Component {
    constructor(props) {
        super(props);
        this.style = props.style
    }

    render() {
        return (
            <View style={this.style}>
                <View style={styles.horizontalLine} />
            </View>
        )
    }
}

const styles = StyleSheet.create({
    horizontalLine: {
        flex: 1,
        height: 1,
        marginVertical: 16,
        flexDirection: "row",
        alignItems: "center",
        backgroundColor: getColorConfig().borderGray,
    },
});
