import LanaAppState from "./lana-app-state";
import Constants from 'expo-constants';

function getHeadersForRequest(method, url) {
    var headersObject = {
        Accept: "application/json",
    };
    if (method === "POST") {
        headersObject["Content-Type"] = "application/json";
    }
    if (url !== "auth/home-care/login") {
        if (LanaAppState.getInstance().getAccessToken()) {
            headersObject["Authorization"] =
                LanaAppState.getInstance().getAccessToken();
        }
    }
    return headersObject;
}

export function makePostCall(url, body, successCallback, failureCallback) {
    fetch(Constants.expoConfig.apiUrl + url, {
        method: "POST",
        headers: getHeadersForRequest("POST", url),
        body: JSON.stringify(body),
    })
        .then((response) => {
            if (response.status >= 200 && response.status < 300) {
                return response.json();
            } else {
                console.log("response.status", response.status);
                const error = new Error(
                    url +
                        " failed with status code: " +
                        response.status +
                        " response: " +
                        response.json()
                );
                failureCallback(error, response.status);
            }
        })
        .then((data) => {
            if(data.success == null || data.success == undefined || data.success) {
                console.log("Inside .then : ");
                if (successCallback) {
                    successCallback(data);
                }    
            } else {
                if(failureCallback) {
                    failureCallback(data)
                }
            }
        })
        .catch((error) => {
            console.error(error);
            if (failureCallback) {
                failureCallback(error);
            }
        })
        .finally(() => {});
}

export function makeGetCall(url, successCallback, failureCallback) {
    fetch(Constants.expoConfig.apiUrl + url, {
        method: "GET",
        headers: getHeadersForRequest("GET", url),
    })
        .then((response) => {
            if (response.status >= 200 && response.status < 300) {
                return response.json();
            } else {
                const error = new Error(
                    url +
                        " failed with status code: " +
                        response.status +
                        " response: " +
                        response.json()
                );
                failureCallback(error, response.status);
            }
        })
        .then((data) => {
            if (successCallback) {
                successCallback(data);
            }
        })
        .catch((error) => {
            if (failureCallback) {
                failureCallback(error);
            }
        })
        .finally(() => {});
}
