import { WebView } from 'react-native-webview';
import React from 'react';
import { Platform } from 'react-native';

export default class DischargeInstructionsScreen extends React.Component {
    
    constructor(props) {
        super(props)
    }

    render() {
        return Platform.OS === "web" ? (
            <iframe src="https://storage.googleapis.com/lana-health-website/intructions/PERC%20-%20Discharge%20Instructions%20Handout.pdf" height={'100%'} width={'100%'} />
          ) : (
            <WebView
                source={{
                uri: 'https://storage.googleapis.com/lana-health-website/intructions/PERC%20-%20Discharge%20Instructions%20Handout.pdf'
                }}/>
        )
    }
}