import React from "react";
import { View, ScrollView, StyleSheet, Dimensions, ActivityIndicator } from "react-native";
import WebView from "react-native-webview";
import * as APIHandler from "../../../apiHandlers/APIHandler";
import ResourceCard from "../../../components/resource-card";
import TouchableWithAnimation from "../../../components/touchable-with-animation";

export default class ImagingResultsScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            imagingResults: []
        }
    }

    componentDidMount() {
        APIHandler.getImagingResulst(
            (data) => {
                this.setState({
                    loaded: true,
                    imagingResults: data.ehrImagingResultList
                });
            },
            () => {
                console.log("Error fetching imaging results");
            }
        );
    }

    render() {
        if(!this.state.loaded) {
            return (
                <View style={{flex: 1, flexDirection: "column", justifyContent: "center"}}>
                    <ActivityIndicator size={"large"}/>
                </View>
            )
        } else {
            return (
                <ScrollView
                    showsVerticalScrollIndicator={false}
                    style={{ paddingTop: 16 }}>
                    <View style={styles.contentView}>
                        {this.state.imagingResults.map((imagingResult) => {
                            return (<TouchableWithAnimation
                                scaleFactor={0.95}
                                onPress={() => {
                                    this.props.navigation.navigate("DicomWebviewScreen", {
                                        dicomUrl: imagingResult.presentedForm[0].url
                                    })
                                }}>
                                    <ResourceCard
                                        icon={require("../../../../assets/healthData/imagingResultsIconColor.png")}
                                        title={imagingResult.presentedForm[0].title}
                                        subTitle={"recorded: Dec 29, 2022"}
                                        arrowIcon={true}
                                    />
                            </TouchableWithAnimation>)
                        })}
                    </View>
                </ScrollView>            
            );
        }
    }
}

const styles = StyleSheet.create({
    contentView: {
        flex: 1,
    },
});
