import React from "react";
import { Dimensions, Image, StyleSheet, View, Text } from "react-native";
import { getColorConfig } from "../constants/colors";
import { boxWithDarkShadow } from "../constants/common-styles";
import TextFields from "./text-fields";

export default class ResourceCard2 extends React.Component {
    constructor(props) {
        super(props);
        this.style = props.style;
        this.icon = props.icon;
        this.title = props.title;
        this.subTitle = props.subTitle;
        this.arrowIcon = props.arrowIcon == true ? true : false;
        this.shadow = props.shadow == false ? false : true;
    }

    render() {
        return (
            <View
                style={[
                    styles.resourceCard,
                    this.style,
                    this.shadow ? boxWithDarkShadow : styles.borderGray,
                ]}
            >
                <Image
                    style={{ width: 48, height: 48, borderRadius: 24 }}
                    source={this.icon}
                />
                <View
                    style={{
                        flexDirection: "column",
                        justifyContent: "center",
                        marginLeft: 16,
                        // marginRight: 100,
                    }}
                >
                    <Text style={{fontFamily: "MetropolisMedium", fontSize: 18, color: getColorConfig().black,}}>
                        {this.title}
                    </Text>

                </View>
                <View
                    style={{
                        flex: 1,
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "right",
                        marginLeft: 16,
                        marginRight: 16,
                        position: 'relative'
                    }}
                >
                    <View style={{position: 'absolute', right: 0}}>
                        <TextFields.SubTitleTextGray>
                            {this.subTitle}
                        </TextFields.SubTitleTextGray>
                    </View>
                </View>
            </View>
        );
    }

    showArrow() {
        if (this.arrowIcon == true) {
            return (
                <Image
                    style={styles.arrowIcon}
                    source={require("../../assets/arrows/rightIconLightGray.png")}
                />
            );
        }
    }

    subTitleStyle() {
        return (
            <View style={{flex: 1, flexDirection: 'row', backgroundColor: 'red', justifyContent: 'space-between'}}>
                <TextFields.MainHeadingText style={{alignSelf: 'flex-end'}}>
                        {this.subTitle}
                </TextFields.MainHeadingText>

            </View>

        );
        // return (
        //     <TextFields.SubTitleTextGray style={{textAlign: 'right'}}>
        //         {this.subTitle}
        //     </TextFields.SubTitleTextGray>
        // );
    }
}

const styles = StyleSheet.create({
    resourceCard: {
        paddingVertical: 24,
        paddingHorizontal: 16,
        borderRadius: 12,
        marginVertical: 8,
        marginHorizontal: 16,
        flexDirection: "row",
        backgroundColor: getColorConfig().white,
    },
    arrowIcon: {
        width: 24,
        height: 24,
        marginVertical: 36,
        marginHorizontal: 16,
        right: 0,
        position: "absolute",
    },
    borderGray: {
        borderWidth: 1,
        borderColor: getColorConfig().borderGray,
    },
});
