import React from "react";
import * as Linking from "expo-linking";
import { StyleSheet, View } from "react-native";
import { getColorConfig } from "../../constants/colors";
import { CommonConstants } from "../../constants/common-constants";
import { boxWithDarkShadow } from "../../constants/common-styles";
import ListButton from "../../components/list-button";
import HorizontalLine from "../../components/horizontal-line";

export default class ProfileResourcesSelector extends React.Component {
    makeCall = () => {
        Linking.openURL("tel:" + CommonConstants.hospitalHelplineCall);
    };

    render() {
        return (
            <View style={[styles.listStyle, boxWithDarkShadow]}>
                <ListButton
                    icon={require("../../../assets/profile/dischargeGray.png")}
                    title={"Discharge Instructions"}
                    arrowIcon={true}
                    onClick={() => {
                        this.props.navigation.navigate(
                            "DischargeInstructionsScreen"
                        );
                    }}
                />

                <HorizontalLine />

                <ListButton
                    icon={require("../../../assets/profile/callGray.png")}
                    title={"Hospital Helpline"}
                    arrowIcon={true}
                    onClick={() => this.makeCall()}
                />
            </View>
        );
    }
}

const styles = StyleSheet.create({
    listStyle: {
        padding: 16,
        marginHorizontal: 16,
        marginVertical: 16,
        backgroundColor: getColorConfig().white,
        borderRadius: 12,
    },
});
