import React from "react";
import { BackHandler } from "react-native";
import { DATE_FORMAT } from "../../../constants/date-format";
import { AppointmentDetailSheet } from "./AppointmentDetailSheet";
import { BottomSheetContext } from "../../../components/bottom-sheet-view";
import dateFormat from "dateformat";
import ResourceCard from "../../../components/resource-card";
import TouchableWithAnimation from "../../../components/touchable-with-animation";

export default class AppointmentCard extends React.Component {
    constructor(props) {
        super(props);
        this.appointmentData = props.appointmentData;
    }

    showAppointmentDetailScreen = () => {
        this.bottomSheetChildren(
            <AppointmentDetailSheet appointmentData={this.appointmentData} />
        );
        this.showBottomSheet();
    };

    handleBackButton = () => {
        BackHandler.removeEventListener(
            "hardwareBackPress",
            this.handleBackButton
        );
        this.hideBottomSheet();
        return true;
    };

    componentDidMount() {
        BackHandler.addEventListener(
            "hardwareBackPress",
            this.handleBackButton
        );
    }

    render() {
        return (
            <BottomSheetContext.Consumer>
                {({
                    showBottomSheet,
                    hideBottomSheet,
                    bottomSheetChildren,
                }) => {
                    this.showBottomSheet = showBottomSheet;
                    this.hideBottomSheet = hideBottomSheet;
                    this.bottomSheetChildren = bottomSheetChildren;
                    return (
                        <TouchableWithAnimation
                            scaleFactor={0.95}
                            onPress={() => this.showAppointmentDetailScreen()}
                        >
                            <ResourceCard
                                icon={require("../../../../assets/healthData/appointment.png")}
                                title={dateFormat(
                                    this.appointmentData.startTime,
                                    DATE_FORMAT.date_time
                                )}
                                subTitle={
                                    this.appointmentData.practitioner.name +
                                    ", " +
                                    this.appointmentData.location.displayName
                                }
                                subTitleBlack={true}
                                arrowIcon={true}
                            />
                        </TouchableWithAnimation>
                    );
                }}
            </BottomSheetContext.Consumer>
        );
    }
}
