import React from "react";
import { View, ScrollView, StyleSheet } from "react-native";
import * as APIHandler from "../../../apiHandlers/APIHandler";
import TextFields from "../../../components/text-fields";
import AppointmentCard from "./AppointmentCard";

export default class AppointmentScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pendingAppointments: [],
            fulfilledAppointments: [],
        };
    }

    componentDidMount() {
        APIHandler.getAppointments(
            (data) => {
                this.setState({
                    pendingAppointments: data.appointments.filter(
                        (appointment) =>
                            appointment.appointmentStatus === "PENDING"
                    ),
                    fulfilledAppointments: data.appointments.filter(
                        (appointment) =>
                            appointment.appointmentStatus === "FULFILLED"
                    ),
                });
            },
            () => {
                console.log("Error fetching appointments");
            }
        );
    }

    render() {
        return (
            <ScrollView
                showsVerticalScrollIndicator={false}
                style={{ paddingTop: 16 }}
            >
                <View style={styles.appointmentContentView}>
                    {this.state.pendingAppointments.length > 0 && (
                        <View style={{ paddingLeft: 16, paddingTop: 24 }}>
                            <TextFields.TitleTextGray1>
                                Upcoming Appointments
                            </TextFields.TitleTextGray1>
                        </View>
                    )}
                    {this.state.pendingAppointments &&
                        this.state.pendingAppointments.map((appointment) => {
                            return (
                                <AppointmentCard
                                    key={
                                        "upcomingAppointment-" +
                                        appointment.appointmentId
                                    }
                                    appointmentData={appointment}
                                />
                            );
                        })}

                    {this.state.fulfilledAppointments.length > 0 && (
                        <View style={{ paddingLeft: 16, paddingTop: 24 }}>
                            <TextFields.TitleTextGray1>
                                Past Appointments
                            </TextFields.TitleTextGray1>
                        </View>
                    )}
                    {this.state.fulfilledAppointments &&
                        this.state.fulfilledAppointments.map((appointment) => {
                            return (
                                <AppointmentCard
                                    key={
                                        "pastAppointment-" +
                                        appointment.appointmentId
                                    }
                                    appointmentData={appointment}
                                />
                            );
                        })}
                </View>
            </ScrollView>
        );
    }
}

const styles = StyleSheet.create({
    appointmentContentView: {
        flex: 1,
    },
});
