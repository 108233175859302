import React, { useState } from "react";
import {
    Dimensions,
    Image,
    Platform,
    StyleSheet,
    TextInput,
    View,
} from "react-native";
import { getColorConfig } from "../../../../constants/colors";
import { DATE_FORMAT } from "../../../../constants/date-format";
import { DateTimeSelectorDevice } from "../../../../components/dateTimeSelectorDevice";
import dateFormat from "dateformat";
import TextFields from "../../../../components/text-fields";
import TouchableWithAnimation from "../../../../components/touchable-with-animation";

export const Card = (props) => {
    return (
        <View style={[styles.resourceCard, styles.borderGray]}>
            <Image
                style={{ width: 48, height: 48, borderRadius: 24 }}
                source={require("../../../../../assets/healthData/medicine.png")}
            />
            <View
                style={{
                    flexDirection: "column",
                    justifyContent: "center",
                    marginLeft: 16,
                    marginRight: 56,
                }}
            >
                <TextFields.TitleText1Lines2>
                    {props.medicationData.medicationDisplayName}
                </TextFields.TitleText1Lines2>

                <TextFields.SubTitleTextGray>
                    {props.medicationData.dosageInstructions[0].display}
                </TextFields.SubTitleTextGray>
            </View>
        </View>
    );
};

export const ReminderTimeList = (props) => {
    var reminderTimeArray = props.reminderTimeArray;
    return reminderTimeArray.map((time, index) => {
        return (
            <View
                key={"timeComponent_" + index}
                style={{ marginTop: 8, width: "100%", flexDirection: "row" }}
            >
                <TouchableWithAnimation
                    scaleFactor={0.95}
                    onPress={() => {
                        props.setReminderTimeArray(
                            props.reminderTimeArray.filter(
                                (item) => item.id !== time.id
                            )
                        );
                    }}
                >
                    <Image
                        style={{
                            width: 28,
                            height: 28,
                            marginRight: 8,
                            marginTop: 8,
                        }}
                        source={require("../../../../../assets/common/removeIconRedWithBg.png")}
                    />
                </TouchableWithAnimation>
                <TimeSetter
                    index={index}
                    reminderTimeArray={reminderTimeArray}
                    setReminderTimeArray={props.setReminderTimeArray}
                />
            </View>
        );
    });
};

export const AddTimeButton = (props) => {
    return (
        <TouchableWithAnimation
            scaleFactor={0.95}
            onPress={props.handleAddTime}
        >
            <View style={{ marginTop: 8, flexDirection: "row" }}>
                <Image
                    style={{
                        width: 28,
                        height: 28,
                        marginRight: 8,
                    }}
                    source={require("../../../../../assets/common/addIconPurpleWithBg.png")}
                />
                <TextFields.ButtonTextPurple style={{ marginTop: 2 }}>
                    Add a time
                </TextFields.ButtonTextPurple>
            </View>
        </TouchableWithAnimation>
    );
};

export const TimeSetter = (props) => {
    const [isPickerVisible, setPickerVisibility] = useState(false);
    const window = Dimensions.get("window");

    const showPicker = () => {
        setPickerVisibility(true);
    };
    const handleConfirm = (time) => {
        const updatedReminderTime = [...props.reminderTimeArray];
        updatedReminderTime[props.index].time = time;
        props.setReminderTimeArray(updatedReminderTime);
        hidePicker();
    };
    const hidePicker = () => {
        setPickerVisibility(false);
    };

    if (Platform.OS === "ios" || Platform.OS === "android") {
        return (
            <View>
                <TouchableWithAnimation scaleFactor={0.95} onPress={showPicker}>
                    <View
                        style={[styles.containerStyle, { width: window.width }]}
                    >
                        <TextFields.SubTitleTextBlack>
                            {dateFormat(
                                props.reminderTimeArray[props.index].time,
                                DATE_FORMAT.time_with_marker
                            )}
                        </TextFields.SubTitleTextBlack>

                        <Image
                            style={styles.iconStyle}
                            source={require("../../../../../assets/common/dropdownArrow.png")}
                        />
                    </View>
                </TouchableWithAnimation>

                <DateTimeSelectorDevice
                    mode="time"
                    selectedDate={props.reminderTimeArray[props.index].time}
                    isPickerVisible={isPickerVisible}
                    handleConfirm={handleConfirm}
                    hidePicker={hidePicker}
                />
            </View>
        );
    } else {
        return (
            <TextInput
                style={[styles.containerStyle, { marginTop: 8 }]}
                value={props.reminderTimeArray[props.index].time}
                onChangeText={handleConfirm}
                placeholder="Select time"
            />
        );
    }
};

export const DateSetter = (props) => {
    const [isPickerVisible, setPickerVisibility] = useState(false);

    const showPicker = () => {
        setPickerVisibility(true);
    };
    const handleConfirm = (date) => {
        props.setSelectedDate(date);
        hidePicker();
    };
    const hidePicker = () => {
        setPickerVisibility(false);
    };

    if (Platform.OS === "ios" || Platform.OS === "android") {
        return (
            <View style={{ marginBottom: 72 }}>
                <TouchableWithAnimation scaleFactor={0.95} onPress={showPicker}>
                    <View style={[styles.containerStyle, { marginTop: 8 }]}>
                        <TextFields.SubTitleTextBlack>
                            {dateFormat(
                                props.reminderDate,
                                DATE_FORMAT.descriptive_date
                            )}
                        </TextFields.SubTitleTextBlack>

                        <Image
                            style={styles.iconStyle}
                            source={require("../../../../../assets/common/dropdownArrow.png")}
                        />
                    </View>
                </TouchableWithAnimation>

                <DateTimeSelectorDevice
                    mode="date"
                    selectedDate={props.reminderDate}
                    isPickerVisible={isPickerVisible}
                    handleConfirm={handleConfirm}
                    hidePicker={hidePicker}
                    display={Platform.OS === "ios" ? "inline" : ""}
                />
            </View>
        );
    } else {
        return (
            <TextInput
                style={[styles.containerStyle, { marginTop: 8 }]}
                value={props.reminderDate}
                onChangeText={handleConfirm}
                placeholder="Select date"
            />
        );
    }
};

const styles = StyleSheet.create({
    resourceCard: {
        padding: 16,
        marginVertical: 8,
        borderRadius: 12,
        flexDirection: "row",
        backgroundColor: getColorConfig().white,
    },
    borderGray: {
        borderWidth: 1,
        borderColor: getColorConfig().borderGray,
    },
    containerStyle: {
        paddingHorizontal: 12,
        paddingVertical: 10,
        borderWidth: 1,
        borderRadius: 12,
        flexDirection: "row",
        borderColor: getColorConfig().borderGray,
    },
    iconStyle: {
        width: 24,
        height: 24,
        marginVertical: 10,
        marginHorizontal: 12,
        right: 0,
        position: "absolute",
    },
});
