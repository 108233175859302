export const boxWithDarkShadow = {
    shadowColor: "#B3A8A8",
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 0.2,
    shadowRadius: 8,
    elevation: 5,
};

export const boxWithLightShadow = {
    shadowColor: "#BCB3B3",
    shadowOpacity: 0.2,
    shadowRadius: 8,
    elevation: 5,
};
