import React, { useState } from "react";
import {
    Image,
    Platform,
    ScrollView,
    StyleSheet,
    View,
} from "react-native";
import { DATE_FORMAT } from "../../constants/date-format";
import { PrimaryButton } from "../../components/primary-button";
import { SecondaryButton } from "../../components/secondary-button";
import dateFormat from "dateformat";
import TextFields from "../../components/text-fields";
import HorizontalLine from "../../components/horizontal-line";
import TouchableWithAnimation from "../../components/touchable-with-animation";
import { getObjectData, storeObjectData } from "../../utils/storage-helper";

export const MEDICATION_OBJECT_DATA = 'medicationLog';

export const LogYourMedication = (props) => {
    const date = new Date();

    let selectedMedications = {}; // medicationId -> medicationObject
    const medicationStateChanged = (medication, isSelected) => {
        if(isSelected) {
            selectedMedications[medication.medicationId] = medication;
        } else {
            delete selectedMedications[medication.medicationId];
        }
    }

    const markAsTakenClicked = () => {
        let newMedicationsLogged = [];
        const medicationIds = Object.keys(selectedMedications);
        for(let i=0; i<medicationIds.length; i++) {
            const medicationId = medicationIds[i];
            newMedicationsLogged.push({
                medicationId: medicationId,
                hour: props.medicationData.medicationTime.hourOfTheDay,
                minute: props.medicationData.medicationTime.minuteOfTheHour,
                date: stripDateOfTime(date),
                medication: selectedMedications[medicationId],
                loggedTime: new Date()
            });
        }
        getObjectData(MEDICATION_OBJECT_DATA)
            .then((storedMedications) => {
                if((!storedMedications) || storedMedications.length == 0) {
                    storeObjectData(MEDICATION_OBJECT_DATA, newMedicationsLogged)
                        .then(() => {
                            props.medicationObjectUpdated();
                        });
                } else {
                    storeObjectData(MEDICATION_OBJECT_DATA, storedMedications.concat(newMedicationsLogged))
                    .then(() => {
                        props.medicationObjectUpdated();
                    });
                }
            })
        props.hideBottomSheet();
    }

    const stripDateOfTime = (date) => {
        return new Date(date.getFullYear(), date.getMonth(), date.getDate());
    }

    const printableTime = (recurrence) => {
        if(!recurrence) {
            return "";
        }
        function padZero(number) {
            return (number < 10 ? '0' : '')+number
        }
        return (recurrence.hourOfTheDay%12 || 12) + ':' + padZero(recurrence.minuteOfTheHour) + ' ' + (recurrence.hourOfTheDay<12? 'AM' :'PM');          
    }

    return (
        <View style={{width: '100%', height: '100%', position: 'relative'}}>
            <ScrollView showsVerticalScrollIndicator={false}>
                <TextFields.TitleText1>Log Your Medications</TextFields.TitleText1>

                <HorizontalLine style={{ height: 33 }} />

                <TextFields.TitleText3_Bold>
                    {printableTime(props.medicationData.medicationTime)}
                </TextFields.TitleText3_Bold>

                <TextFields.SubTitleTextBlack style={{ marginDown: 20 }}>
                    {dateFormat(date, DATE_FORMAT.day_date)}
                </TextFields.SubTitleTextBlack>

                <Medication 
                    medicationReminders={props.medicationData.medicationReminders}
                    medicationStateChanged={medicationStateChanged} />

                <View style={{ height: 24 }} />
            </ScrollView>
            <View style={{position: 'absolute', bottom: 24, right: 0, left: 0}}>
                    <PrimaryButton
                        icon={require("../../../assets/common/tickWithWhiteBg.png")}
                        buttonText={"Mark as taken"}
                        onPress={markAsTakenClicked}
                    />
                    {/* <SecondaryButton
                        icon={require("../../../assets/healthData/notificationPurple.png")}
                        buttonText={"Remind me in 10 mins"}
                        onPress={props.hideBottomSheet}
                    /> */}
            </View>

        </View>
    );
};

export class Medication extends React.Component {
    constructor(props) {
        super(props);

        let selectedArray = [];
        this.props.medicationReminders.map(() => selectedArray.push(false));

        this.state = {
            selected: selectedArray
        }
    }

    render() {
        return(
            <>
                {this.props.medicationReminders.map((reminder, index) => {
                    return (
                        <TouchableWithAnimation
                            key={"medication_" + reminder.medication.medicationId}
                            scaleFactor={0.95}
                            onPress={() => {
                                const newSelectedValue = !this.state.selected[index];
                                this.state.selected[index] = newSelectedValue;
                                this.setState({
                                    selected: {...this.state.selected}
                                })
                                this.props.medicationStateChanged(reminder.medication, newSelectedValue);
                            }}>
                                <View
                                    style={{
                                        flexDirection: "row",
                                        alignItems: "center",
                                        marginTop: 20,
                                    }}
                                >
                                    <Image
                                        style={{ width: 48, height: 48, borderRadius: 24 }}
                                        source={require("../../../assets/healthData/medicine.png")}
                                    />
                                    <View
                                        style={{
                                            flexDirection: "column",
                                            justifyContent: "center",
                                            marginLeft: 16,
                                        }}
                                    >
                                        <TextFields.TitleText1Lines2>
                                            {reminder.medication.medicationDisplayName}
                                        </TextFields.TitleText1Lines2>
                
                                        <View style={{marginRight: 64}}>
                                            <TextFields.SubTitleTextGray>
                                                {reminder.medication.dosageInstructions[0].display}
                                            </TextFields.SubTitleTextGray>
                                        </View>
                                    </View>
                
                                    <Image
                                        style={styles.tickIcon}
                                        source={
                                            this.state.selected[index]
                                                ? require("../../../assets/common/selectedTick.png")
                                                : require("../../../assets/common/unselectedTick.png")
                                        }
                                    />
                                </View>
                        </TouchableWithAnimation>
                    )
                })}
            </>
        )
    }

}

const styles = StyleSheet.create({
    tickIcon: {
        width: 24,
        height: 24,
        borderRadius: 24,
        right: 0,
        position: "absolute",
    },
    button: {
        left: 0,
        right: 0,
        borderRadius: 12,
        marginVertical: 10,
        alignItems: "center",
        flexDirection: "row",
        justifyContent: "center",
    },
});
