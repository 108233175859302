import React from "react";
import { View } from "react-native";
import { DATE_FORMAT } from "../../constants/date-format";
import { BottomSheetContext } from "../../components/bottom-sheet-view";
import { ProfileVisitDetailsSheet } from "./ProfileSheets/ProfileVisitDetailsSheet";
import { ProfileEmergencyContactSheet } from "./ProfileSheets/ProfileEmergencyContactSheet";
import dateFormat from "dateformat";
import ListButton from "../../components/list-button";
import HorizontalLine from "../../components/horizontal-line";

export default class ProfileOptionSelector extends React.Component {
    render() {
        return (
            <BottomSheetContext.Consumer>
                {({
                    showBottomSheet,
                    hideBottomSheet,
                    bottomSheetChildren,
                }) => {
                    this.showBottomSheet = showBottomSheet;
                    this.hideBottomSheet = hideBottomSheet;
                    this.bottomSheetChildren = bottomSheetChildren;
                    return (
                        <View>
                            <ListButton
                                icon={require("../../../assets/profile/visitDetailsGray.png")}
                                title={"Visit Details"}
                                arrowIcon={true}
                                onClick={() =>
                                    this.showProfileVisitDetailsSheet()
                                }
                            />

                            <HorizontalLine />

                            <ListButton
                                icon={require("../../../assets/profile/emergencyContactGray.png")}
                                title={"Emergency Contact"}
                                arrowIcon={true}
                                onClick={() =>
                                    this.showProfileEmergencyContactSheet()
                                }
                            />

                            <HorizontalLine />

                            <ListButton
                                icon={require("../../../assets/profile/myRemindersGray.png")}
                                title={"My Reminders"}
                                arrowIcon={true}
                                onClick={() =>
                                    this.navigateToMedicationLogRemindersScreen()
                                }
                            />

                            {/* <HorizontalLine />

                            <ListButton
                                icon={require("../../../assets/profile/shareWithFamilyGray.png")}
                                title={"Share with Family"}
                                showComingSoonBadge={false}
                                arrowIcon={true}
                                disable={true}
                                onClick={() => {}}
                            /> */}
                        </View>
                    );
                }}
            </BottomSheetContext.Consumer>
        );
    }

    showProfileVisitDetailsSheet = () => {
        this.bottomSheetChildren(<ProfileVisitDetailsSheet />);
        this.showBottomSheet();
    };

    showProfileEmergencyContactSheet = () => {
        this.bottomSheetChildren(<ProfileEmergencyContactSheet />);
        this.showBottomSheet();
    };

    navigateToMedicationLogRemindersScreen = () => {
        this.props.navigation.navigate("MedicationLogRemindersList");
    };
}
