import React from "react";
import { Image, ScrollView, StyleSheet, View } from "react-native";
import { getColorConfig } from "../../../constants/colors";
import TextFields from "../../../components/text-fields";
import HorizontalLine from "../../../components/horizontal-line";

export const ProfileAboutHospital = () => {
    return (
        <ScrollView showsVerticalScrollIndicator={false}>
            <TextFields.TitleText1>
                About St. John’s Hospital
            </TextFields.TitleText1>

            <HorizontalLine style={{ height: 33 }} />

            <View style={styles.container}>
                <View style={styles.imageBg}>
                    <Image
                        style={styles.image}
                        source={require("../../../../assets/logos/hospitalLogo.png")}
                    />
                </View>
                <TextFields.TitleText3>
                    St. John’s Hospital
                </TextFields.TitleText3>
                <TextFields.SubTitleTextGray>
                    21st Street, Delaney Park, Orlando, FL
                </TextFields.SubTitleTextGray>
            </View>

            <HorizontalLine style={{ height: 33 }} />

            <TextFields.TitleText3>About</TextFields.TitleText3>
            <TextFields.SubTitleTextGray>
                {
                    "St. John’s Hospital is a Registered Charity under the Charities Acts (Registered Charity No. 20000394) and is administered and managed in accordance with a Hospital Constitution approved by the Charities Regulatory Authority.  The current Hospital Constitution was approved in 2018.  The property is vested in Trustees.\n\nThe Hospital has a total of 99 beds – 89 In-Patient beds and 10 Day Care beds. The In-Patient specialties are General Medicine, General Surgery and Gynaecology. We also have an Urgent Care Centre incorporating a Local Injuries Unit and Medical Assessment Unit."
                }
            </TextFields.SubTitleTextGray>

            <View style={{ height: 64 }}></View>
        </ScrollView>
    );
};

const styles = StyleSheet.create({
    container: {
        justifyContent: "center",
        alignItems: "center",
    },
    image: {
        width: 48,
        height: 48,
    },
    imageBg: {
        width: 80,
        height: 80,
        borderRadius: 48,
        marginBottom: 16,
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: getColorConfig().limeGreen,
    },
});
