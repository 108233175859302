import React from "react";
import { Image, ImageBackground, StyleSheet, View } from "react-native";
import { BlurView } from "expo-blur";
import { getColorConfig } from "../constants/colors";
import TextFields from "./text-fields";
import CommonComponents from "./common-components";
import * as APIHandler from "../apiHandlers/APIHandler";
import TouchableWithAnimation from "./touchable-with-animation";

export default class EducationActionCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            imageUrl: { uri: props.imageUrl },
            title: props.title,
            duration: props.duration,
            completionRate: props.completionRate,
        };
    }

    render() {
        return (
            <View style={{ flex: 1 }}>
                <ImageBackground
                    source={this.state.imageUrl}
                    resizeMode="cover"
                    imageStyle={styles.backgroundImage}
                    style={styles.backgroundImageView}
                >
                    <View style={{ backgroundColor: "rgba(0, 0, 0, 0.45)" }}>
                        <BlurView
                            intensity={24}
                            style={{ flexDirection: "column" }}
                        >
                            <View style={{ padding: 16 }}>
                                <TextFields.WhiteButtonText>
                                    Complete your education for today
                                </TextFields.WhiteButtonText>
                                <TextFields.WhiteButtonText>
                                    {this.state.title}
                                </TextFields.WhiteButtonText>
                            </View>
                            <View
                                style={{
                                    paddingHorizontal: 16,
                                    paddingBottom: 16,
                                    flexDirection: "row",
                                }}
                            >
                                <Image
                                    style={{ width: 36, height: 36 }}
                                    source={require("../../assets/common/playIconBlackWithBg.png")}
                                />
                                <View
                                    style={{
                                        justifyContent: "center",
                                        paddingLeft: 16,
                                    }}
                                >
                                    <TextFields.DarkCardSubText>
                                        {this.state.duration}
                                    </TextFields.DarkCardSubText>
                                </View>
                                <CommonComponents.ProgressBar
                                    progressRate={this.state.completionRate}
                                    backgroundColor={
                                        getColorConfig().whiteOpacityBackground
                                    }
                                    style={{ paddingHorizontal: 16, flex: 1 }}
                                    highlightColor={getColorConfig().white}
                                />
                            </View>
                        </BlurView>
                    </View>
                </ImageBackground>
            </View>
        );
    }
}

const styles = StyleSheet.create({
    backgroundImageView: {
        flex: 1,
        justifyContent: "flex-end",
    },
    backgroundImage: {
        width: "100%",
    },
});
