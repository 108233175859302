import React from "react";
import { Animated, Pressable } from "react-native";

export default class TouchableWithAnimation extends React.Component {
    constructor(props) {
        super(props);
        this.scaleFactor = props.scaleFactor;
        if (!this.scaleFactor) {
            this.scaleFactor = 0.9;
        }
        this.animatedValue = new Animated.Value(1);
    }

    handlePressIn = () => {
        Animated.spring(this.animatedValue, {
            toValue: this.scaleFactor,
            useNativeDriver: true,
        }).start();
    };

    handlePressOut = () => {
        Animated.spring(this.animatedValue, {
            toValue: 1.0,
            useNativeDriver: true,
        }).start();
    };

    handleOnPress = () => {
        if (this.props.onPress) {
            this.props.onPress();
        }
    };

    disableButton() {
        var disable = false;
        if (this.props.disable) {
            disable = this.props.disable;
        } else {
            disable = false;
        }
        return disable;
    }

    render() {
        const animatedStyle = {
            transform: [{ scale: this.animatedValue }],
        };
        return (
            <Pressable
                onPressIn={() => this.handlePressIn()}
                onPressOut={() => {
                    this.handlePressOut();
                }}
                onPress={() => this.handleOnPress()}
                disabled={this.disableButton()}
            >
                <Animated.View style={animatedStyle}>
                    {this.props.children}
                </Animated.View>
            </Pressable>
        );
    }
}
