export const DATE_FORMAT = {
    short_day_date: "ddd, mmm dd",
    shortest_day_date: "mmm dd",
    day_date: "dddd, mmm dd",
    descriptive_date: "mmm dd, yyyy",
    date_time: "mmm dd, h:MM TT",
    time: "h:MM",
    time_with_marker: "h:MM TT",
    date_hour: "dd mm yyyy, hh",
};
