import React from "react";
import {
    Animated,
    Dimensions,
    Pressable,
    StyleSheet,
    View,
} from "react-native";
import { getColorConfig } from "../constants/colors";
import { boxWithDarkShadow } from "../constants/common-styles";
import * as APIHandler from "../apiHandlers/APIHandler";
import SurveyActionCard from "./survey-action-card";
import EducationActionCard from "./education-action-card";
import AppRatingActionCard from "./app-rating-action-card";
import GestureRecognizer from "react-native-swipe-gestures";
import TouchableWithAnimation from "./touchable-with-animation";
import { getObjectData, storeObjectData } from "../utils/storage-helper";

export const APP_RATING_VAR_NAME = "appRatingSubmitted";

const config = {
    velocityThreshold: 0.3,
    directionalOffsetThreshold: 80,
};

export default class PrimaryActionCards extends React.Component {
    constructor(props) {
        super(props);
        this.primaryCardsLoaded = props.primaryCardsLoaded
        this.state = {
            primaryCardData: [],
            animation: new Animated.Value(1),
            currentActiveCarouselCard: 0,
            totalCarouselCards: 0
        };
    }

    componentDidMount() {
        this.updateCoordinations();
        // Uncomment the following line for debugging app rating
        // storeObjectData(APP_RATING_VAR_NAME, {value: false});
    }

    updateCoordinations = () => {
        APIHandler.getCoordinations(
            (data) => {
                let cardDataList = [];
                let shouldShowRatingCard = true;
                getObjectData(APP_RATING_VAR_NAME)
                    .then((appRatingSubmitted) => {
                        if(appRatingSubmitted && appRatingSubmitted.value) {
                            shouldShowRatingCard = false;
                        }
        
                        data.responseList.map((responseData) => {
                            if(responseData.coordinationType !== 'RATE_THE_APP' || (shouldShowRatingCard)) {
                                cardDataList.push(responseData)
                            }
                        })
                        this.setState({
                            primaryCardData: cardDataList,
                            totalCarouselCards: cardDataList.length
                        });
                        this.navigateTo(0);
                    })
                this.props.primaryCardsLoaded();
            },
            () => {
                this.props.primaryCardsLoaded();
            }
        );
    }

    removeRatingCard = () => {
        storeObjectData(APP_RATING_VAR_NAME, {value: true});
        this.updateCoordinations();
    }

    animate = (newActiveCard) => {
        Animated.spring(this.state.animation, {
            toValue: -newActiveCard * Math.min(550, Dimensions.get("screen").width),
            useNativeDriver: true,
        }).start();
    };

    onSwipeLeft = (gestureState) => {
        if (
            this.state.currentActiveCarouselCard + 1 ===
            this.state.totalCarouselCards
        ) {
            return;
        }
        const newActiveCard =
            (this.state.currentActiveCarouselCard + 1) %
            this.state.totalCarouselCards;
        this.navigateTo(newActiveCard);
    };

    onSwipeRight = (gestureState) => {
        if (this.state.currentActiveCarouselCard === 0) {
            return;
        }
        const newActiveCard =
            (this.state.currentActiveCarouselCard -
                1 +
                this.state.totalCarouselCards) %
            this.state.totalCarouselCards;
        this.navigateTo(newActiveCard);
    };

    navigateTo = (index) => {
        this.setState({
            currentActiveCarouselCard: index,
        });
        this.animate(index);
    };

    displayCarouselDots = (activeIndex, count) => {
        return (
            <>
                {[...Array(count)].map((x, index) => {
                    if (activeIndex === index) {
                        return (
                            <Pressable
                                key={"carouselDots-" + index}
                                onPress={() => this.navigateTo(index)}
                            >
                                <View>
                                    <View style={styles.selectedCarousel} />
                                </View>
                            </Pressable>
                        );
                    } else {
                        return (
                            <Pressable
                                key={"carouselDots-" + index}
                                onPress={() => this.navigateTo(index)}
                            >
                                <View>
                                    <View style={styles.unselectedCarousel} />
                                </View>
                            </Pressable>
                        );
                    }
                })}
            </>
        );
    };

    continueWatchingEducationCard = (card) => {
        return (
            <TouchableWithAnimation
                key={"card-" + card.coordinationType + card.title}
                scaleFactor={0.95}
                onPress={() =>
                    this.props.navigation.navigate("EducationDetailScreen", {
                        educationData: card.patientEducationResponse,
                    })
                }
            >
                <View style={boxWithDarkShadow}>
                    <View style={styles.actionCardStyle}>
                        <EducationActionCard
                            imageUrl={
                                card.patientEducationResponse.educationAsset
                                    .thumbnailWide
                            }
                            title={
                                card.patientEducationResponse.educationAsset
                                    .languagesAvailable[0].displayName
                            }
                            duration={new Date(
                                card.patientEducationResponse.educationAsset
                                    .languagesAvailable[0].durationInSeconds *
                                    1000
                            )
                                .toISOString()
                                .slice(14, 19)}
                            completionRate={
                                card.patientEducationResponse.completionPercent
                            }
                            educationAsset={
                                card.patientEducationResponse.educationAsset
                            }
                        />
                    </View>
                </View>
            </TouchableWithAnimation>
        );
    };

    render() {
        const animatedStyle = {
            transform: [{ translateX: this.state.animation }],
        };

        return (
            <View>
                <GestureRecognizer
                    onSwipeLeft={(state) => this.onSwipeLeft(state)}
                    onSwipeRight={(state) => this.onSwipeRight(state)}
                    config={config}
                >
                    <Animated.View
                        style={[styles.animatedViewStyle, animatedStyle]}
                    >
                        {this.state.primaryCardData &&
                            this.state.primaryCardData.map((card) => {
                                if (card.coordinationType == "SURVEY") {
                                    return (
                                        <View
                                            key={
                                                "card-" +
                                                card.coordinationType +
                                                card.survey.formId
                                            }
                                            style={boxWithDarkShadow}
                                        >
                                            <View
                                                style={styles.actionCardStyle}
                                            >
                                                <SurveyActionCard card={card} onUpdateCallback={() => this.updateCoordinations()} />
                                            </View>
                                        </View>
                                    );
                                } else if (card.coordinationType == "EDUCATION") {
                                    return this.continueWatchingEducationCard(
                                        card
                                    );
                                } else if (card.coordinationType == "RATE_THE_APP") {
                                    return (
                                        <View
                                            key={
                                                "card-" +
                                                card.coordinationType +
                                                card.title
                                            }
                                            style={boxWithDarkShadow}
                                        >
                                            <View
                                                style={styles.actionCardStyle}
                                            >
                                                <AppRatingActionCard
                                                    card={card}
                                                    onUpdateCallback={() => this.removeRatingCard()} 
                                                />
                                            </View>
                                        </View>
                                    );
                                }
                            })}
                    </Animated.View>
                </GestureRecognizer>

                <View
                    style={{
                        flexDirection: "row",
                        justifyContent: "center",
                    }}
                >
                    {this.displayCarouselDots(
                        this.state.currentActiveCarouselCard,
                        this.state.totalCarouselCards
                    )}
                </View>
            </View>
        );
    }
}

const styles = StyleSheet.create({
    animatedViewStyle: {
        flexDirection: "row",
    },
    actionCardStyle: {
        backgroundColor: getColorConfig().white,
        margin: 16,
        borderRadius: 12,
        overflow: "hidden",
        maxWidth: 518,
        width: Dimensions.get("screen").width - 32,
        height: 240,
    },
    unselectedCarousel: {
        width: 8,
        height: 8,
        borderRadius: 4,
        marginHorizontal: 8,
        backgroundColor: getColorConfig().mainLightColor,
    },
    selectedCarousel: {
        width: 8,
        height: 8,
        borderRadius: 4,
        marginHorizontal: 8,
        backgroundColor: getColorConfig().primaryColor,
    },
});
