export default class LanaAppState {
    static myInstance = null;

    _hospitalId = null;
    _hospitalName = null;
    _encounterId = null;
    _patientId = null;
    _accessToken = null;
    _patientName = null;
    _admitDate = null;
    _dischargeDate = null;
    _branding = null;
    _homeCareTabTypes = [];
    _enabledAuthFeatures = [];
    _homeCareAuthType = null;

    static getInstance() {
        if (LanaAppState.myInstance == null) {
            LanaAppState.myInstance = new LanaAppState();
        }
        return this.myInstance;
    }

    getHospitalId() {
        return this._hospitalId;
    }

    setHospitalId(id) {
        this._hospitalId = id;
    }

    getPatientId() {
        return this._patientId;
    }

    setPatientId(id) {
        this._patientId = id;
    }

    getEncounterId() {
        return this._encounterId;
    }

    setEncounterId(id) {
        this._encounterId = id;
    }

    getAccessToken() {
        return this._accessToken;
    }

    setAccessToken(accessToken) {
        this._accessToken = accessToken;
    }

    getPatientFirstName() {
        return this._patientName.split(" ")[0];
    }

    getPatientName() { 
        return this._patientName;
    }

    setPatientName(patientName) {
        this._patientName = patientName;
    }

    getAdmitDate() { 
        return this._admitDate;
    }

    setAdmitDate(admitDate) {
        this._admitDate = admitDate;
    }

    getDischargeDate() { 
        return this._dischargeDate;
    }

    setDischargeDate(dischargeDate) {
        this._dischargeDate = dischargeDate;
    }

    getHospitalName() { 
        return this._hospitalName;
    }

    setHospitalName(hospitalName) {
        this._hospitalName = hospitalName;
    }

    getBranding() { 
        return this._branding;
    }

    setBranding(branding) {
        this._branding = branding;
    }

    getHomeCareTabTypes() {
        return this._homeCareTabTypes;
    }

    setHomeCareTabTypes(homeCareTabTypes) {
        this._homeCareTabTypes = homeCareTabTypes;
    }

    getEnabledAuthFeatures() {
        return this._enabledAuthFeatures;
    }

    setEnabledAuthFeatures(enabledAuthFeatures) {
        this._enabledAuthFeatures = enabledAuthFeatures;
    }

    getHomeCareAuthType() {
        return this._homeCareAuthType;
    }

    setHomeCareAuthType(homeCareAuthType) {
        this._homeCareAuthType = homeCareAuthType;
    }
}
