import React from "react";
import { View } from "react-native";
import TextFields from "../../../../components/text-fields";

export default class EmptyReminderScreen extends React.Component {
    render() {
        return (
            <View style={{ alignItems: "center", paddingTop: 48 }}>
                <TextFields.TitleText1>No Reminders</TextFields.TitleText1>
            </View>
        );
    }
}
