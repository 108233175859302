import React from "react";
import { View, ScrollView, StyleSheet, ActivityIndicator } from "react-native";
import * as APIHandler from "../../../apiHandlers/APIHandler";
import TextFields from "../../../components/text-fields";
import ResourceCard from "../../../components/resource-card";
import TouchableWithAnimation from "../../../components/touchable-with-animation";
import MedicationCard from "./MedicationCard";

export default class MedicationScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentMedications: [],
            pastMedications: [],
            medicationLoading: true
        };
    }

    componentDidMount() {
        APIHandler.getMedications(
            (data) => {
                this.setState({
                    currentMedications: data.medicationList.filter(
                        (currentMedication) =>
                            currentMedication.status === "ACTIVE"
                    ),
                    pastMedications: data.medicationList.filter(
                        (pastMedication) => pastMedication.status === "INACTIVE"
                    ),
                    medicationLoading: false
                });
            },
            () => {
                this.setState({medicationLoading: false})
                console.log("Error fetching medications");
            }
        );
    }

    render() {
        return (
            <View style={{flex: 1}}>
            {(this.state.medicationLoading) &&
                <View style={{
                        position: 'absolute', top: 0, left: 0, right: 0, bottom: 0,
                        zIndex: 1000, backgroundColor: '#F8F7F7', flex: 1, justifyContent: 'center', alignItems: 'center'}}>
                    <ActivityIndicator size={"large"} />
                </View>
            }
                <ScrollView
                    showsVerticalScrollIndicator={false}
                    style={{ paddingTop: 16 }}
                >
                    <View style={styles.medicationContentView}>
                        <TouchableWithAnimation
                            scaleFactor={0.95}
                            onPress={() =>
                                this.props.navigation.navigate(
                                    "MedicationLogScreen"
                                )
                            }
                        >
                            <ResourceCard
                                icon={require("../../../../assets/resourceCardIcons/medicationLog.png")}
                                title="My medication log"
                                subTitle="View & log medications"
                                arrowIcon={true}
                            />
                        </TouchableWithAnimation>

                        {this.state.currentMedications &&
                            this.state.currentMedications.length > 0 && (
                            <View style={{ paddingLeft: 16, paddingTop: 24 }}>
                                <TextFields.TitleTextGray1>
                                    Current Medications
                                </TextFields.TitleTextGray1>
                            </View>
                        )}
                        {this.state.currentMedications &&
                            this.state.currentMedications.map(
                                (currentMedication) => {
                                    return (
                                        <MedicationCard
                                            key={
                                                "currentMedication-" +
                                                currentMedication.medicationId
                                            }
                                            navigation={this.props.navigation}
                                            medicationData={currentMedication}
                                        />
                                    );
                                }
                            )}

                        {this.state.pastMedications.length > 0 && (
                            <View style={{ paddingLeft: 16, paddingTop: 24 }}>
                                <TextFields.TitleTextGray1>
                                    Past Medications
                                </TextFields.TitleTextGray1>
                            </View>
                        )}
                        {this.state.pastMedications &&
                            this.state.pastMedications.map((pastMedication) => {
                                return (
                                    <MedicationCard
                                        key={
                                            "pastMedication-" +
                                            pastMedication.medicationId
                                        }
                                        navigation={this.props.navigation}
                                        medicationData={pastMedication}
                                    />
                                );
                            })}
                    </View>
                    <View style={{ height: 32 }}></View>
                </ScrollView>
            </View>
        );
    }
}

const styles = StyleSheet.create({
    medicationContentView: {
        flex: 1,
    },
});
